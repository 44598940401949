<template>
  <div
    class="form-container"
    :class="{
      'gradient-bg': isGradientHint,
      'color-bg': isHintWithBg,
      'indication4-bg': isHintType4,
      client: postType === 'client' || postType === 'smb-dashboard',
    }"
    :style="bgColorHintVar"
  >
    <template v-if="isShowIndication && post.tooltip.length">
      <Indication
        v-for="(tooltip, index) in post.tooltip"
        :key="index"
        :text="tooltip.hint"
        :isContentCalendarPage="isContentCalendarPage"
        :tooltip="tooltip.tip"
        :tooltipBgColor="tooltip.color ? tooltip.color : '#1D1D1B'"
        :tooltipTextColor="tooltip.textColor ? tooltip.textColor : '#ffffff'"
        :imgSrc="tooltip.icon"
        @rejectSmbPost="$emit('rejectSmbPost', post)"
        :itemType="post.type != 'post' ? 'Story' : 'Post'"
        :dataObject="post"
        :customClass="
          +tooltip.type === 2
            ? 'gradient-indication'
            : +tooltip.type === 3
            ? 'custom-color-bg'
            : +tooltip.type === 4
            ? 'indication4'
            : ''
        "
      />
    </template>
    <Widget
      :class="[
        postType === 'client' ? 'client' : '',
        postType === 'smb-dashboard' ? 'smb-dashboard' : '',
      ]"
      class="widget-content"
      :postType="postType"
      :post="post"
      :isSmall="isSmall"
      :isShowEditIcon="isShowEditIcon"
      :isShowCloseIcon="isShowCloseIcon"
      :isShowTopActions="isShowTopActions"
      :isShowDateCreation="isShowDateCreation"
      :isShowDeletedIcon="isShowDeletedIcon"
      :headerTitle="headerTitle"
      :isShowPublishTime="
        isShowPublishTime && post.scheduledForStr.trim() === ''
      "
      :isShowAllowMovePostIcon="isShowAllowMovePostIcon"
      :checkedList="checkedList"
      :isShowDeleteSchedulingIcon="isShowDeleteSchedulingIcon"
      @edit="editPostHandler($event)"
      @delete="deletePostHandler"
      @approveAction="$emit('approveAction', $event)"
      @updateAmountPost="$emit('updateAmountPost')"
      @clickOnChangePublishDateIcon="openModalHandler"
      @acceptToMoveThePost="movePostHandler"
      @downloadPostMedia="downloadPostMedia($event)"
      @clearDate="changeDateHandler"
      @rejectSmbPost="$emit('rejectSmbPost', $event)"
      @saveSmbPost="$emit('saveSmbPost', $event)"
      @publishSmbPostNow="$emit('publishSmbPostNow', $event)"
      @cancelScheduling="$emit('cancelScheduling', $event)"
      @scheduleSmbPost="$emit('scheduleSmbPost', $event)"
      @editTemplateImage="$emit('editTemplateImage', $event)"
    >
      <div class="post-info" v-if="postType !== 'smb-dashboard'">
        <div class="post-info__for">
          <div
            v-if="postType === 'client' || postType === 'smb-dashboard'"
            class="item social-icons"
          >
            <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
            <img src="@/assets/img/icons/facebook_f_logo.svg" alt="" />
          </div>
          <div v-else class="item">
            <img
              v-if="isFacebookPlatform"
              src="@/assets/img/icons/facebook_f_logo.svg"
              alt=""
            />
            <img v-else src="@/assets/img/icons/Instagram_logo.svg" alt="" />
          </div>
        </div>
        <template v-if="isCanAddACoupon && post.couponEnable">
          <div
            v-if="!post.couponDetails.value"
            class="post-info__coupon"
            @click="addACouponDialog.isShow = true"
          >
            Add a coupon
          </div>

          <div v-else class="post-info__coupon" @click="removeCouponHandler">
            Remove a coupon
          </div>
        </template>
        <template v-if="isArchive || (isShowPublishTime && !post.rush)">
          <div
            v-if="post.scheduledForStr.trim() !== ''"
            class="publish-date"
            @click="openModalHandler"
          >
            <span class="publish-date__name">Publish date:</span>
            <span class="publish-date__val">{{ getPublishDate }}</span>
          </div>
        </template>
        <template v-if="post.rush && !isArchive">
          <div class="publish-rush" @click="openModalRushPostHandler">
            <span class="publish-rush__name">Rush Post</span>
          </div>
        </template>
        <div class="post-info__type" :class="{ 'mobile-name': isHeaderClient }">
          <div
            class="post-info__schedule"
            v-if="
              (postType === 'client' || postType === 'smb-dashboard') &&
              getPublishDate &&
              $route.name !== 'smb-feedback'
            "
          >
            Scheduled:
            {{ getPublishDate }}
          </div>
          {{ post.type != "post" ? "story" : "post" }}
        </div>
      </div>
      <Post
        v-if="post.type === 'post' && !post.isSkeletonLoading"
        :post="post"
        :isShowSwitch="isShowSwitch"
        :isShowComment="isShowComment"
        :currentCustomer="currentCustomer"
        :isShowMakeChangesButton="isShowMakeChangesButton"
        :isShowRating="isShowRating"
        :isFacebookPlatform="isFacebookPlatform"
        :isCanAddComment="isCanAddComment"
        :isShowSubmitBtn="isShowSubmitBtn"
        :postType="postType"
        :focusToElementId="focusToElementId"
        :isShowEditIconForClient="isShowEditIconForClient"
        :isCanShowEditedLabel="isCanShowEditedLabel"
        :isCanShowBackupData="isCanShowBackupData"
        :isCanShowFailedLabel="isCanShowFailedLabel"
        :isPreviousFeedback="isPreviousFeedback"
        :isShowActionDateInFooter="isShowActionDateInFooter"
        :actionDateTitle="actionDateTitle"
        :isShowSmbAction="isShowSmbAction"
        :ratingSize="ratingSize"
        :isPosted="isPosted"
        :isCanDeleteContent="isCanDeleteContent"
        :isCanSaveForLater="isCanSaveForLater"
        :isScheduledContent="isScheduledContent"
        :isContentCalendarPage="isContentCalendarPage"
        @submitAction="$emit('submitAction', $event)"
        @updateRejectedList="$emit('updateRejectedList', $event)"
        @deleteFromRejectedContent="$emit('deleteFromRejectedContent', $event)"
        @focusOnInput="$emit('focusOnInput', post)"
        @blurFromInput="$emit('blurFromInput')"
        @changeDataInPost="$emit('changeDataInPost', $event)"
        @updateCaptionValues="$emit('updateCaptionValues', $event)"
        @rejectSmbPost="$emit('rejectSmbPost', $event)"
        @saveSmbPost="$emit('saveSmbPost', $event)"
        @publishSmbPostNow="$emit('publishSmbPostNow', $event)"
        @scheduleSmbPost="checkIsNeedToSetSchedule($event)"
        @changeScheduledTime="changeSmbScheduleDate"
        @cancelScheduling="$emit('cancelScheduling', $event)"
        @howToEditLiveFbPost="$emit('howToEditLiveFbPost')"
        @howToEditLiveIgPost="$emit('howToEditLiveIgPost')"
        @editTemplateImage="$emit('editTemplateImage', $event)"
        @saveForLater="$emit('saveForLater', $event)"
        @showedEditTemplateDialog="$emit('showedEditTemplateDialog')"
        @editCaption="$emit('editCaption')"
        @approvedToAnimatePost="$emit('approvedToAnimatePost', $event)"
        @revertAnimatedPost="$emit('revertAnimatedPost', $event)"
        @changeSocialAccounts="$emit('changeSocialAccounts', $event)"
      />
      <Stories
        v-else-if="post.type === 'story' && !post.isSkeletonLoading"
        :post="post"
        :isShowSwitch="isShowSwitch"
        :currentCustomer="currentCustomer"
        :isShowMakeChangesButton="isShowMakeChangesButton"
        :isShowComment="isShowComment"
        :isShowRating="isShowRating"
        :isCanAddComment="isCanAddComment"
        :isShowSubmitBtn="isShowSubmitBtn"
        :postType="postType"
        :focusToElementId="focusToElementId"
        :isCanShowFailedLabel="isCanShowFailedLabel"
        :isPreviousFeedback="isPreviousFeedback"
        :isShowActionDateInFooter="isShowActionDateInFooter"
        :actionDateTitle="actionDateTitle"
        :isShowSmbAction="isShowSmbAction"
        :ratingSize="ratingSize"
        :isPosted="isPosted"
        :isCanDeleteContent="isCanDeleteContent"
        :isCanSaveForLater="isCanSaveForLater"
        :isScheduledContent="isScheduledContent"
        :isContentCalendarPage="isContentCalendarPage"
        @clickingOnPost="$emit('clickingOnPost', $event)"
        @submitAction="$emit('submitAction', $event)"
        @updateRejectedList="$emit('updateRejectedList', $event)"
        @deleteFromRejectedContent="$emit('deleteFromRejectedContent', $event)"
        @focusOnInput="$emit('focusOnInput', post)"
        @blurFromInput="$emit('blurFromInput')"
        @changeDataInStory="$emit('changeDataInStory', $event)"
        @rejectSmbPost="$emit('rejectSmbPost', $event)"
        @saveSmbPost="$emit('saveSmbPost', $event)"
        @publishSmbPostNow="$emit('publishSmbPostNow', $event)"
        @scheduleSmbPost="checkIsNeedToSetSchedule($event)"
        @changeScheduledTime="changeSmbScheduleDate"
        @cancelScheduling="$emit('cancelScheduling', $event)"
        @howToEditLiveFbPost="$emit('howToEditLiveFbPost')"
        @howToEditLiveIgPost="$emit('howToEditLiveIgPost')"
        @editTemplateImage="$emit('editTemplateImage', $event)"
        @saveForLater="$emit('saveForLater', $event)"
        @approvedToAnimatePost="$emit('approvedToAnimatePost', $event)"
        @showedEditTemplateDialog="$emit('showedEditTemplateDialog')"
        @revertAnimatedPost="$emit('revertAnimatedPost', $event)"
      />
      <PostSkeleton
        v-else-if="post.type === 'post' && post.isSkeletonLoading"
        :caption="post.text"
        :nickname="getCurrentSmbCustomer.name"
      />
      <StorySkeleton :elementId="`story-${post.id}`" v-else />
      <div v-if="isCanSwap" class="position-container">
        <div class="cell">
          <v-icon
            v-if="index > 0"
            color="light-green"
            @click="
              leftIcon = 'mdi-refresh mdi-spin';
              $emit('swapWithPrevious', index);
            "
            >{{ leftIcon }}</v-icon
          >
        </div>
        <div class="action-date" v-if="isShowActionDate">
          <div class="date-title">{{ actionDateTitle }}</div>
          <div class="date-text">
            {{ $dayjs.unix(post.updatedAt).format("MM.DD.YYYY") }}
          </div>
        </div>
        <div class="cell">
          <v-icon
            v-if="index < lastIndex - 1"
            color="light-green"
            @click="
              rightIcon = 'mdi-refresh mdi-spin';
              $emit('swapWithPrevious', index + 1);
            "
            >{{ rightIcon }}</v-icon
          >
        </div>
      </div>
    </Widget>
    <ModalDialog
      :isShow="changePublishDateDialog.isShow"
      :title="changePublishDateDialog.title"
      :footerButtons="changePublishDateDialog.footerButtons"
      @approveChange="changeDateHandler(dateForModal)"
      @cancel="closeModalHandler"
      @close="closeModalHandler"
    >
      <template v-slot:description>
        <Scheduling
          :date="dateForModal"
          :disabledTime="disabledTime"
          @changeDate="dateForModal = $event"
          @clearDate="dateForModal = ''"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="changePublishDateRashPostDialog.isShow"
      :title="changePublishDateRashPostDialog.title"
      :footerButtons="changePublishDateRashPostDialog.footerButtons"
      @approveChange="changeDateRushPostHandler(dateForModal)"
      @cancel="changePublishDateRashPostDialog.isShow = false"
      @close="changePublishDateRashPostDialog.isShow = false"
    >
      <template v-slot:description>
        <Scheduling
          :date="dateForModal"
          @changeDate="dateForModal = $event"
          @clearDate="dateForModal = ''"
        />
      </template>
      <template v-slot:footer_slot>
        <div class="modal__footer center_btn">
          <div class="btn-wrapper">
            <button
              class="btn black"
              :class="{ disabled: !dateForModal }"
              :disabled="!dateForModal"
              @click="changeDateRushPostHandler(dateForModal)"
            >
              Save
            </button>
            <button
              class="btn black-border"
              @click="changePublishDateRashPostDialog.isShow = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="setScheduleModal.isShow"
      :title="setScheduleModal.title"
      :footerButtons="setScheduleModal.footerButtons"
      :isNeedToCallLoaderComponent="isNeedToCallLoaderComponentInSchedulePopUp"
      @schedule="setScheduleDate(scheduleDateForSet)"
      @cancel="setScheduleModal.isShow = false"
      @close="setScheduleModal.isShow = false"
    >
      <template v-slot:description>
        <Scheduling
          :date="scheduleDateForSet"
          :disabledTime="disabledTimeForSetSchedule"
          :isClearable="false"
          @changeDate="scheduleDateForSet = $event"
          @clearDate="scheduleDateForSet = ''"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="addACouponDialog.isShow"
      :title="addACouponDialog.title"
      :isNeedMarginFromBody="false"
      :isLoading="addACouponDialog.isLoading"
      :icon="require('@/assets/img/icons/percentage-svgrepo-com.svg')"
      :footerButtons="addACouponDialog.footerButtons"
      @close="closeAddCouponDialogHandler"
      @createCoupon="approveCouponHandler"
    >
      <template v-slot:description>
        <v-text-field
          v-model="discount"
          outlined
          append-icon="mdi-percent"
          placeholder="Enter discount precentage"
          type="number"
        ></v-text-field>
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="couponWrongDialog.isShow"
      :title="couponWrongDialog.title"
      :isNeedMarginFromBody="false"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="couponWrongDialog.footerButtons"
      @close="couponWrongDialog.isShow = false"
    >
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Widget from "@/components/dashboard/Widget";
import Post from "@/components/createPost/Post";
import Stories from "@/components/createPost/Stories";
import Scheduling from "@/components/dashboard/widgets/creationWidget/components/Scheduling";
import ModalDialog from "@/components/global/ModalDialog";
import Indication from "@/components/createPost/Indication";

import { NAV_MENU, NAV_MENU_ID } from "@/constants/smb-client";
import helper from "@/mixins/helper";
import PostSkeleton from "@/components/global/PostSkeleton.vue";
import StorySkeleton from "@/components/global/StorySkeleton.vue";

export default {
  name: "PostForm",
  components: {
    StorySkeleton,
    PostSkeleton,
    Widget,
    Post,
    Stories,
    Scheduling,
    ModalDialog,
    Indication,
  },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    switchLabel: {
      type: String,
      default: "Approve Post",
    },
    postType: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    lastIndex: {
      type: Number,
      default: 0,
    },
    isShowSwitch: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isFacebookPlatform: {
      type: Boolean,
      default: false,
    },
    isShowComment: {
      type: Boolean,
      default: false,
    },
    isShowCloseIcon: {
      type: Boolean,
      default: false,
    },
    isShowRating: {
      type: Boolean,
      default: false,
    },
    isCanAddComment: {
      type: Boolean,
      default: false,
    },
    isShowSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isShowEditIcon: {
      type: Boolean,
      default: false,
    },
    isShowMakeChangesButton: {
      type: Boolean,
      default: false,
    },
    isShowTopActions: {
      type: Boolean,
      default: false,
    },
    isShowDateCreation: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: "",
    },
    isShowDeletedIcon: {
      type: Boolean,
      default: false,
    },
    isCanEditPublishDate: {
      type: Boolean,
      default: false,
    },
    focusToElementId: {
      type: String,
    },
    publishDateFormat: {
      type: String,
      default: "MM/DD/YYYY hh:mm A",
    },
    isShowPublishTime: {
      type: Boolean,
      default: false,
    },
    isShowAllowMovePostIcon: {
      type: Boolean,
      default: false,
    },
    checkedList: {
      type: Array,
      default: () => [],
    },
    isCanSwap: {
      type: Boolean,
      default: false,
    },
    isHeaderClient: {
      type: Boolean,
      default: false,
    },
    isShowEditIconForClient: {
      type: Boolean,
      default: false,
    },
    isCanShowEditedLabel: {
      type: Boolean,
      default: false,
    },
    isCanShowBackupData: {
      type: Boolean,
      default: false,
    },
    isCanShowFailedLabel: {
      type: Boolean,
      default: false,
    },
    isPreviousFeedback: {
      type: Boolean,
      default: false,
    },
    isCanAddACoupon: {
      type: Boolean,
      default: false,
    },
    isShowDeleteSchedulingIcon: {
      type: Boolean,
      default: false,
    },
    isShowActionDate: {
      type: Boolean,
      default: false,
    },
    isShowActionDateInFooter: {
      type: Boolean,
      default: false,
    },
    actionDateTitle: {
      type: String,
      default: "",
    },
    isShowIndication: {
      type: Boolean,
      default: false,
    },
    currentCustomer: {
      type: Object,
      default: () => {},
    },
    isShowSmbAction: {
      type: Boolean,
      default: false,
    },
    ratingSize: {
      type: [String, Number],
      default: 35,
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    isScheduledContent: {
      type: Boolean,
      default: false,
    },
    isSmbPendingPage: {
      type: Boolean,
      default: false,
    },
    isContentCalendarPage: {
      type: Boolean,
      default: false,
    },
    isCanDeleteContent: {
      type: Boolean,
      default: false,
    },
    isCanSaveForLater: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [helper],
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    getPublishDate() {
      return this.parseStringDate(this.post.scheduledForStr);
    },
    isArchive() {
      return this.$route.name === "create-post-archive";
    },
    isGradientHint() {
      return this.post.tooltip.length &&
        this.post.tooltip.find((tooltip) => +tooltip.type === 2)
        ? this.post.tooltip.find((tooltip) => +tooltip.type === 2)
        : null;
    },
    isHintWithBg() {
      return this.post.tooltip.length &&
        this.post.tooltip.find((tooltip) => +tooltip.type === 3)
        ? this.post.tooltip.find((tooltip) => +tooltip.type === 3)
        : null;
    },
    isHintType4() {
      return this.post.tooltip.length &&
        this.post.tooltip.find((tooltip) => +tooltip.type === 4)
        ? this.post.tooltip.find((tooltip) => +tooltip.type === 4)
        : null;
    },
    bgColorHintVar() {
      return this.isHintWithBg && this.isHintWithBg.color.length
        ? `--hint-bg-color: ${this.isHintWithBg.color}`
        : `--hint-bg-color: '#1D1D1B`;
    },
    disabledTime() {
      if (
        this.isSmbPendingPage &&
        this.post.type === "story" &&
        this.getCurrentSmbCustomer.manual_posting
      ) {
        return 48;
      }
      return 0;
    },
  },
  data: () => ({
    changePublishDateDialog: {
      isShow: false,
      title: "Change publication date",
      footerButtons: [
        { name: "Change", emitName: "approveChange", btnClass: "black" },
        { name: "Cancel", emitName: "cancel", btnClass: "black-border" },
      ],
    },
    setScheduleModal: {
      isShow: false,
      title: "Select time to post.",
      footerButtons: [
        { name: "Schedule", emitName: "schedule", btnClass: "black" },
        { name: "Cancel", emitName: "cancel", btnClass: "black-border" },
      ],
    },
    scheduleDateForSet: "",
    disabledTimeForSetSchedule: -24,
    changePublishDateRashPostDialog: {
      isShow: false,
      title:
        "This is a rush post! Are you sure you want to change to manual scheduling?",
    },
    dateForModal: "",
    isShowSheduling: false,
    leftIcon: "mdi-arrow-left-thin",
    rightIcon: "mdi-arrow-right-thin",
    addACouponDialog: {
      isShow: false,
      isLoading: false,
      title: "Enter discount precentage",
      footerButtons: [
        {
          name: "Create coupon",
          emitName: "createCoupon",
          btnClass: "disabled",
          isDisabled: true,
        },
      ],
    },
    couponWrongDialog: {
      isShow: false,
      title: "Somethings went wrong",
      footerButtons: [
        {
          name: "OK",
          emitName: "close",
          btnClass: "violet",
        },
      ],
    },
    discount: null,
    isNeedToCallLoaderComponentInSchedulePopUp: false,
  }),
  watch: {
    discount: function (newValue) {
      let button = this.addACouponDialog.footerButtons.find(
        (i) => i.emitName === "createCoupon"
      );

      if (+newValue > 0 && +newValue <= 100) {
        button.btnClass = "violet";
        button.isDisabled = false;
      } else {
        button.btnClass = "disabled";
        button.isDisabled = true;
      }
    },
  },
  mounted() {
    this.discount = this.post.couponDetails.value;
  },
  methods: {
    ...mapActions("createPost", [
      "deletePost",
      "updatePublishDate",
      "updatePosts",
      "getSuggestedPublishDate",
    ]),
    ...mapActions("post", ["updateCoupon"]),
    ...mapActions("mediaLibraryWidget", ["downloads"]),
    ...mapMutations("stories", ["setCurrentStories"]),
    ...mapMutations("post", ["setCurrentPost"]),
    ...mapMutations("instagramPost", ["setIsStoriesTabActive"]),
    editPostHandler(event) {
      if (this.post.type === "post") {
        this.setIsStoriesTabActive(false);
        this.setCurrentPost(this.post);
      } else {
        this.setIsStoriesTabActive(true);
        this.setCurrentStories(this.post);
      }
      this.$router.push({
        name: "dashboard",
        params: { isEdit: true, redirectPath: event.fullPath },
      });
    },
    openModalRushPostHandler() {
      this.changePublishDateRashPostDialog.isShow = true;
      this.dateForModal = "";
    },
    async deletePostHandler() {
      await this.deletePost(this.post.id);
      this.$emit("postDeletedSuccessfully");
    },
    // swap() {
    //   this.$emit("swapWithPrevious", this.index);
    // },
    openModalHandler() {
      if (this.isCanEditPublishDate) {
        this.dateForModal = this.post.scheduledForStr;
        this.changePublishDateDialog.isShow = true;
      }
    },
    changeSmbScheduleDate() {
      this.dateForModal = this.post.scheduledForStr;
      this.changePublishDateDialog.isShow = true;
      this.changePublishDateDialog.title = "Change time to post";
    },
    async changeDateHandler(newDate) {
      try {
        await this.updatePublishDate({
          id: this.post.id,
          scheduledForStr: newDate,
        });
        this.$emit("updateSchedule", {
          postId: this.post.id,
          scheduledForStr: newDate,
        });
        this.closeModalHandler();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async changeDateRushPostHandler(newDate) {
      try {
        await this.updatePublishDate({
          id: this.post.id,
          scheduledForStr: newDate,
        });
        await this.updateRushStatus(this.post.id);
        this.$emit("updateSchedule", {
          postId: this.post.id,
          scheduledForStr: newDate,
        });
        this.changePublishDateRashPostDialog.isShow = false;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    closeModalHandler() {
      this.dateForModal = "";
      this.changePublishDateDialog.isShow = false;
    },
    async updateRushStatus(id) {
      try {
        await this.updatePosts({ id: id, rush: false });
        this.$emit("updateRush", { id: id, rush: false });
      } catch (e) {
        throw e;
      }
    },
    async movePostHandler(postToMove) {
      try {
        await this.updatePosts({ id: postToMove.id, customerApprove: "true" });
        this.$emit("updatePostsList");
      } catch (e) {
        throw e;
      }
    },
    replaceFromString(string, searchItem, replaceTo = "", searchFlag = "ig") {
      return string.replace(new RegExp(searchItem, searchFlag), (match) => {
        return match.replace(match, replaceTo);
      });
    },
    async downloadPostMedia($event) {
      try {
        let mediaUrlList = [];

        $event.media.map((m) => {
          mediaUrlList.push(m.postMediaUrl ? m.postMediaUrl : m.mediaUrl);
        });

        if (mediaUrlList.length) {
          const data = await this.downloads({
            customerId: this.currentCustomer.id,
            imageUrlList: mediaUrlList,
          });
          if (data) {
            this.download(data);
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async removeCouponHandler() {
      try {
        this.addACouponDialog.isLoading = true;
        this.addACouponDialog.isShow = true;

        const updatedPostOrStories = await this.updateCoupon({
          id: this.post.id,
          couponDetails: { value: "" },
        });

        this.$emit("updatedCoupon", updatedPostOrStories);
        this.discount = null;
        this.addACouponDialog.isShow = false;
        this.addACouponDialog.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    closeAddCouponDialogHandler() {
      this.addACouponDialog.isShow = false;
      this.discount = this.post.couponDetails.value;
    },
    async approveCouponHandler() {
      try {
        this.addACouponDialog.isLoading = true;
        const updatedPostOrStories = await this.updateCoupon({
          id: this.post.id,
          couponDetails: { value: this.discount },
        });

        this.$emit("updatedCoupon", updatedPostOrStories);
        this.discount = null;

        this.addACouponDialog.isLoading = false;
        this.addACouponDialog.isShow = false;
      } catch (e) {
        this.addACouponDialog.isShow = false;
        this.addACouponDialog.isLoading = false;
        this.couponWrongDialog.isShow = true;
      }
    },
    checkIsNeedToSetSchedule(event) {
      if (!this.post.scheduledForStr && this.isSmbPendingPage) {
        if (
          this.post.type === "story" &&
          this.getCurrentSmbCustomer.manual_posting
        ) {
          this.disabledTimeForSetSchedule = 48;
          this.scheduleDateForSet = this.$dayjs(
            this.getCurrentDateRounded(48)
          ).format("YYYY-MM-DD_HH-mm-ss");
        } else {
          this.disabledTimeForSetSchedule = 0;
          this.scheduleDateForSet = this.$dayjs(
            this.getCurrentDateRounded()
          ).format("YYYY-MM-DD_HH-mm-ss");
        }
        this.setScheduleModal.isShow = true;
      } else {
        this.$emit("scheduleSmbPost", event);
      }
    },
    getCurrentDateRounded(hoursForAdd = 0) {
      let date = this.$dayjs().add(hoursForAdd, "h");

      if (date.minute() >= 30) {
        date = date.startOf("hour").add(1, "hour");
      } else {
        date = date.startOf("hour").add(30, "minute");
      }

      const roundedMinutes = Math.round(date.minute() / 30) * 30;
      return date.minute(roundedMinutes).second(0).millisecond(0).toDate();
    },
    async setScheduleDate(newDate) {
      this.isNeedToCallLoaderComponentInSchedulePopUp = true;
      try {
        await this.updatePublishDate({
          id: this.post.id,
          scheduledForStr: newDate,
        });
        this.$emit("updateSchedule", {
          postId: this.post.id,
          scheduledForStr: newDate,
        });
        this.$emit("scheduleSmbPost", this.post);
        this.isNeedToCallLoaderComponentInSchedulePopUp = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.form-container {
  display: flex;
  flex-direction: column;
  &.smb-dashboard,
  &.client {
    height: 100%;
    min-height: 550px;
    &.gradient-bg {
      background: conic-gradient(
        from -0.19deg at 50.14% 48.14%,
        #db1d2f -88.79deg,
        #003ea5 90.21deg,
        #db1d2f 271.21deg,
        #003ea5 450.21deg
      );
      border-radius: 8px;
      padding: 0 4px 4px 4px;
      .widget-content {
        &.smb-dashboard,
        &.client {
          height: 100%;
        }
      }
    }
    &.indication4-bg {
      padding: 0 4px 4px 4px;
      background: conic-gradient(
        from 180deg at 50% 50%,
        #92c4ff -91.71deg,
        #92c4ff 207.59deg,
        #92c4ff 268.29deg,
        #92c4ff 567.59deg
      );
      border: 0.5px solid #dadada;
      border-radius: 8px;
      .widget-content {
        &.smb-dashboard,
        &.client {
          height: 100%;
        }
      }
    }
  }
}

.widget-content {
  height: 100%;
  &.smb-dashboard,
  &.client {
    width: 100%;
    ::v-deep {
      .top-row {
        display: none !important;
      }
      .position-container {
        display: none;
      }
    }
  }

  .post-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;
    margin-bottom: 12px;
    &__schedule {
      font-family: "Inter";
      font-weight: 500;
      font-size: 10px;
      color: #4e5778;
    }
    &__for {
      display: inline-flex;
      align-items: center;
      .social-icons {
        display: flex;
        flex-direction: row;
        img {
          margin-left: 12px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .item {
        height: 16px;
        margin-right: 8px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__type {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: $color-smb-black;
      text-transform: capitalize;
    }
    &__coupon {
      color: rgb(38 82 232);
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}
.publish-date {
  cursor: pointer;
  font-size: 12px;
  color: #4e5778;
}
.publish-rush {
  cursor: pointer;
  display: inline-flex;
  &__name {
    font-size: 12px;
    font-weight: 600;
  }
}
.position-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(47, 72, 88, 0.2);
  padding-top: 4px;
  margin-top: auto;
  .cell {
    display: inline-flex;
    align-items: center;
    button {
      font-size: 18px;
      height: 18px;
      color: green !important;
    }
  }
}
.action-date {
  font-size: 12px;
  text-align: center;
  .date-title {
    color: green;
  }
}
.smb-dashboard,
.client {
  .widget-item {
    .mobile-name {
      font-weight: 500;
      font-size: 12px;
    }
    .post-info {
      &__type {
        display: flex;
        justify-content: center;
      }
      &__schedule {
        margin-right: 7px;
      }
    }
  }
  &.color-bg {
    background: var(--hint-bg-color);
    border-radius: 8px;
    padding: 0 4px 4px 4px;
  }
}

.smb-dashboard {
  ::v-deep {
    .widget-item {
      overflow: hidden;
      padding: 5px 0 0 0 !important;
    }
  }
}

.widget-content {
  &.smb-dashboard {
    .post-info {
      &__schedule,
      &__type {
        font-size: 14px;
        @media screen and (min-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
