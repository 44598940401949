<template>
  <div class="template-media">
    <v-tabs v-model="tab" background-color="transparent" color="basil">
      <v-tab v-for="item in tabs" :key="item.id">
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.id">
        <v-card color="basil" flat>
          <div class="media-wrapper">
            <template v-show="item.id === tab">
              <component
                :calendarMediaItems="calendarMediaItems"
                :searchTermVal="searchTermVal"
                :mediaRatio="mediaRatio"
                :templateId="templateId"
                @changeImage="$emit('changeImage', $event)"
                @changeRelativeImage="$emit('changeRelativeImage', $event)"
                :is="item.component"
              ></component>
            </template>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SmbStockMedia from "@/components/smb-client/SmbStockMedia";
import SmbRelatedMedia from "@/components/smb-client/SmbRelatedMedia";
export default {
  name: "SmbMedia",
  props: {
    mediaRatio: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    templateId: {
      type: String,
      default: "",
    },
    searchTermVal: {
      type: String,
      default: "",
    },
    calendarMediaItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          id: 1,
          title: "Stock Photos",
          component: SmbStockMedia,
        },
      ],
    };
  },
  beforeMount() {
    this.setRelatedMediaTab();
  },
  methods: {
    setRelatedMediaTab() {
      this.tabs = this.tabs.map((item) => {
        return {
          id: item.id + 1,
          title: item.title,
          component: item.component,
        };
      });
      if (this.calendarMediaItems.length) {
        this.tabs.unshift({
          id: 0,
          title: "Related media",
          component: SmbRelatedMedia,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.template-media {
  ::v-deep {
    .v-window {
      &__container {
        margin-top: 20px;
      }
    }
  }
}
</style>
