<template>
  <div
    class="edit-template"
    v-click-outside="{
      handler: onClickOutsideWithConditional,
    }"
  >
    <div class="select-template" v-if="currentStep === 1">
      <div class="select-template__title">
        Organize your media: Add, remove, or edit
      </div>
      <draggable
        :force-fallback="true"
        class="select-template__list"
        draggable=".select-template__item"
        @end="dragAndDropItems($event)"
        v-model="mediaList"
      >
        <div
          class="select-template__item"
          v-for="(item, index) in mediaList"
          :key="item.mediaId"
        >
          <img :src="getMediaUrl(item)" />
          <div class="select-template__item-controls">
            <div class="button-item">
              <button
                class="edit"
                v-if="isTemplate(item)"
                @click="changeTemplateImages(item)"
              >
                <i class="icon-edit"></i>
              </button>
            </div>
            <div class="button-item">
              <button
                class="delete"
                @click="
                  itemForDelete = index;
                  modalIsOpen = true;
                  approveDeleteMedia.isShow = true;
                "
              >
                <i class="icon-remove"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="select-template__item-upload">
          <Uploader
            isUseCustomElementInSlot
            :isDrop="false"
            :isMultiple="false"
            fileFormat="png|jpeg|gif|svg|jpg"
            className="uploader-custom-icon"
            @uploadFile="startUploadFileHandler($event)"
          >
          </Uploader>
        </div>
      </draggable>
      <div class="select-template__footer" @click="closePanel()">
        {{ mediaIsChanged ? "Done" : "Cancel" }}
      </div>
    </div>
    <div class="select-image" v-else>
      <div class="select-image__title">Choose the image you want to change</div>
      <div class="select-image__list">
        <div
          class="select-image__item"
          v-for="(item, index) in editImages"
          :key="index"
        >
          <button class="edit" @click="changeImage(item.name, item)">
            <i class="icon-edit"></i>
          </button>
          <img v-if="item.value" :src="item.value" />
        </div>
      </div>
      <div class="select-image__footer">
        <div
          class="select-image__footer-item"
          v-if="valueIsChanged"
          @click="saveTemplateChanges()"
        >
          Save changes
        </div>
        <div class="select-image__footer-item" v-else @click="close()">
          Cancel
        </div>
      </div>
    </div>
    <ModalDialog
      :isShow="templateMediaDialog.isShow"
      :size="500"
      customClass="template-list"
      @close="templateMediaDialog.isShow = false"
      @click="() => null"
    >
      <template v-slot:description>
        <SmbMedia
          :searchTermVal="searchValForTemplate"
          :mediaRatio="editImageRatio"
          :templateId="selectedTemplateId"
          :calendarMediaItems="calendarMediaItems"
          @changeImage="setNewImage($event)"
          @changeRelativeImage="changeRelativeImage($event)"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="approveDeleteMedia.isShow"
      :size="approveDeleteMedia.size"
      :title="approveDeleteMedia.title"
      customClass="approve-delete-media"
      :footerButtons="approveDeleteMedia.footerButtons"
      @success="removeImage"
      @cancel="closeModalDelete"
      @close="closeModalDelete"
    >
    </ModalDialog>
    <ModalDialog
      :isShow="editTemplateDialogIsShow"
      :size="1000"
      :isCanClose="false"
      customClass="edit-template-modal"
      @close="editTemplateDialogIsShow = false"
    >
      <template v-slot:description>
        <SmbEditTemplate
          v-if="editTemplateDialogIsShow"
          :post="post"
          @close="editTemplateDialogIsShow = false"
          :isEditTemplate="true"
          :editedTemplate="editedMediaItem.template"
          @updateSmbPostMedia="
            newMedia($event);
            editTemplateDialogIsShow = false;
          "
        />
      </template>
    </ModalDialog>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";
import SmbMedia from "@/components/smb-client/SmbMedia";
import SmbEditTemplate from "@/components/smb-client/SmbEditTemplate";
import Uploader from "@/components/global/Uploader";
import { mapActions, mapGetters } from "vuex";
import uploader from "@/mixins/uploader";

export default {
  name: "SmbEditMedia",
  data: () => ({
    currentStep: 1,
    editedMediaItem: null,
    valueIsChanged: false,
    mediaIsChanged: false,
    mediaList: [],
    isLoading: false,
    templateMediaDialog: {
      isShow: false,
    },
    itemForDelete: null,
    modalIsOpen: false,
    editImageRatio: "",
    selectedTemplateId: "",
    searchValForTemplate: "",
    editTemplateDialogIsShow: false,
    approveDeleteMedia: {
      isShow: false,
      title: "Are you sure you want to delete this media?",
      footerButtons: [
        { name: "Cancel", emitName: "cancel", btnClass: "black-border" },
        { name: "Delete", emitName: "success", btnClass: "red" },
      ],
    },
  }),
  components: {
    ModalDialog,
    Loader,
    Uploader,
    SmbMedia,
    SmbEditTemplate,
  },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [uploader],
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    editImages() {
      this.editedMediaItem.template.parameters.forEach((parameter, index) => {
        parameter.indexInList = index;
      });

      return this.editedMediaItem.template.parameters.length
        ? this.editedMediaItem.template.parameters.filter(
            (i) => i.type === "image_url"
          )
        : [];
    },
    calendarMediaItems() {
      return this.mediaList.filter((item) => !item.template);
    },
  },
  created() {
    this.mediaList = JSON.parse(
      JSON.stringify(this.post.media.filter((item) => item))
    );
  },
  methods: {
    ...mapActions("templates", ["getTemplatesInfo", "createImageFromTemplate"]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    ...mapActions("clientDashboard", ["updatePosts"]),

    close() {
      this.currentStep = 1;
    },
    closePanel() {
      this.mediaIsChanged = false;
      this.$emit("close");
    },
    async saveChanges() {
      const mediaParams = this.mediaList.map((obj) => ({
        cropDetails: obj.cropDetails,
        mediaId: obj.mediaId,
        mediaUrl: obj.mediaUrl,
        type: "image",
        ratio: obj.ratio,
        template: obj.template,
      }));
      await this.updatePosts({
        id: this.post.id,
        byCustomer: true,
        mediaObject: mediaParams,
      });
      const updatedPost = JSON.parse(JSON.stringify(this.post));
      updatedPost.media = mediaParams;
      this.$emit("updateSmbPostMedia", updatedPost);
    },
    getMediaUrl(file) {
      return file.editedMediaUrlReduced || file.editedMediaUrl || file.mediaUrl;
    },
    selectTemplateForEdit(media) {
      this.editedMediaItem = media;
      this.modalIsOpen = true;
      this.editTemplateDialogIsShow = true;
      this.searchTermTemplate(this.editedMediaItem.template);
      this.selectedTemplateId = this.editedMediaItem.template.id;
    },
    searchTermTemplate(template) {
      if (template.searchTerm && template.searchTerm.length) {
        if (Array.isArray(template.searchTerm)) {
          this.searchValForTemplate = template.searchTerm[0];
        } else {
          this.searchValForTemplate = template.searchTerm;
        }
      }
    },
    changeImage(name, item) {
      this.templateMediaDialog.isShow = true;
      this.modalIsOpen = true;
      this.editImageName = name;
      this.editImageRatio = item.image_layer_type;
    },
    setNewImage(image) {
      let editedItem = this.editedMediaItem.template.parameters.findIndex(
        (item) => item.name.trim() === this.editImageName.trim()
      );
      this.editedMediaItem.template.parameters[editedItem].value =
        image.mediaUrl;
      this.editedMediaItem.template.parameters[editedItem].externalId =
        image.externalId;
      this.editedMediaItem.template.parameters[editedItem].externalService =
        image.externalService;
      this.editImageName = "";
      this.editImageRatio = "";
      this.selectedTemplateId = "";
      this.valueIsChanged = true;
      this.templateMediaDialog.isShow = false;
      setTimeout(() => {
        this.modalIsOpen = false;
      }, 500);
    },
    changeRelativeImage(image) {
      this.mediaList = this.mediaList.filter(
        (item) => item.mediaId !== image.id
      );
      this.setNewImage(image);
    },
    async saveTemplateChanges() {
      try {
        this.isLoading = true;
        const params = {
          task: "change_template",
          inputParameters: this.editedMediaItem.template.parameters,
          templateId: this.editedMediaItem.template.id,
          customerId: this.getCurrentSmbCustomer.id,
          contentTemplateId: this.post.contentTemplateId,
          calendarItemId: this.post.calendarItemId,
        };
        const data = await this.createImageFromTemplate(params);
        this.editedMediaItem.template = data.template;
        this.editedMediaItem.mediaUrl = data.template.preview_url;
        const newlyUploadedMedia = await this.uploadObjectMedia(
          this.editedMediaItem
        );
        let currentMediaIndex = this.mediaList.findIndex(
          (i) => i.mediaId === this.editedMediaItem.mediaId
        );
        this.mediaList[currentMediaIndex].mediaUrl =
          newlyUploadedMedia.mediaUrl;
        this.mediaList[currentMediaIndex].editedMediaUrlReduced =
          newlyUploadedMedia.mediaUrl;
        this.mediaList[currentMediaIndex].editedMediaUrl =
          newlyUploadedMedia.mediaUrl;
        this.mediaList[currentMediaIndex].mediaId = newlyUploadedMedia.mediaId;
        this.mediaList[currentMediaIndex].template = data.template;
        this.currentStep = 1;
        this.valueIsChanged = false;
        this.mediaIsChanged = true;
        await this.saveChanges();
        this.close();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    dragAndDropItems(e) {
      if (e.newDraggableIndex !== e.oldDraggableIndex) {
        this.mediaIsChanged = true;
        this.saveChanges();
      }
    },
    async uploadObjectMedia(mediaObject) {
      const data = await this.uploadMediaFromUrl({
        customerId: this.getCurrentSmbCustomer.id,
        inputMediaUrl: mediaObject.mediaUrl,
        isLicensed: mediaObject.isLicensed,
        processMedia: false,
        createMediaItem: true,
        createdBy: "6",
        fileName: "post_raw_file", //TODO, shoule be unique per post
        folderName: "None",
      });
      const mediaUrl = data.data["media_url"];
      const mediaId = data.data["media_id"];
      return { mediaUrl, mediaId };
    },
    onClickOutsideWithConditional(e) {
      // if (!this.modalIsOpen) {
      //   this.$emit("close");
      // }
    },
    isTemplate(media) {
      return media.template !== null;
    },
    async startUploadFileHandler(file) {
      try {
        this.isLoading = true;
        const {
          url,
          mediaId,
          fields: { key },
        } = await this.uploadFileHandler(file, this.getCurrentSmbCustomer.id);
        this.mediaList.push({
          cropDetails: {
            height: 1000,
            left: 0,
            top: 0,
            width: 1000,
          },
          mediaId: mediaId,
          mediaUrl: url + key,
          editedMediaUrl: url + key,
          editedMediaUrlReduced: url + key,
          ratio: "square",
          type: "image",
          template: null,
        });
        this.mediaIsChanged = true;
        await this.saveChanges();
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    closeModalDelete() {
      this.itemForDelete = null;
      this.approveDeleteMedia.isShow = false;
      setTimeout(() => {
        this.modalIsOpen = false;
      }, 500);
    },
    async removeImage() {
      this.isLoading = true;
      this.mediaList.splice(this.itemForDelete, 1);
      this.itemForDelete = null;
      this.approveDeleteMedia.isShow = false;
      this.closeModalDelete();
      await this.saveChanges();
      this.mediaIsChanged = true;
      this.isLoading = false;
    },
    changeTemplateImages(item) {
      this.selectTemplateForEdit(item);
    },
    newMedia(data) {
      this.mediaList = data.media;
      const updatedPost = JSON.parse(JSON.stringify(this.post));
      updatedPost.media = data.media;
      this.$emit("updateSmbPostMedia", updatedPost);
      this.mediaIsChanged = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.edit-template {
  position: absolute;
  background: #ffffff;
  box-shadow: 1px 2px 15px rgba(31, 35, 37, 0.2);
  border-radius: 12px;
  top: 50px;
  left: 12px;
  right: 12px;
  z-index: 10;
  overflow: hidden;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  &__close {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  .select-template {
    display: flex;
    flex-direction: column;
    flex: 1;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      padding: 20px 16px;
      margin-bottom: 4px;
    }
    &__list {
      flex: 1;
      margin-bottom: 4px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      padding: 0 16px 16px 16px;
      overflow-y: auto;
      max-height: 320px;
    }
    &__item {
      background: #d9d9d9;
      aspect-ratio: 1 / 1;
      display: inline-flex;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-upload {
        aspect-ratio: 1 / 1;
        background: #ffffff;
        border: 1px dashed #000000;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:before {
          content: "";
          width: 24px;
          height: 1px;
          background: #1d1d1b;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:after {
          content: "";
          width: 24px;
          height: 1px;
          background: #1d1d1b;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
        ::v-deep {
          .file-upload {
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            label {
              cursor: pointer;
            }
          }
        }
      }
      &-controls {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
      }
      .button-item {
        button {
          width: 22px;
          height: 22px;
          border-radius: 3px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          font-size: 12px;
          background: #fff;
        }
      }
    }
    &__footer {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      padding: 15px 12px;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid rgba(29, 29, 27, 0.5);
      background: #fff;
    }
  }
  .select-image {
    display: flex;
    flex-direction: column;
    flex: 1;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      padding: 20px 16px;
      margin-bottom: 4px;
    }
    &__list {
      flex: 1;
      margin-bottom: 4px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      padding: 0 35px 16px 35px;
    }
    &__item {
      background: #d9d9d9;
      aspect-ratio: 1 / 1;
      display: inline-flex;
      position: relative;
      border: 1px solid #1f2325;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .edit {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 11px;
        color: #1f2325;
        background: #ffffff;
        transition: all 0.3s ease;
      }
    }
    &__footer {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      padding: 15px 12px;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid rgba(29, 29, 27, 0.5);
    }
  }
}
.image-uploading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
</style>
