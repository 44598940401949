<template>
  <div class="smb-nav" :class="customClass">
    <div class="smb-nav__item" v-for="menuItem in navMenu" :key="menuItem.id">
      <v-btn
        v-if="!menuItem.isDisabled"
        class="smb-nav__item-button"
        :loading="isLoadingBtnId === menuItem.id"
        :class="menuItem.customClass"
        @click="$emit(menuItem.emitName)"
        >{{ menuItem.name }}
        <v-icon class="arrow-icon" v-if="menuItem.isArrowIcon"
          >mdi-chevron-right</v-icon
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
import { NAV_MENU, NAV_MENU_ID } from "@/constants/smb-client";
import helper from "@/mixins/helper";
import { mapGetters } from "vuex";

export default {
  name: "SmbPostClientNav",
  data: () => ({
    navMenu: [],
  }),
  mixins: [helper],
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    isScheduledContent: {
      type: Boolean,
      default: false,
    },
    isDisableGenerateCaptionBtn: {
      type: Boolean,
      default: false,
    },
    isDisableEditTemplate: {
      type: Boolean,
      default: false,
    },
    isDisableEditMedia: {
      type: Boolean,
      default: false,
    },
    isLoadingBtnId: {
      type: Number,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.fetchMenu();
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  methods: {
    fetchMenu() {
      const fullMenu = NAV_MENU;
      let list = [];

      if (
        this.$route.name === "smb-feedback" ||
        this.$route.name === "personal-update"
      ) {
        if (this.post.type === "post") {
          list = fullMenu.filter(
            (item) =>
              item.id === NAV_MENU_ID.EDIT_CAPTIONS ||
              item.id === NAV_MENU_ID.GENERATE_NEW_CAPTION ||
              item.id === NAV_MENU_ID.EDIT_MEDIA ||
              item.id === NAV_MENU_ID.CHANGE_SCHEDULED_TIME ||
              item.id === NAV_MENU_ID.CANCEL
          );

          this.getCurrentSmbCustomer.social_platforms.length > 1 &&
          this.post.postTo &&
          this.post.postTo.length
            ? (list = [
                ...list,
                ...fullMenu.filter(
                  (item) => item.id === NAV_MENU_ID.CHANGE_SOCIAL_ACCOUNTS
                ),
              ])
            : null;
        } else {
          list = fullMenu.filter(
            (item) =>
              item.id === NAV_MENU_ID.CHANGE_SCHEDULED_TIME ||
              item.id === NAV_MENU_ID.EDIT_TEMPLATE ||
              item.id === NAV_MENU_ID.CANCEL
          );
        }
        list.forEach((item) => {
          if (item.id === NAV_MENU_ID.CHANGE_SCHEDULED_TIME) {
            this.post.scheduledForStr
              ? (item.name = "Change scheduled time")
              : (item.name = "Manually set time");
          }
        });
        list.forEach((item) => {
          if (item.id === NAV_MENU_ID.GENERATE_NEW_CAPTION) {
            item.isDisabled = this.isDisableGenerateCaptionBtn;
          }
        });
        list.forEach((item) => {
          if (item.id === NAV_MENU_ID.EDIT_TEMPLATE) {
            item.isDisabled = this.isDisableEditTemplate;
          }
        });
        list.forEach((item) => {
          if (item.id === NAV_MENU_ID.EDIT_MEDIA) {
            item.isDisabled = this.isDisableEditMedia;
          }
        });
        if (this.post.type === "post" && this.post.editedByCustomer) {
          list.unshift({
            name: "Revert to original caption",
            emitName: "revertOriginalCaption",
          });
        }
      }

      if (this.$route.name === "smb-content-calendar") {
        if (this.isScheduledContent) {
          if (this.post.type === "post") {
            list = fullMenu.filter(
              (item) =>
                item.id === NAV_MENU_ID.CANCEL_SCHEDULING ||
                item.id === NAV_MENU_ID.CHANGE_SCHEDULED_TIME ||
                item.id === NAV_MENU_ID.CANCEL
            );
            console.log(list, "list");
          } else {
            list = fullMenu.filter(
              (item) =>
                item.id === NAV_MENU_ID.CANCEL_SCHEDULING ||
                item.id === NAV_MENU_ID.CHANGE_SCHEDULED_TIME ||
                item.id === NAV_MENU_ID.CANCEL
            );
          }
        }

        if (this.isPosted) {
          list = fullMenu.filter(
            (item) =>
              item.id === NAV_MENU_ID.HOW_EDIT_IG_POST ||
              item.id === NAV_MENU_ID.HOW_EDIT_FB_POST ||
              item.id === NAV_MENU_ID.CANCEL
          );
        }
      }
      this.navMenu = list;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 110px;
  left: 12px;
  right: 12px;
  background: #ffffff;
  box-shadow: 1px 2px 15px rgba(31, 35, 37, 0.2);
  border-radius: 12px;
  z-index: 10;
  overflow: hidden;
  &.bottom {
    top: initial;
    bottom: 43px;
  }
  &__item {
    &-button {
      text-align: center;
      cursor: pointer;
      padding: 15px !important;
      font-weight: 500;
      font-size: 17px;
      height: 52px !important;
      line-height: 140%;
      color: #1f2325;
      border-bottom: 1px solid rgba(29, 29, 27, 0.3) !important;
      transition: all 0.3s ease;
      text-indent: initial;
      letter-spacing: initial;
      background: #fff !important;
      text-transform: none;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      position: relative;
      .arrow-icon {
        position: absolute;
        right: -10px;
        color: #828282;
      }
      &.cancel {
        font-weight: 600;
      }
      &.red {
        color: #fe3a43;
      }

      &:hover {
        background: rgba(29, 29, 27, 0.1);
      }
    }
    &:last-child {
      .smb-nav__item-button {
        border-bottom: none !important;
      }
    }
  }
}
</style>
