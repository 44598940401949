<template>
  <div
    class="post"
    @click:outside="clickOutsideHandler()"
    :class="[
      postType === 'client' ? 'client' : '',
      postType === 'smb-dashboard' ? 'smb-dashboard' : '',
    ]"
  >
    <div
      class="post-fb"
      v-if="
        isFacebookPlatform &&
        (postType === 'client' || postType === 'smb-dashboard')
      "
    >
      <div class="post-fb__header">
        <div class="user">
          <div class="user__avatar">
            <img
              v-if="currentCustomer.picUrl"
              :src="currentCustomer.picUrl"
              :alt="currentCustomer.name"
            />
            <img
              v-else
              src="@/assets/default-profile-picture.png"
              :alt="currentCustomer.name"
            />
          </div>
          <div class="user__info">
            <div class="user__name">{{ currentCustomer.name }}</div>
            <div
              class="user__location"
              v-if="getCustomerAddress && isShowCustomerAddress"
            >
              {{ getCustomerAddress }}
            </div>
          </div>
        </div>
        <div class="more-info" @click="toggleSmbNav()">
          <i class="icon-more"></i>
        </div>
      </div>
      <div
        class="post-fb__text-container"
        :class="{ 'edit-mode': isShowTextAreaForEdit }"
      >
        <div class="text">
          <template v-if="!isShowTextAreaForEdit">
            <pre>{{ post.text }}</pre>
          </template>
          <div v-show="isShowTextAreaForEdit" class="edit-area">
            <div class="edit-area__textarea-container">
              <v-textarea
                solo
                placeholder="Type your caption here"
                class="customer-textarea"
                :ref="'editTemplate_fb_' + post.id"
                v-model="postText"
              ></v-textarea>
              <EmojiPicker
                v-show="$refs['editTemplate_fb_' + post.id]"
                :inputRef="$refs['editTemplate_fb_' + post.id]"
                :content="postText"
                @newValue="postText = $event"
                class="emoji"
                customClass="chat"
              />
            </div>
            <div>
              <span
                >Hashtags
                <span :style="{ color: postTags.length < 30 ? 'black' : 'red' }"
                  >({{ hashtagsHint }})</span
                ></span
              >
              <v-combobox
                :delimiters="[',', ' ', ';', '#']"
                @change="hashtagsInput"
                v-model="postTags"
                chips
                hide-details
                class="tags_container"
                multiple
                outlined
              >
                <template
                  v-slot:selection="{ attrs, item, index, select, selected }"
                >
                  <draggable
                    :id="index"
                    :list="postTags"
                    v-bind="dragOptionsChips"
                    :move="move"
                    @change="change"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      color="#000000"
                      small
                      draggable
                      class="txt-color"
                      @mousedown.stop
                      @click.stop
                      @click="select"
                      @click:close="deleteTagHandler(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </draggable>
                </template>
              </v-combobox>
            </div>
            <div class="edit-area__buttons" v-if="postType !== 'smb-dashboard'">
              <v-btn x-small class="red" @click="canceledEditHandler"
                >Cancel
              </v-btn>
              <v-btn
                x-small
                class="green"
                :disabled="!isChangedByUser"
                @click="dialog.isShow = true"
                >Save
              </v-btn>
            </div>
          </div>
        </div>

        <!--        <div v-if="!isShowTextAreaForEdit" class="edit-comment-row">
          <div class="tags-list">
            <span
              class="tags-list__item"
              v-for="(tag, index) in post.tags"
              :key="index"
              >{{ tag }}</span
            >
          </div>
          <div
            v-if="isShowEditIconForClient && !isPreviousFeedback"
            class="edit-icon-container"
            :class="{ 'empty-tags': !post.tags.length }"
          >
            <v-btn
              x-small
              class="purple"
              @click="clickEditBtnHandler('editTemplate_fb_')"
              >Edit caption</v-btn
            >
          </div>
        </div>-->

        <div v-if="!isShowTextAreaForEdit" class="edit-comment-row">
          <div class="tags-list">
            <span
              class="tags-list__item"
              v-for="(tag, index) in post.tags"
              :key="index"
              >{{ tag }}</span
            >
          </div>
          <div
            v-if="
              isShowEditIconForClient &&
              !isPreviousFeedback &&
              postType !== 'smb-dashboard'
            "
            class="edit-icon-container"
            :class="{ 'empty-tags': !post.tags.length }"
          >
            <v-row v-if="post.contentCaptionIds.length > 1" class="icons-row">
              <v-col cols="6">
                <v-row>
                  <v-col cols="3">
                    <v-icon
                      v-if="isShowPrevBtn && currentIndex > 0"
                      class="icons"
                      color="#7e5689"
                      @click="clickPrevArrowHandler"
                      >mdi-arrow-left-top
                    </v-icon>
                  </v-col>
                  <v-col cols="3">
                    <v-icon
                      v-if="
                        isShowNextBtn &&
                        currentIndex < post.contentCaptionIds.length - 1 &&
                        currentIndex < captionHistory.length - 1
                      "
                      class="icons"
                      color="#7e5689"
                      @click="clickNextArrowHandler"
                      >mdi-arrow-right-top
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col class="info-icons">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="icons"
                          color="#1D1D1B"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help
                        </v-icon>
                      </template>
                      <p>
                        We’ve created
                        {{ post.contentCaptionIds.length }} options for you to
                        choose from.
                      </p>
                      <p>
                        The one that shows when you click Approve will be the
                        one we will use.
                      </p>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              x-small
              class="purple"
              @click="clickEditBtnHandler('editTemplate_fb_')"
              >Edit caption
            </v-btn>
            <div
              v-if="post.contentCaptionIds.length > 1"
              class="change-caption-block"
            >
              <v-btn
                x-small
                :loading="isChangeCaptionLoad"
                class="purple"
                :disabled="
                  captionHistory.length === post.contentCaptionIds.length
                "
                @click="changeCaptionHandler"
                >Change caption
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="isLicenseIndication">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            why do you see watermarks?
            <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
          </template>
          <span>
            once you approve the Post the media will be purchased and the
            watermark will be cleared
            <br />
          </span>
        </v-tooltip>
      </div> -->
      <div
        class="post-fb__img"
        v-if="post.media.length"
        :class="{ crop: post.media.length }"
      >
        <splide
          v-if="post.media.length"
          :slides="post.media"
          :options="options"
          class="crop__slider"
        >
          <splide-slide v-for="(file, index) in post.media" :key="index">
            <img
              @click="$emit('clickingOnPost', post)"
              v-if="file.type === 'image'"
              class="post-image"
              :class="{ cover: !file.editedMediaUrl }"
              :src="getMediaUrl(file)"
            />
            <div
              class="video"
              :class="{ isStatic: onlyVideo() }"
              v-else-if="file.type === 'video'"
            >
              <VideoPlayer
                :refName="'clientDashboardPostVideo' + index"
                :videoSrc="file.mediaUrl"
              />
            </div>
          </splide-slide>
        </splide>
        <div class="default-img" v-else>
          <img src="@/assets/img/default-inst-img.svg" alt="" />
        </div>
      </div>
      <div class="post-fb__icons">
        <div class="item">
          <div class="item__icon">
            <i class="icon-like-fb"></i>
          </div>
          <div class="item__name">Like</div>
        </div>
        <div class="item">
          <div class="item__icon">
            <i class="icon-comment-fb"></i>
          </div>
          <div class="item__name">Comment</div>
        </div>
        <div class="item">
          <div class="item__icon">
            <i class="icon-share-fb"></i>
          </div>
          <div class="item__name">Share</div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="post__body"
      @click="bodyPostClick($event)"
      :class="{ 'edit-mode': isShowTextAreaForEdit }"
    >
      <!--      <div class="rejected" v-if="post.customerApprove === 'false'">
        Rejected
      </div>-->
      <!-- <div v-if="isLicenseIndication">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            why do you see watermarks?
            <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
          </template>
          <span>
            once you approve the Post the media will be purchased and the
            watermark will be cleared
            <br />
          </span>
        </v-tooltip>
      </div> -->
      <div class="post__content">
        <div class="post__header" @click="$emit('clickingOnPost', post)">
          <div class="user">
            <div class="user__avatar">
              <img
                v-if="currentCustomer.picUrl"
                :src="currentCustomer.picUrl"
                :alt="currentCustomer.name"
              />
              <img
                v-else
                src="@/assets/default-profile-picture.png"
                :alt="currentCustomer.name"
              />
            </div>
            <div class="user__info">
              <div class="user__name">{{ currentCustomer.name }}</div>
              <div
                class="user__location"
                v-if="getCustomerAddress && isShowCustomerAddress"
              >
                {{ getCustomerAddress }}
              </div>
            </div>
          </div>
          <div class="more-info" @click="toggleSmbNav()">
            <i class="icon-more"></i>
          </div>
        </div>
        <div class="post__img" :class="{ crop: post.media.length }">
          <v-btn
            class="edit-media"
            :class="{
              'centralize-with-animate-item':
                post.videoSupported || post.isCanRevertVideoTemplate,
            }"
            v-if="isContainTemplate"
            @click="editTemplateHandler()"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/edit_image_icon.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/edit_image_icon_hover.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Edit</div>
          </v-btn>
          <v-btn
            class="edit-media animate-item"
            v-if="post.videoSupported"
            @click="$emit('approvedToAnimatePost', post)"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/animate.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/animate_hover.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Change to video</div>
          </v-btn>
          <v-btn
            class="edit-media animate-item"
            v-if="post && post.isCanRevertVideoTemplate"
            @click="$emit('revertAnimatedPost', post)"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/revert-arrow.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/revert-arrow.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Revert</div>
          </v-btn>
          <splide
            v-if="post.media.length"
            :slides="post.media"
            :options="options"
            class="crop__slider"
          >
            <splide-slide v-for="(file, index) in post.media" :key="index">
              <img
                @click="$emit('clickingOnPost', post)"
                v-if="file.type !== 'video'"
                class="post-image"
                :class="{ cover: !file.editedMediaUrlReduced }"
                :src="getMediaUrl(file)"
              />
              <div
                class="video"
                :class="{ isStatic: onlyVideo() }"
                v-else-if="file.type === 'video'"
              >
                <VideoPlayer
                  :isShowButton="false"
                  :refName="'clientDashboardPostVideo' + index"
                  :videoSrc="file.mediaUrl"
                />
              </div>
            </splide-slide>
          </splide>
          <div class="default-img" v-else>
            <img src="@/assets/img/default-inst-img.svg" alt="" />
          </div>
          <div
            v-if="isCanShowEditedLabel && post.editedByCustomer"
            class="edited-label"
          >
            Edited
          </div>
          <div
            v-if="isCanShowFailedLabel && post.publishState === 'error'"
            class="failed-label"
          >
            Failed to post
          </div>
        </div>
        <div @click="$emit('clickingOnPost', post)" class="post__controls">
          <div class="cell">
            <div class="item">
              <i class="icon-hart"></i>
            </div>
            <div class="item">
              <i class="icon-message"></i>
            </div>
            <div class="item">
              <i class="icon-direct"></i>
            </div>
          </div>
          <div class="cell">
            <div class="item">
              <i class="icon-bookmarks"></i>
            </div>
          </div>
        </div>
        <div v-if="postType === 'client'" class="likes">190 likes</div>
        <div
          class="post__text-container"
          @click="
            $emit('clickingOnPost', post);
            toggleMenuForMobile();
          "
        >
          <div class="text">
            <template v-if="isLoadingCaption">
              <div class="captions">
                <div class="caption-line skeleton-loader"></div>
                <div class="caption-line skeleton-loader"></div>
                <div class="caption-line skeleton-loader"></div>
                <div class="caption-line skeleton-loader"></div>
                <div class="caption-line skeleton-loader"></div>
              </div>
            </template>
            <template v-if="!isShowTextAreaForEdit && !isLoadingCaption">
              <div
                v-if="isCanShowBackupData && post.editedByCustomer"
                class="original-data-container"
              >
                <v-menu content-class="menu-content" open-on-hover top offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="original-data-container__title"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Original
                      <v-icon> mdi-cursor-default-click</v-icon>
                    </span>
                  </template>

                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-card-text>
                            <pre
                              v-if="post.text || post.tags.length"
                            ><strong v-if="currentCustomer.name">{{ currentCustomer.name }} </strong>{{
                                post.textBySmm
                              }}</pre>
                            <div class="tags-list">
                              <span
                                class="tags-list__item"
                                v-for="(tag, index) in post.tagsBySmm"
                                :key="index"
                                >{{ tag }}</span
                              >
                            </div>
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
              <pre
                v-if="post.text || post.tags.length"
              ><strong v-if="currentCustomer.name">{{ currentCustomer.name }} </strong>{{ post.text }}</pre>
            </template>
            <div
              v-show="isShowTextAreaForEdit && !isLoadingCaption"
              class="edit-area"
            >
              <div class="edit-area__textarea-container">
                <v-textarea
                  solo
                  placeholder="Type your caption here"
                  class="customer-textarea"
                  :ref="'editTemplate_inst_' + post.id"
                  v-model="postText"
                ></v-textarea>
                <EmojiPicker
                  v-show="$refs['editTemplate_inst_' + post.id]"
                  :inputRef="$refs['editTemplate_inst_' + post.id]"
                  :content="postText"
                  @newValue="postText = $event"
                  class="emoji"
                  customClass="chat"
                />
              </div>
              <div>
                <span
                  >Hashtags
                  <span
                    :style="{ color: postTags.length < 30 ? 'black' : 'red' }"
                    >({{ hashtagsHint }})</span
                  ></span
                >
                <v-combobox
                  :delimiters="[',', ' ', ';', '#']"
                  @change="hashtagsInput"
                  v-model="postTags"
                  chips
                  hide-details
                  class="tags_container"
                  multiple
                  outlined
                >
                  <template
                    v-slot:selection="{ attrs, item, index, select, selected }"
                  >
                    <draggable
                      :id="index"
                      :list="postTags"
                      v-bind="dragOptionsChips"
                      :move="move"
                      @change="change"
                      handle=".v-chip__content strong"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        color="#000000"
                        small
                        draggable
                        class="txt-color"
                        @mousedown.stop
                        @click.stop
                        @click="select"
                        @click:close="deleteTagHandler(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </draggable>
                  </template>
                </v-combobox>
              </div>
              <div
                class="edit-area__buttons"
                v-if="postType !== 'smb-dashboard'"
              >
                <v-btn x-small class="red" @click="canceledEditHandler"
                  >Cancel
                </v-btn>
                <v-btn
                  x-small
                  class="green"
                  :disabled="!isChangedByUser"
                  @click="dialog.isShow = true"
                  >Save
                </v-btn>
              </div>
            </div>
          </div>
          <div v-if="!isShowTextAreaForEdit" class="edit-comment-row">
            <div class="tags-list">
              <span
                class="tags-list__item"
                v-for="(tag, index) in post.tags"
                :key="index"
                >{{ tag }}</span
              >
            </div>
            <div
              v-if="
                isShowEditIconForClient &&
                !isPreviousFeedback &&
                postType !== 'smb-dashboard'
              "
              class="edit-icon-container"
              :class="{ 'empty-tags': !post.tags.length }"
            >
              <v-row v-if="post.contentCaptionIds.length > 1" class="icons-row">
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-icon
                        v-if="isShowPrevBtn && currentIndex > 0"
                        class="icons icon-history"
                        color="#7e5689"
                        @click="clickPrevArrowHandler"
                        >mdi-arrow-left-top
                      </v-icon>
                    </v-col>
                    <v-col cols="6">
                      <v-icon
                        v-if="
                          isShowNextBtn &&
                          currentIndex < post.contentCaptionIds.length - 1 &&
                          currentIndex < captionHistory.length - 1
                        "
                        class="icons icon-history"
                        color="#7e5689"
                        @click="clickNextArrowHandler"
                        >mdi-arrow-right-top
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col class="info-icons">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="icons"
                            color="#1D1D1B"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help
                          </v-icon>
                        </template>
                        <p>
                          We’ve created
                          {{ post.contentCaptionIds.length }} options for you to
                          choose from.
                        </p>
                        <p>
                          The one that shows when you click Approve will be the
                          one we will use.
                        </p>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-btn
                x-small
                class="purple"
                @click="clickEditBtnHandler('editTemplate_inst_')"
                >Edit caption
              </v-btn>
              <div
                v-if="post.contentCaptionIds.length > 1"
                class="change-caption-block"
              >
                <v-btn
                  x-small
                  :loading="isChangeCaptionLoad"
                  class="purple"
                  :disabled="
                    captionHistory.length === post.contentCaptionIds.length
                  "
                  @click="changeCaptionHandler"
                  >Change caption
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="post__slide-menu-container"
        v-if="!isLoadingCaption && !isShowTextAreaForEdit && isShowSlideInMenu"
      >
        <div class="post__slide-menu">
          <div class="slide-nav-button hover">
            <span class="slide-nav-button__icon">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="tone-icon"
              >
                <path
                  d="M1.34412 16.6669C0.867066 16.2207 0.867066 15.4975 1.34412 15.0521L12.7412 4.39321C13.2183 3.94706 13.9916 3.94706 14.4679 4.39321C14.9449 4.83936 14.9449 5.56263 14.4679 6.00802L3.07078 16.6669C2.59454 17.1123 1.82117 17.1123 1.34412 16.6669Z"
                  fill="#92C4FF"
                  stroke="#1D1D1B"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.8787 5.1875L13.6053 6.80246"
                  stroke="#1D1D1B"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9311 3.9775L16.643 3.1704L15.78 2.90086L16.643 2.63208L16.9311 1.82422L17.2185 2.63208L18.0822 2.90086L17.2185 3.1704L16.9311 3.9775Z"
                  fill="#92C4FF"
                  stroke="#1D1D1B"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.7449 2.26167L13.5756 1.78883L13.0701 1.63045L13.5756 1.47284L13.7449 1L13.9135 1.47284L14.419 1.63045L13.9135 1.78883L13.7449 2.26167Z"
                  fill="#92C4FF"
                  stroke="#1D1D1B"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5943 7.85151L16.425 7.37867L15.9194 7.22106L16.425 7.06268L16.5943 6.58984L16.7628 7.06268L17.2684 7.22106L16.7628 7.37867L16.5943 7.85151Z"
                  fill="#92C4FF"
                  stroke="#1D1D1B"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="slide-nav-button__title">Change tone</span>
            <div class="tone-list">
              <div
                class="tone-item"
                v-for="(tone, index) in toneList"
                :key="index"
                @click="changeToneCaption(tone)"
              >
                <div class="tone-item__icon">
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="tone-icon"
                  >
                    <path
                      d="M1.34412 16.6669C0.867066 16.2207 0.867066 15.4975 1.34412 15.0521L12.7412 4.39321C13.2183 3.94706 13.9916 3.94706 14.4679 4.39321C14.9449 4.83936 14.9449 5.56263 14.4679 6.00802L3.07078 16.6669C2.59454 17.1123 1.82117 17.1123 1.34412 16.6669Z"
                      fill="#92C4FF"
                      stroke="#1D1D1B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8787 5.1875L13.6053 6.80246"
                      stroke="#1D1D1B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9311 3.9775L16.643 3.1704L15.78 2.90086L16.643 2.63208L16.9311 1.82422L17.2185 2.63208L18.0822 2.90086L17.2185 3.1704L16.9311 3.9775Z"
                      fill="#92C4FF"
                      stroke="#1D1D1B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.7449 2.26167L13.5756 1.78883L13.0701 1.63045L13.5756 1.47284L13.7449 1L13.9135 1.47284L14.419 1.63045L13.9135 1.78883L13.7449 2.26167Z"
                      fill="#92C4FF"
                      stroke="#1D1D1B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.5943 7.85151L16.425 7.37867L15.9194 7.22106L16.425 7.06268L16.5943 6.58984L16.7628 7.06268L17.2684 7.22106L16.7628 7.37867L16.5943 7.85151Z"
                      fill="#92C4FF"
                      stroke="#1D1D1B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="tone-item__text">
                  Make it {{ tone.toLowerCase() }}
                </div>
              </div>
            </div>
          </div>
          <v-btn class="slide-nav-button" @click="changeToneCaption('long')">
            <span class="slide-nav-button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                class="expand-icon"
              >
                <path
                  d="M5.60693 7.79612L1.7124 11.3317L5.60693 14.8672"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.71231 11.3316L11.0039 11.3818"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.7427 7.79612L26.6551 11.3154L22.7427 14.8672"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2904 11.3316H26.6373"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="slide-nav-button__title">Expand</span>
          </v-btn>
          <v-btn class="slide-nav-button" @click="changeToneCaption('short')">
            <span class="slide-nav-button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                class="shorten-icon"
              >
                <path
                  d="M8.72241 14.8484L12.5998 11.3284L8.72241 7.77734"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.6374 11.313H15.7327"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.6272 14.8484L15.7148 11.3291L19.6272 7.77734"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6169 11.313H1.71221"
                  stroke="#92C4FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="slide-nav-button__title">Shorten</span>
          </v-btn>
          <v-btn class="slide-nav-button" @click="toggleSmbNav('bottom')">
            <span class="slide-nav-button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                class="options-icon"
              >
                <path
                  d="M1.7877 6.92499H7.58426C8.0277 6.92499 8.38563 6.59 8.38563 6.17499V0.75C8.38563 0.33499 8.0277 0 7.58426 0H1.7877C1.34426 0 0.986328 0.33499 0.986328 0.75V6.17499C0.986328 6.59 1.34426 6.92499 1.7877 6.92499Z"
                  fill="#92C4FF"
                />
                <path
                  d="M17.922 0.75C17.922 0.33499 17.5641 0 17.1206 0H11.3241C10.8806 0 10.5227 0.33499 10.5227 0.75V6.17499C10.5227 6.59 10.8806 6.92499 11.3241 6.92499H17.1206C17.5641 6.92499 17.922 6.59 17.922 6.17499V0.75Z"
                  fill="#92C4FF"
                />
                <path
                  d="M1.7877 15.8508H7.58426C8.0277 15.8508 8.38563 15.5158 8.38563 15.1008V9.67578C8.38563 9.2608 8.0277 8.92578 7.58426 8.92578H1.7877C1.34426 8.92578 0.986328 9.2608 0.986328 9.67578V15.1008C0.986328 15.5158 1.34426 15.8508 1.7877 15.8508Z"
                  fill="#92C4FF"
                />
                <path
                  d="M14.2197 8.92578C12.1789 8.92578 10.5227 10.4758 10.5227 12.3858C10.5227 14.2958 12.1789 15.8508 14.2197 15.8508C16.2605 15.8508 17.922 14.2958 17.922 12.3858C17.922 10.4758 16.2605 8.92578 14.2197 8.92578Z"
                  fill="#70C5B0"
                />
              </svg>
            </span>
            <span class="slide-nav-button__title">Options</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      class="post__footer post__footer-client"
      v-if="isShowRating"
      :class="{ 'smb-rating': postType === 'smb-dashboard' }"
    >
      <div v-if="postType !== 'smb-dashboard'" class="rating-status">
        <div
          class="item"
          :class="
            post.customerRating > 0 && post.customerRating <= 2 ? 'active' : ''
          "
        >
          Reject
        </div>
        <div class="item" :class="post.customerRating >= 3 ? 'active' : ''">
          Approve
        </div>
      </div>
      <div v-if="postType === 'smb-dashboard'" class="rating-title">
        Help us improve
      </div>
      <div class="star-list">
        <v-rating
          :readonly="isPreviousFeedback"
          v-model="post.customerRating"
          :length="5"
          :color="post.customerRating <= 2 ? 'red' : '#50B070'"
          :size="ratingSize"
          @input="changeMarkHandler(post)"
        ></v-rating>
      </div>
      <div class="comments" v-if="isCanAddComment">
        <span class="comments__title">Comment</span>
        <div class="comments__control">
          <textarea
            :readonly="isPreviousFeedback"
            :placeholder="isPreviousFeedback ? '' : 'Write your comment...'"
            v-model="post.customerComment"
            :ref="'postComment_' + post.id"
            @focus="$emit('focusOnInput', post)"
            @blur="blurFromInputHandler(post)"
            @input="$emit('changeDataInPost')"
          ></textarea>
        </div>
      </div>
      <div v-if="postType === 'smb-dashboard'" class="smb-schedule">
        <span class="smb-schedule__text">{{
          post.publishState === "published"
            ? "Posted on"
            : +post.scheduledFor > $dayjs(new Date()).unix()
            ? "Scheduled for"
            : "About to be published"
        }}</span>
        <span
          class="smb-schedule__date"
          v-if="
            post.publishState === 'published' ||
            (post.publishState !== 'published' &&
              +post.scheduledFor > $dayjs(new Date()).unix())
          "
          >{{ getPublishDate }}</span
        >
      </div>
    </div>
    <div
      class="post__footer post__footer-info"
      v-if="isShowComment && (post.customerComment || post.customerRating)"
    >
      <div class="action-date" v-if="isShowActionDateInFooter">
        <div class="date-title">{{ actionDateTitle }}</div>
        <div class="date-text">
          {{ $dayjs.unix(post.updatedAt).format("MM.DD.YYYY") }}
        </div>
      </div>
      <div class="top-row">
        <div class="title" :class="post.customerRating >= 3 ? 'active' : ''">
          {{ post.customerComment ? "Comment" : "" }}
        </div>
        <div class="grade">
          <span>Grade</span>
          <div class="rating">
            <v-rating
              v-model="post.customerRating"
              :length="5"
              :readonly="true"
              :size="35"
            ></v-rating>
          </div>
        </div>
      </div>
      <div class="comment">
        {{ post.customerComment }}
      </div>
    </div>
    <div
      class="post__footer"
      v-if="postType === 'smb-dashboard' && isShowSmbAction"
    >
      <div class="footer-smb">
        <div class="footer-smb__edit-content" v-if="isShowTextAreaForEdit">
          <v-btn
            variant="outlined"
            class="save"
            :disabled="!isChangedByUser"
            @click="dialog.isShow = true"
            >Save changes
          </v-btn>
          <v-btn variant="outlined" class="cancel" @click="canceledEditHandler"
            >Cancel
          </v-btn>
        </div>
        <div class="footer-smb__row">
          <div class="footer-smb__cell">
            <v-btn
              variant="outlined"
              class="blue-btn schedule"
              @click="schedulePost()"
              ><v-progress-circular
                indeterminate
                v-if="post.isLoadingSchedule"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <div class="schedule__container" v-else>
                <template v-if="getPublishDate">
                  Schedule for
                  <span class="schedule-date">{{ getPublishDate }}</span>
                </template>
                <template v-else>Schedule</template>
              </div>
            </v-btn>
          </div>
          <div class="footer-smb__cell">
            <v-btn
              variant="outlined"
              class="blue-btn publish-now"
              @click="publishPostNow()"
            >
              <v-progress-circular
                indeterminate
                v-if="post.isLoadingPublishNow"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <template v-else>Publish now</template>
            </v-btn>
          </div>
        </div>
        <div
          class="footer-smb__row"
          v-if="isCanDeleteContent || isCanSaveForLater"
        >
          <div v-if="isCanDeleteContent" class="footer-smb__cell">
            <v-btn
              variant="outlined"
              class="white-btn reject"
              @click="rejectPost()"
            >
              Delete content
            </v-btn>
          </div>
          <div v-if="isCanSaveForLater" class="footer-smb__cell">
            <v-btn
              variant="outlined"
              :disabled="post.isLoadingSaveForLater"
              class="white-btn reject"
              @click="$emit('saveForLater', post)"
            >
              <v-progress-circular
                indeterminate
                v-if="post.isLoadingSaveForLater"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <template v-else>Save for later</template>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        class="transparent-overlay"
        @click="clickOutsideEditArea"
        v-if="isShowTransparentOverlay"
      ></div>
    </div>

    <SmbPostClientNav
      v-if="isShowSmbNav"
      :post="post"
      :customClass="smbNavCustomClass"
      :isDisableGenerateCaptionBtn="
        post.contentCaptionIds.length <= 1 ||
        captionHistory.length === post.contentCaptionIds.length - 1
      "
      :isDisableEditTemplate="editTemplateIsDisabled"
      :isDisableEditMedia="isShowAddEditMedia(post.media)"
      :isPosted="isPosted"
      :isScheduledContent="isScheduledContent"
      v-click-outside="hideSmbNav"
      @editCaption="clickEditBtnHandler('editTemplate_inst_')"
      @generateNewCaption="changeCaptionHandlerSmb"
      @changeScheduledTime="$emit('changeScheduledTime'), hideSmbNav()"
      @cancelScheduling="cancelSchedulingHandler"
      @closeMenu="hideSmbNav()"
      @howToEditLiveFbPost="hideSmbNav, $emit('howToEditLiveFbPost')"
      @howToEditLiveIgPost="hideSmbNav, $emit('howToEditLiveIgPost')"
      @editMedia="editMediaHandler()"
      @editTemplate="editTemplateHandler"
      @revertOriginalCaption="revertInitialCaption"
      @changeSocialAccounts="$emit('changeSocialAccounts', post)"
    />
    <SmbEditMedia
      @close="closeModalEditTemplate"
      v-if="isShowEditMediaModal"
      :post="post"
      @updateSmbPostMedia="$emit('editTemplateImage', $event)"
    />
    <ModalDialog
      :isShow="dialog.isShow"
      :title="dialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="dialog.footerButtons"
      @success="approvedEditHandler"
      @close="closeModalHandler"
    />
    <ModalDialog
      :isShow="approveEditCaption.isShow"
      :title="approveEditCaption.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="approveEditCaption.footerButtons"
      @success="approvedEditHandler"
      @close="approveEditCaption.isShow = false"
      @reject="rejectChangesCaption"
    />
    <ModalDialog
      :isShow="isCancelScheduleDialog.isShow"
      :title="isCancelScheduleDialog.title"
      :subTitle="isCancelScheduleDialog.subTitle"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="isCancelScheduleDialog.footerButtons"
      @cancelScheduling="
        (isCancelScheduleDialog.isShow = false), $emit('cancelScheduling', post)
      "
      @back="isCancelScheduleDialog.isShow = false"
      @close="isCancelScheduleDialog.isShow = false"
    />
    <ModalDialog
      :isShow="editTemplateDialogIsShow"
      :size="1000"
      :isCanClose="false"
      customClass="edit-template-modal"
      @close="editTemplateDialogIsShow = false"
    >
      <template v-slot:description>
        <SmbEditTemplate
          v-if="editTemplateDialogIsShow"
          :post="post"
          :isEditTemplate="true"
          @close="editTemplateDialogIsShow = false"
          :editedTemplate="selectTemplateForEdit()"
          @updateSmbPostMedia="
            $emit('editTemplateImage', $event);
            editTemplateDialogIsShow = false;
          "
        />
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import helper from "@/mixins/helper";
import VideoPlayer from "@/components/global/VideoPlayer";
import ModalDialog from "@/components/global/ModalDialog";
import SmbPostClientNav from "@/components/smb-client/SmbPostClientNav";
import SmbEditMedia from "@/components/smb-client/SmbEditMedia";
import SmbEditTemplate from "@/components/smb-client/SmbEditTemplate";
import H from "@/utils/helper";
import LogRocket from "logrocket";
import Template from "@/models/Template";

export default {
  name: "Post",
  data: () => ({
    isApproveValue: false,
    isShowSmbNav: false,
    isShowSlideInMenu: false,
    checkedId: null,
    isShowTextAreaForEdit: false,
    editTemplateDialogIsShow: false,
    postText: "",
    smbNavCustomClass: "",
    postTags: [],
    selectedSync: [],
    isShowBackupData: false,
    dialog: {
      isShow: false,
      title: "Are you sure you want to change the captions/tag(s)?",
      footerButtons: [
        { name: "Yes", emitName: "success", btnClass: "black" },
        { name: "No", emitName: "close", btnClass: "black-border" },
      ],
    },
    approveEditCaption: {
      isShow: false,
      title: "There are unsaved changes to your captions.",
      footerButtons: [
        { name: "Save changes", emitName: "success", btnClass: "black" },
        {
          name: "Reject changes",
          emitName: "reject",
          btnClass: "black-border",
        },
      ],
    },
    click: null,
    currentIndex: 0,
    isChangeCaptionLoad: false,
    captionHistory: [],
    isShowPrevBtn: false,
    isShowNextBtn: false,
    isLoadingCaption: false,
    isShowTransparentOverlay: false,
    isCancelScheduleDialog: {
      isShow: false,
      title: "Are you sure?",
      subTitle: "The content will move back to Pending for editing",
      footerButtons: [
        {
          name: "Cancel scheduling",
          emitName: "cancelScheduling",
          btnClass: "black",
        },
        { name: "Back", emitName: "back", btnClass: "black-border" },
      ],
    },
    isShowEditMediaModal: false,
    editTemplateIsDisabled: false,
  }),
  mixins: [helper],
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    postType: {
      type: String,
      default: "",
    },
    isShowSwitch: {
      type: Boolean,
      default: true,
    },
    isShowComment: {
      type: Boolean,
      default: false,
    },
    isShowRating: {
      type: Boolean,
      default: false,
    },
    isFacebookPlatform: {
      type: Boolean,
      default: false,
    },
    isCanAddComment: {
      type: Boolean,
      default: false,
    },
    isShowSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isShowMakeChangesButton: {
      type: Boolean,
      default: false,
    },
    focusToElementId: {
      type: String,
    },
    isShowEditIconForClient: {
      type: Boolean,
      default: false,
    },
    isCanShowEditedLabel: {
      type: Boolean,
      default: false,
    },
    isCanShowBackupData: {
      type: Boolean,
      default: true,
    },
    isCanShowFailedLabel: {
      type: Boolean,
      default: false,
    },
    isPreviousFeedback: {
      type: Boolean,
      default: false,
    },
    isShowCustomerAddress: {
      type: Boolean,
      default: false,
    },
    isShowActionDateInFooter: {
      type: Boolean,
      default: false,
    },
    actionDateTitle: {
      type: String,
      default: "",
    },
    currentCustomer: {
      type: Object,
      default: () => {},
    },
    isShowSmbAction: {
      type: Boolean,
      default: false,
    },
    ratingSize: {
      type: [String, Number],
      default: 35,
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    isScheduledContent: {
      type: Boolean,
      default: false,
    },
    isContentCalendarPage: {
      type: Boolean,
      default: false,
    },
    isCanDeleteContent: {
      type: Boolean,
      default: false,
    },
    isCanSaveForLater: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VideoPlayer,
    EmojiPicker: () => import("@/components/global/EmojiPicker"),
    ModalDialog,
    SmbPostClientNav,
    SmbEditMedia,
    SmbEditTemplate,
  },
  computed: {
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerAddress",
    ]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("createPost", ["getFeedbackApprovedList"]),
    ...mapGetters("videoPlayer", ["getActiveVideoRefName", "getActiveVideo"]),
    isApprove: {
      get: function () {
        if (this.$route.name === "create-post-pre-feedback") {
          return this.getFeedbackApprovedList.includes(this.post.id)
            ? this.getFeedbackApprovedList.includes(this.post.id)
            : false;
        } else {
          return false;
        }
      },
      set: function (newValue) {
        this.isApproveValue = newValue;
      },
    },
    isChangedByUser() {
      return (
        this.postText !== this.post.text ||
        JSON.stringify(this.post.tags) !== JSON.stringify(this.postTags)
      );
    },
    isTextTheSameAsOriginal() {
      return this.post.text === this.postText;
    },
    isTagsTheSameAsOriginal() {
      return JSON.stringify(this.post.tags) === JSON.stringify(this.postTags);
    },
    options() {
      return {
        type: "slide",
        perPage: 1,
        fixedHeight: "40vh",
        arrows: true,
        autoWidth: false,
        width: "500px",
        cover: true,
        pagination: this.post.media.length > 1,
      };
    },
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
    hashtagsHint() {
      return `${this.postTags.length}/30`;
    },
    getPublishDate() {
      return this.parseStringDateForSmb(this.post.scheduledForStr);
    },
    toneList() {
      if (this.getCurrentSmbCustomer.brand_values.length) {
        return this.getCurrentSmbCustomer.brand_values;
      } else {
        return ["professional", "informative", "witty"];
      }
    },
    isContainTemplate() {
      return this.post.media.find((i) => i.template !== null) ? true : false;
    },
  },
  mounted() {
    if (this.post.tags === "") {
      this.post.tags = [];
    }

    if (this.post.contentCaptionIds.length && this.postType === "client") {
      this.currentIndex = 0;
      this.setCaptionByIndexFromList(this.currentIndex);
    }

    window.addEventListener("click", this.clickOutsideHandler);
    if (!this.$isMobile()) {
      this.isShowSlideInMenu = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.clickOutsideHandler);
  },
  methods: {
    ...mapActions("clientDashboard", ["updatePosts"]),
    ...mapMutations("videoPlayer", ["setToActiveVideo"]),
    ...mapActions("calendar", ["fetchContentCaption"]),
    ...mapActions("smbPost", ["changePostCaptionTone", "getPost"]),

    bodyPostClick(event) {
      if (this.$isMobile() && !event.target.closest(".text")) {
        this.isShowSlideInMenu = true;
      }
    },

    clickOutsideHandler(event) {
      if (this.$isMobile() && !event.target.closest(".post__body")) {
        this.isShowSlideInMenu = false;
      }
    },

    toggleMenuForMobile() {
      if (this.$isMobile()) {
        this.isShowSlideInMenu = !this.isShowSlideInMenu;
      }
    },

    editMediaHandler() {
      this.hideSmbNav();
      this.isShowEditMediaModal = true;
    },

    editTemplateHandler() {
      this.$emit("showedEditTemplateDialog");
      this.editTemplateDialogIsShow = true;
    },

    isShowOverlay() {
      this.isShowTransparentOverlay = !this.isShowTransparentOverlay;
    },

    clickOutsideEditArea() {
      if (this.isChangedByUser) {
        this.approveEditCaption.isShow = true;
      } else {
        this.canceledEditHandler();
      }
    },

    rejectChangesCaption() {
      this.canceledEditHandler();
      this.approveEditCaption.isShow = false;
      this.isShowTransparentOverlay = false;
    },

    getMediaUrl(file) {
      return file.editedMediaUrlReduced || file.editedMediaUrl || file.mediaUrl;
    },

    hideSmbNav() {
      if (this.isShowSmbNav) {
        this.isShowSmbNav = false;
        this.smbNavCustomClass = "";
      }
    },

    closeModalEditTemplate() {
      if (this.isShowEditMediaModal) {
        this.isShowEditMediaModal = false;
      }
    },

    selectTemplateForEdit() {
      let mediaWithTemplate = this.post.media.find((i) => i.template !== null);
      if (mediaWithTemplate) {
        return mediaWithTemplate.template;
      } else {
        this.editTemplateIsDisabled = true;
      }
    },

    rejectPost() {
      this.$emit("rejectSmbPost", this.post);
    },

    savePost() {
      this.$emit("saveSmbPost", this.post);
    },

    publishPostNow() {
      this.$emit("publishSmbPostNow", this.post);
    },

    schedulePost() {
      this.$emit("scheduleSmbPost", this.post);
    },

    toggleSmbNav(className = "") {
      if (this.postType === "smb-dashboard" && !this.isShowTextAreaForEdit) {
        if (!this.isShowSmbNav) {
          LogRocket.track("pending_approval_edit");
        }

        this.isShowSmbNav = !this.isShowSmbNav;

        if (this.isShowSmbNav) {
          this.selectTemplateForEdit();
        }

        className
          ? (this.smbNavCustomClass = className)
          : (this.smbNavCustomClass = "");
      }
    },

    changeMarkHandler(post) {
      if (this.$device.mobile) {
        if (post.customerRating <= 2) {
          this.$emit("updateRejectedList", post);
        } else {
          this.$emit("deleteFromRejectedContent", post);
        }
      }
      this.$emit("changeDataInPost", post);
    },

    blurFromInputHandler(post) {
      if (this.$device.mobile) {
        this.$emit("blurFromInput", post);
      }
    },

    onlyVideo() {
      if (this.post.media.length) {
        return !this.post.media
          .map((file) => file.type === "image")
          .includes(true);
      }
    },

    isProcessedImages(media) {
      if (media.length) {
        const data = media.filter((item) => !H.isVideo(item.mediaUrl));
        return data.some((item) => !item.editedMediaUrlReduced) ? true : false;
      } else {
        false;
      }
    },

    isShowAddEditMedia(media) {
      if (media.length) {
        return H.isVideo(media[0].mediaUrl);
      } else {
        false;
      }
    },

    clickEditBtnHandler(ref) {
      this.$emit("editCaption");
      this.hideSmbNav();
      this.isShowOverlay();
      this.postText = this.post.text;
      this.postTags = this.post.tags.length ? [...this.post.tags] : [];

      this.isShowTextAreaForEdit = true;
      this.$nextTick(() => {
        this.$refs[ref + this.post.id].$refs.input.focus();
      });
    },

    async approvedEditHandler() {
      try {
        this.isShowTransparentOverlay = false;
        this.approveEditCaption.isShow = false;
        this.isLoadingCaption = true;
        await this.updatePosts({
          id: this.post.id,
          byCustomer: true,
          tags: !this.isTagsTheSameAsOriginal ? this.postTags : undefined,
          text: !this.isTextTheSameAsOriginal ? this.postText : undefined,
          contentCaptionId:
            this.post.contentCaptionIds.length && !this.isTextTheSameAsOriginal
              ? this.post.contentCaptionId
              : undefined,
          customerComment: null,
          customerRating: 5,
        });
        this.isLoadingCaption = false;
      } catch (e) {
        console.log(e);
        this.isLoadingCaption = false;
      }

      this.post.tags = !this.isTagsTheSameAsOriginal
        ? [...this.postTags]
        : this.post.tags;
      this.post.text = !this.isTextTheSameAsOriginal
        ? this.postText
        : this.post.text;
      this.post.customerRating = 5;
      this.post.customerComment = null;

      this.isShowTextAreaForEdit = false;
      this.dialog.isShow = false;
    },

    closeModalHandler() {
      this.dialog.isShow = false;
    },
    canceledEditHandler() {
      this.isShowTextAreaForEdit = false;
      this.isShowTransparentOverlay = false;
    },
    hashtagsInput(val) {
      if (!val && !val.length) return;

      if (this.postTags.length < 30) {
        this.postTags = H.hashtagsCompose(val);
      } else {
        this.postTags = this.postTags.filter((item, index) => index < 30);
      }
    },
    deleteTagHandler(tag) {
      this.postTags = this.postTags.filter((val) => val !== tag);
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change(value) {
      if (value.removed) {
        // insert
        this.postTags.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.postTags[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.postTags.splice(this.dragged.from, 1);
        // RTL
        else this.postTags.splice(this.dragged.from + 1, 1);
      }
    },
    async setCaptionByIndexFromList(index) {
      this.isChangeCaptionLoad = true;
      try {
        const {
          text,
          textBeforeEdit,
          id: contentCaptionId,
        } = await this.fetchContentCaption(this.post.contentCaptionIds[index]);

        this.$emit("updateCaptionValues", {
          postId: this.post.id,
          newValues: { text, textBeforeEdit, contentCaptionId },
        });

        this.captionHistory.push({ text, textBeforeEdit, contentCaptionId });

        this.postText = text;
        this.currentIndex = index;
      } catch (e) {
        throw e;
      } finally {
        this.isChangeCaptionLoad = false;
      }
    },
    async changeCaptionHandler() {
      this.hideSmbNav();
      this.isLoadingCaption = true;
      await this.setCaptionByIndexFromList(this.captionHistory.length);
      this.isLoadingCaption = false;
      this.isShowPrevBtn = true;
      this.isShowNextBtn = false;
    },
    async changeCaptionHandlerSmb() {
      this.hideSmbNav();
      this.isLoadingCaption = true;
      await this.setCaptionByIndexFromList(this.captionHistory.length + 1);
      this.isLoadingCaption = false;
      this.isShowPrevBtn = true;
      this.isShowNextBtn = false;
    },
    clickPrevArrowHandler() {
      this.currentIndex -= 1;

      this.$emit("updateCaptionValues", {
        postId: this.post.id,
        newValues: this.captionHistory[this.currentIndex],
      });

      this.isShowNextBtn = true;
    },
    clickNextArrowHandler() {
      this.currentIndex += 1;

      this.$emit("updateCaptionValues", {
        postId: this.post.id,
        newValues: this.captionHistory[this.currentIndex],
      });
    },
    cancelSchedulingHandler() {
      this.hideSmbNav();
      if (this.isScheduledContent) {
        this.isCancelScheduleDialog.isShow = true;
      } else {
        this.$emit("cancelScheduling", this.post);
      }
    },

    async changeToneCaption(tone) {
      LogRocket.track("pending_approval_captions_rephrased");
      try {
        this.isLoadingCaption = true;
        const params = {
          action: "getRephrasedText",
          text: this.post.text,
          tone: tone.toLowerCase(),
        };
        const data = await this.changePostCaptionTone(params);
        if (data) {
          await this.updatePosts({
            id: this.post.id,
            byCustomer: true,
            text: data.text,
            contentCaptionId: this.post.contentCaptionId,
          });
        }
        let updatedPostData = await this.getPost(this.post.id);
        this.$emit("updateCaptionValues", {
          postId: this.post.id,
          newValues: {
            text: data.text,
            editedByCustomer: true,
            textBySmm: updatedPostData[0].textBySmm,
          },
        });
        this.isLoadingCaption = false;
      } catch (e) {
        console.log(e);
        this.isLoadingCaption = false;
      }
    },
    async revertInitialCaption() {
      this.hideSmbNav();
      try {
        this.isLoadingCaption = true;
        await this.updatePosts({
          id: this.post.id,
          byCustomer: true,
          text: this.post.textBySmm,
          contentCaptionId: this.post.contentCaptionId,
        });
        this.$emit("updateCaptionValues", {
          postId: this.post.id,
          newValues: { text: this.post.textBySmm, editedByCustomer: false },
        });
        this.isLoadingCaption = false;
      } catch (e) {
        console.log(e);
        this.isLoadingCaption = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.post {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 700px;

  .post__slide-menu-container,
  .edit-media,
  .rejected {
    display: none;
  }

  &.smb-dashboard,
  &.client {
    height: 100%;
    max-height: 650px;
    flex: 1;
    @media screen and (max-height: 850px) {
      max-height: 550px;
    }
    @media screen and (max-width: 920px) {
      max-height: initial;
    }

    .rejected {
      text-transform: uppercase;
      color: #ff4853;
      opacity: 0.6;
      transform: rotate(-60deg);
      font-weight: bold;
      font-size: 64px;
      line-height: 140%;
      position: absolute;
      z-index: 10;
      top: 200px;
      display: block;
    }

    .post {
      position: relative;

      &__header {
        padding: 12px 10px;
      }

      &__text-container {
        padding: 0 10px;
      }

      &__content {
        width: 100%;
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .text {
          position: relative;

          pre {
            font-size: 10px;
          }
        }

        .tags-list {
          &__item {
            font-size: 10px;
          }
        }

        .user {
          &__name {
            font-size: 9px;
          }
        }

        .edit-icon-container {
          button {
            //width: 100px !important;
          }
        }

        .likes {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          padding-bottom: 8px;
          margin-left: 17px;
        }
      }

      &__controls {
        padding: 13px 10px 7px;
      }

      &__slider {
        height: 480px;
      }

      &__img {
        height: 480px;
        position: relative;

        .edit-media {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          height: auto;
          width: auto;
          min-width: auto;
          padding: 0;
          letter-spacing: initial;
          text-transform: none;
          border: 1px solid #1d1d1b;
          border-radius: 5px;
          transition: all 0.3s ease;
          display: none;

          ::v-deep {
            .v-btn__content {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 5px;
              min-width: 80px;
            }
          }

          &__icon {
            display: inline-flex;

            img {
              width: 18px;
            }

            .main-icon {
              display: block;
            }

            .hover-icon {
              display: none;
            }
          }

          &__text {
            color: #1d1d1b;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            margin-top: 2px;
          }

          &:hover {
            background: #92c4ff;

            .edit-media {
              &__icon {
                .main-icon {
                  position: relative;
                  left: 1px;
                  display: none;
                }

                .hover-icon {
                  display: block;
                }
              }
            }
          }
          &.centralize-with-animate-item {
            left: 25%;
            width: 100%;
            max-width: 95px;
            .edit-media__icon {
              img {
                width: 24px !important;
              }
            }
            .edit-media__text {
              padding-right: 6px;
              font-size: 10px;
            }
          }
          &.animate-item {
            left: 75%;
            width: 100%;
            max-width: 95px;
            .edit-media__icon {
              img {
                width: 24px !important;
              }
            }
            .edit-media__text {
              font-size: 10px;
            }
          }
        }

        &:hover {
          .edit-media {
            display: inline-flex;
          }
        }

        ::v-deep {
          .splide {
            &__pagination {
              bottom: -26px;
            }
          }
        }
      }

      &__body {
        flex: 1;
        padding: 0;
      }
    }

    .smb-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 500px) {
        margin-bottom: 0;
      }

      .rating-title {
        font-weight: 500;
        font-size: 10px;
        color: #1d1d1b;
        padding-bottom: 10px;
      }

      ::v-deep {
        .star-list {
          button {
            margin: 0 5px;
          }

          .v-rating {
            .accent--text {
              color: #c7c9ca !important;
              caret-color: #c7c9ca !important;
            }
          }
        }
      }
    }
  }

  &__body {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 500px) {
      overflow-y: unset;
      overflow-x: unset;
    }

    &.edit-mode {
      position: relative;
      z-index: 12;
    }
  }

  &__content {
    background: $color-white;
    border-top: 1px solid #eeeff0;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06) !important;
    border-radius: 0 !important;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    cursor: pointer;

    .user {
      display: inline-flex;
      align-items: center;

      &__avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 8px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        display: inline-flex;
        flex-direction: column;
      }

      &__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: $color-black;
      }

      &__location {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: #515456;

        span {
          margin-right: 4px;
        }
      }
    }

    .more-info {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: $color-black;
      }
    }
  }

  &__img {
    background: rgba(78, 87, 120, 0.2);
    height: 480px;
    position: relative;

    .edited-label {
      position: absolute;
      padding: 0px 4px;
      background: black;
      color: rgb(234, 176, 181);
      font-size: 20px;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
    }

    .failed-label {
      position: absolute;
      padding: 0px 4px;
      background: red;
      color: #ffffff;
      font-size: 15px;
      top: 50%;
      max-width: 105px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    .default-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.blur {
      overflow: hidden;

      .splide {
        filter: blur(8px);
      }
    }

    &-loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1000;

      span {
        text-align: center;
        color: #fff;
        font-size: 12px;
        padding: 5px;
      }
    }
  }

  &__slider {
    width: 100% !important;
    height: 480px;
    margin-bottom: 13px;

    ::v-deep {
      .splide {
        &__track {
          height: 100%;
        }

        &__list {
          height: 100%;
        }

        &__slide {
          height: 100% !important;
          width: 100%;
          background-size: cover !important;

          .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__arrow {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &:disabled {
            opacity: 0;
          }

          &--prev {
            &:before {
              font-family: "icomoon" !important;
              content: "\e902";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          &--next {
            &:before {
              font-family: "icomoon" !important;
              content: "\e90c";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          svg {
            display: none;
          }
        }

        &__pagination {
          padding-left: 0;
          bottom: -27px;

          &__page {
            width: 4px;
            height: 4px;
            background: $color-grey3;
            transform: scale(1);
            padding: 2px !important;
            transition: all 0.3s ease;

            &.is-active {
              background: $color-black;
            }
          }
        }
      }
    }

    .content-container {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-file {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
    }

    .inst-video {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__controls {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px;

    .cell {
      display: inline-flex;
      align-items: center;

      .item {
        display: inline-flex;
        margin-right: 12px;

        i {
          font-size: 17px;
          color: $color-black;
          font-weight: bold;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__text-container {
    width: 100%;
    padding: 0 16px;

    .likes {
      font-weight: 500;
      font-size: 9px;
      line-height: 140%;
      color: $color-black;
      margin-bottom: 8px;
    }

    .text {
      pre {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #515456;
        white-space: pre-wrap;
        font-family: $base-font;
      }

      ::v-deep {
        .original-data-container {
          display: flex;
          justify-content: flex-end;

          &__title,
          .v-icon {
            cursor: pointer;
            font-size: 12px;
            color: red !important;
          }
        }
      }

      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #515456;
      margin-bottom: 8px;
    }

    .tags-list {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      max-width: 200px;

      &__item {
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: $link-color;
        margin-right: 5px;
        max-width: 175px;
        cursor: pointer;

        &:hover {
          color: $link-color-hover;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    position: relative;
    bottom: -3px;

    &-client {
      .rating-status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: 8px;

        .item {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: $color-grey3;
          transition: all 0.3s ease;

          &:first-child {
            &.active {
              transition: all 0.3s ease;
              color: $color-red;
            }
          }

          &:last-child {
            &.active {
              transition: all 0.3s ease;
              color: $color-green;
            }
          }
        }
      }

      .star-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        ::v-deep {
          .v-rating {
            &--readonly {
              opacity: 0.5;
            }

            .mdi-star,
            .mdi-star-outline {
              &:before {
                font-family: "icomoon" !important;
                content: "\e907";
              }
            }

            .v-icon {
              padding: 0;
              margin: 0 9px;
              color: $color-grey3;

              &.red--text {
                color: $color-red;
              }

              &.green--text {
                color: $color-green;
              }
            }
          }
        }
      }

      .comments {
        width: 100%;
        padding: 0 16px;

        &__title {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: $color-black;
          margin-bottom: 4px;
        }

        &__control {
          width: 100%;

          textarea {
            width: 100%;
            resize: none;
            height: 68px;
            background: #fafafa;
            border: 1px solid $color-grey3;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 8px 12px;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            outline: none;

            &:read-only {
              border: none;
              padding-left: 0;
              padding-right: 0;
              background: none;
              cursor: default;
            }
          }
        }
      }
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;

    .btn {
      height: 36px;
      width: 100%;
      max-width: 200px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: $color-white;
      border-radius: 16px;
      background: $color-violet;
      transition: all 0.3s ease;

      &:hover {
        background: darken($color-violet, 10);
      }
    }
  }

  &__footer-info {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0 15px;

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 11px !important;
        line-height: 130%;
        color: $color-red;

        &.active {
          color: $color-green;
        }
      }

      .grade {
        display: inline-flex;
        align-items: center;

        span {
          font-weight: normal;
          font-size: 10px;
          line-height: 140%;
          color: $color-grey2;
          margin-right: 10px;
        }

        .rating {
          ::v-deep {
            .v-rating {
              .mdi-star,
              .mdi-star-outline {
                &:before {
                  font-family: "icomoon" !important;
                  content: "\e907";
                  font-size: 10px;
                }
              }

              .v-icon {
                padding: 0;
                margin: 0 1px;
                color: $color-grey3;

                &.accent--text {
                  color: $color-grey3 !important;
                }

                &.primary--text {
                  color: #ffc273 !important;
                }
              }
            }
          }
        }
      }
    }

    .comment {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $color-black;
      max-height: 80px;
      overflow-y: auto;
    }
  }

  &__img {
    &.crop {
      height: auto !important;

      ::v-deep {
        .splide {
          &__list {
            width: 100%;
          }

          &__slide {
            width: 100% !important;
            background: none !important;
            height: auto !important;
            position: relative;

            img {
              width: 100%;
              display: block !important;

              &.cover {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .video {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;

              .video-wrapper .video-container {
                object-fit: contain;
              }

              &.isStatic {
                position: static;
              }
            }
          }

          &__arrow {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:disabled {
              opacity: 0;
            }

            &--prev {
              &:before {
                font-family: "icomoon" !important;
                content: "\e902";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }

            &--next {
              &:before {
                font-family: "icomoon" !important;
                content: "\e90c";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }

            svg {
              display: none;
            }
          }

          &__pagination {
            padding-left: 0;
            bottom: -18px;

            &__page {
              width: 4px;
              height: 4px;
              background: $color-grey3;
              transform: scale(1);
              padding: 2px !important;
              transition: all 0.3s ease;

              &.is-active {
                background: $color-black;
              }
            }
          }
        }
      }
    }
  }

  .footer-smb {
    border-top: 1px solid rgba(47, 72, 88, 0.2);
    padding: 5px 3px;
    display: flex;
    z-index: 10;
    position: relative;
    background: #fff;
    flex-direction: column;

    &__row {
      display: grid;
      grid-template-columns: 1fr 85px;
      grid-gap: 4px;
      @media screen and (min-width: 500px) {
        grid-template-columns: 1fr 70px;
      }
      &:nth-child(2) {
        grid-template-columns: 1fr;
        margin-top: 4px;
      }
    }

    .v-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      background: #ffffff;
      color: #1d1d1b;
      border: 1px solid #1d1d1b;
      border-radius: 5px !important;
      text-transform: none;
      padding: 1px 3px;
      box-shadow: none;
      letter-spacing: inherit;
      height: 37px !important;
      width: 100%;
      text-indent: initial;
      flex: 1;
      @media screen and (max-height: 850px) {
        height: 32px !important;
      }
      @media screen and (min-width: 500px) {
        letter-spacing: -0.5px;
      }

      &.reject {
        font-size: 10px;
      }

      &.blue-btn {
        background: #92c4ff;
        font-weight: 700;
        font-size: 11px;
        line-height: 150%;

        ::v-deep {
          .schedule-date {
            font-size: 10px;
            margin-left: 3px;
          }
        }

        &.schedule {
          font-size: 9px;
          @media screen and (max-width: 550px) {
            font-size: 12px !important;
          }

          ::v-deep {
            .schedule-date {
              font-size: 10px;
              margin-left: 3px;
              @media screen and (max-width: 550px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }

    &__edit-content {
      display: grid;
      grid-template-columns: 1fr 87px;
      grid-gap: 8px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      position: relative;
      z-index: 12;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -10px;
        right: -10px;
        height: 1px;
        background: rgba(47, 72, 88, 0.2);
      }

      .v-btn {
        font-weight: 500;
        font-size: 15px;
        line-height: 150%;
        background: #ffffff;
        color: #1d1d1b;
        border: 1px solid #1d1d1b;
        border-radius: 5px !important;
        text-transform: none;
        padding: 1px 3px;
        box-shadow: none;
        letter-spacing: initial;
        height: 37px !important;
        width: 100%;
        text-indent: initial;
        flex: 1;

        &.save {
          background: #92c4ff;
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;

          &:disabled,
          &.disabled {
            border-color: rgba(0, 0, 0, 0.12) !important;
          }
        }

        &.cancel {
          font-size: 11px;
          background: #fff !important;
        }
      }
    }
  }
}

.post-fb {
  display: flex;
  flex-direction: column;
  max-height: 750px;
  flex: 1;
  height: calc(100% - 30px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 500px) {
    overflow-y: unset;
    overflow-x: unset;
  }
  @media screen and (max-width: 920px) {
    max-height: initial;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    cursor: pointer;

    .user {
      display: inline-flex;
      align-items: center;

      &__avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 8px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        display: inline-flex;
        flex-direction: column;
      }

      &__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: $color-black;
      }

      &__location {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: #515456;

        span {
          margin-right: 4px;
        }
      }
    }

    .more-info {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: $color-black;
      }
    }
  }

  &__text-container {
    padding: 0 16px;

    &.edit-mode {
      position: relative;
      z-index: 12;
    }

    .text {
      pre {
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #515456;
        white-space: pre-wrap;
        font-family: $base-font;
      }

      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #515456;
      margin-bottom: 8px;
    }

    .tags-list {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &__item {
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: $link-color;
        margin-right: 5px;
      }
    }
  }

  &__img {
    .default-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.crop {
      height: auto !important;

      ::v-deep {
        .splide {
          &__list {
            width: 100%;
          }

          &__slide {
            width: 100% !important;
            background: none !important;
            height: auto !important;
            position: relative;

            img {
              width: 100%;
              display: block !important;

              &.cover {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .video {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;

              .video-wrapper .video-container {
                object-fit: contain;
              }

              &.isStatic {
                position: static;
              }
            }
          }

          &__arrow {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:disabled {
              opacity: 0;
            }

            &--prev {
              &:before {
                font-family: "icomoon" !important;
                content: "\e902";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }

            &--next {
              &:before {
                font-family: "icomoon" !important;
                content: "\e90c";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }

            svg {
              display: none;
            }
          }

          &__pagination {
            padding-left: 0;
            bottom: 10px;

            &__page {
              width: 4px;
              height: 4px;
              background: $color-grey3;
              transform: scale(1);
              padding: 2px !important;
              transition: all 0.3s ease;

              &.is-active {
                background: $color-black;
              }
            }
          }
        }
      }
    }
  }

  &__slider {
    width: 100% !important;
    height: 480px;
    margin-bottom: 13px;

    ::v-deep {
      .splide {
        &__track {
          height: 100%;
        }

        &__list {
          height: 100%;
        }

        &__slide {
          height: 100% !important;
          width: 100%;
          background-size: cover !important;

          .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__arrow {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &:disabled {
            opacity: 0;
          }

          &--prev {
            &:before {
              font-family: "icomoon" !important;
              content: "\e902";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          &--next {
            &:before {
              font-family: "icomoon" !important;
              content: "\e90c";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          svg {
            display: none;
          }
        }

        &__pagination {
          padding-left: 0;
          bottom: -27px;

          &__page {
            width: 4px;
            height: 4px;
            background: $color-grey3;
            transform: scale(1);
            padding: 2px !important;
            transition: all 0.3s ease;

            &.is-active {
              background: $color-black;
            }
          }
        }
      }
    }

    .content-container {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-file {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
    }

    .inst-video {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__icons {
    display: flex;
    margin: 10px 16px 10px 16px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
    justify-content: space-between;
    align-items: center;

    .item {
      display: inline-flex;
      align-items: center;

      &__icon {
        margin-right: 5px;
        font-size: 16px;
        display: inline-flex;
      }

      &__name {
        font-size: 14px;
      }
    }
  }
}

.edit-comment-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .edit-icon-container {
    flex-shrink: 0;
    margin-bottom: 10px;

    .icons-row {
      padding-bottom: 5px;

      .info-icons {
        display: flex;
        justify-content: flex-end;
      }
    }

    .purple {
      width: 110px !important;
      height: 25px !important;
      border: 1px solid $color-smb-black;
      background: $color-white;
      color: $color-smb-black;
      border-radius: 15px;
      font-weight: 600;
      font-size: 9px;
      overflow: hidden;
      box-shadow: none;
      transition: all 0.3s ease;
      cursor: pointer;

      &:before {
        display: none;
      }

      &:hover {
        box-shadow: 0 0 7px 1px rgb(0 0 0 / 30%);
      }
    }

    &.empty-tags {
      margin-left: auto;
    }

    .icons {
      border: 1px solid $color-black;
      border-radius: 20px;
      font-size: 15px;

      &.icon-history {
        width: 22px;
        height: 22px;
      }
    }
  }

  .change-caption-block {
    padding-top: 5px;
  }
}

.edit-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $color-white;
  border-radius: 10px;
  padding: 5px;

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;

    .v-btn {
      border-radius: 18px !important;
    }

    .v-btn--disabled {
      border: unset !important;
    }

    .v-btn--is-elevated {
      box-shadow: unset !important;
    }

    .green {
      color: $color-green;
      margin-left: 10px;
      background: $color-white;
      border: 1px solid $color-green;
    }

    .red {
      color: $color-red;
      background: $color-white;
      border: 1px solid $color-red;
    }
  }

  ::v-deep {
    .v-text-field--outlined fieldset {
      border-color: #017dbb !important;
      border-radius: 10px !important;
    }

    .emoji .emoji-mart {
      max-width: 260px;
    }

    .emoji.chat .emoji__picker {
      right: -21px !important;
    }
  }

  &__textarea-container {
    position: relative;
    border: 1px solid #017dbb;
    border-radius: inherit;

    .emoji {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 2;
    }

    .customer-textarea {
      ::v-deep {
        .v-input__slot {
          box-shadow: unset !important;
          background: unset;
        }
      }
    }
  }
}

.txt-color {
  color: $color-white;
}

.backup-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;

  &__button {
    width: 75px;
    height: 15px !important;
    background-color: $color-violet !important;
    background: $color-violet !important;
    color: $color-white !important;
    font-size: 8px !important;
  }
}

.menu-content {
  max-width: 350px !important;

  pre {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #515456;
    white-space: pre-wrap;
    font-family: $base-font;
  }

  .tags-list {
    margin-top: 30px !important;
    flex-wrap: wrap;
    margin-bottom: 15px;
    max-width: 200px;

    &__item {
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $link-color;
      margin-right: 5px;
      max-width: 175px;
      cursor: pointer;

      &:hover {
        color: $link-color-hover;
      }
    }
  }
}

.action-date {
  font-size: 12px;
  text-align: center;
  display: flex;

  .date-title {
    padding-right: 10px;
    color: red;
  }
}

.smb-dashboard {
  ::v-deep {
    .tags-list {
      max-width: initial;

      &__item {
        max-width: initial;
      }
    }
  }

  &.post {
    ::v-deep {
      .post {
        &__header {
          .user {
            &__name {
              font-size: 13px;
            }
          }
        }

        &__text-container {
          .text {
            pre {
              font-size: 13px;
            }
          }

          .tags-list {
            &__item {
              font-size: 13px;
            }
          }
        }

        &__body {
          position: relative;
          &:hover {
            .post__slide-menu-container {
              transition: all 0.3s ease-in;
              overflow: initial;
            }
            .post__slide-menu {
              transform: translateY(0);
              transition: all 0.3s ease-in;
            }
          }
        }

        &__slide-menu {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 5px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 4px;
          background: #fff;
          border-top: 1px solid rgba(47, 72, 88, 0.2);
          transform: translateY(80px);
          transition: all 0.3s ease-in;
        }

        &__slide-menu-container {
          position: sticky;
          left: 0;
          right: 0;
          bottom: 0;
          height: 0;
          overflow: hidden;
          display: block;
          transition: all 0.3s ease-in;

          .slide-nav-button {
            height: 55px;
            border-radius: 5px;
            border: 1px solid #1d1d1b;
            text-transform: none;
            letter-spacing: 0;
            background: #fff;
            padding: 2px !important;
            min-width: auto;
            text-indent: initial;
            box-shadow: none;

            .v-btn__content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            &__icon {
              height: 25px;
              width: 25px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            &__title {
              font-size: 9px;
              font-style: normal;
              font-weight: 800;
              line-height: 150%;
              color: #1d1d1b;
              white-space: nowrap;
              text-align: center;
              @media screen and (max-width: 450px) {
                font-size: 12px;
              }
            }

            &:hover {
              background: #92c4ff;
              .slide-nav-button {
                &__icon {
                  svg.tone-icon path:first-child {
                    fill: #ffffff !important;
                  }
                  svg.expand-icon path {
                    stroke: #ffffff !important;
                  }
                  svg.shorten-icon path {
                    stroke: #ffffff !important;
                  }
                  svg.options-icon path:nth-child(1),
                  svg.options-icon path:nth-child(2),
                  svg.options-icon path:nth-child(3) {
                    fill: #ffffff !important;
                  }
                }
              }
            }

            &.hover {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
              cursor: pointer;
              .tone-list {
                position: absolute;
                transform: scale(0);
                transition: all 0.3s ease;
                display: inline-flex;
                flex-direction: column;
                bottom: 100%;
                left: 0;
                .tone-item {
                  display: inline-flex;
                  align-items: center;
                  border-radius: 5px;
                  border: 1px solid #1d1d1b;
                  background: #92c4ff;
                  white-space: nowrap;
                  margin-bottom: 3px;
                  padding: 1px 5px;
                  cursor: pointer;
                  &:hover {
                    background: darken(#92c4ff, 10);
                  }
                  &__icon {
                    display: inline-flex;
                    align-items: center;
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    svg {
                      width: 100%;
                    }
                    svg.tone-icon path:first-child {
                      fill: #ffffff !important;
                    }
                  }
                  &__text {
                    color: #1d1d1b;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                  }
                }
              }
              &:hover {
                .tone-list {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
    }
  }
}

.smb-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(47, 72, 88, 0.2);
  padding: 7px;
  width: 100%;
  height: 71px;

  &__text {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1d1d1b;
  }

  &__date {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1d1d1b;
  }
}

.transparent-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: transparent;
}

.skeleton-loader {
  background: linear-gradient(100deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.captions {
  min-height: 200px;
}

.caption-line {
  height: 10px;
  margin-bottom: 10px;
  width: 100%; /* Adjust as needed */
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
