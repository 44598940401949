<template>
  <div class="instagram-post">
    <!-- User Info -->
    <div class="user-info">
      <div class="avatar skeleton-loader"></div>
      <div class="username skeleton-loader"></div>
    </div>

    <!-- Post Image -->
    <div class="post-image skeleton-loader"></div>

    <!-- Action Icons -->
    <div class="action-icons">
      <div class="icon skeleton-loader"></div>
      <div class="icon skeleton-loader"></div>
      <div class="icon skeleton-loader"></div>
    </div>

    <!-- Captions -->
    <div class="captions">
      <template v-if="!caption">
        <div class="caption-line skeleton-loader"></div>
        <div class="caption-line skeleton-loader"></div>
        <div class="caption-line skeleton-loader"></div>
      </template>
      <div class="text-container" v-else>
        <div class="text">
          <pre><strong>{{nickname }}</strong> {{ caption }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostSkeleton",
  props: {
    caption: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.instagram-post {
  width: 100%;
  max-width: 500px;
  overflow-x: auto;
  height: 100%;
  max-height: 650px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 15px; /* Add roundness */
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
}

.skeleton-loader {
  background: linear-gradient(100deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.username {
  width: 60%;
  height: 10px;
}

.post-image {
  width: 100%;
  height: 232px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.action-icons {
  display: flex;
  gap: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

.captions {
  margin-top: 20px;
}

.caption-line {
  height: 10px;
  margin-bottom: 10px;
  width: 100%; /* Adjust as needed */
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.text-container {
  width: 100%;
  padding: 0 10px;
  height: 50px;
  .text {
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #515456;
    margin-bottom: 8px;
    pre {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #515456;
      white-space: pre-wrap;
      font-family: $base-font;
    }
  }
}
</style>
