<template>
  <div class="modal-logo">
    <div class="modal-logo__description">
      Drag the logo to where you would like to add it
    </div>
    <div
      class="move-area"
      :class="{ story: this.post.type !== 'post' }"
      v-if="currentTemplatePreview && logoList.length"
    >
      <div class="move-area__initial-img">
        <img :src="currentTemplatePreview" />
        <div class="draggable-area" v-if="parentShow" ref="draggableAreaRef">
          <vue-draggable-resizable
            v-for="(logo, index) in logoList"
            :key="index"
            :resizable="false"
            :w="logoSize(logo).width"
            :h="logoSize(logo).height"
            :lock-aspect-ratio="true"
            :parent="true"
            @dragstop="(x, y) => onDrag(x, y, logo)"
          >
            <div class="logo-container" v-if="logo.logoUrl">
              <img :src="logo.logoUrl" />
            </div>
          </vue-draggable-resizable>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import Loader from "@/components/global/Loader.vue";
import { mapGetters } from "vuex";
import Post from "@/models/Post";
export default {
  name: "SmbSetLogoPosition",
  props: {
    logoList: {
      type: Array,
      default: () => [],
    },
    currentTemplatePreview: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    logotype: {
      type: String,
      default: "",
    },
    post: {
      type: Object,
      default: new Post(),
    },
  },
  data: () => ({
    parentShow: false,
    isDropActive: true,
    isLoading: false,
  }),
  components: { Loader },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  mounted() {
    this.setParentContainerSize();
  },
  methods: {
    logoSize(logo) {
      return {
        width:
          logo.logoName === "brokerage_image_url"
            ? 300 / (this.post.type === "post" ? 2.74 : 4.695)
            : 200 / (this.post.type === "post" ? 2.74 : 4.695),
        height:
          logo.logoName === "brokerage_image_url"
            ? 150 / (this.post.type === "post" ? 2.74 : 4.695)
            : 200 / (this.post.type === "post" ? 2.74 : 4.695),
      };
    },
    setParentContainerSize() {
      this.isLoading = true;
      setTimeout(() => {
        this.parentShow = true;
        this.isLoading = false;
      }, 1000);
    },
    onDrag(x, y, item) {
      this.$emit("newCoordinates", {
        logoName: item.logoName,
        logoUrl: item.logoUrl,
        x: this.post.type === "post" ? x * 2.74 : x * 4.695,
        y: this.post.type === "post" ? y * 2.74 : y * 4.695,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.modal-logo {
  display: flex;
  flex-direction: column;
  text-align: left;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: #000;
    margin-bottom: 8px;
  }
  &__description {
    width: 100%;
    max-width: 534px;
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  &__upload-wrapper {
    width: 100%;
    display: block;
    color: #3771c8;
  }
  &__upload-btn {
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    .uploader {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 22px;
      z-index: 10;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .move-area {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    &.story {
      max-width: 230px;
    }
    &__initial-img {
      display: inline-flex;
      position: relative;
      img {
        width: 100%;
      }
      .draggable-area {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn {
      width: 100%;
      height: 49px;
      background: #92c4ff;
      border: 1px solid #000000;
      border-radius: 16px;
      color: #1d1d1b;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      text-transform: none;
      letter-spacing: inherit;
      box-shadow: none;
      max-width: 215px;
    }
  }
  .logo-container {
    width: 100%;
    height: 100%;
    display: inline-flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
</style>
