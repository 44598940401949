import { render, staticRenderFns } from "./SmbRelatedMedia.vue?vue&type=template&id=30df3ebb&scoped=true&"
import script from "./SmbRelatedMedia.vue?vue&type=script&lang=js&"
export * from "./SmbRelatedMedia.vue?vue&type=script&lang=js&"
import style0 from "./SmbRelatedMedia.vue?vue&type=style&index=0&id=30df3ebb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30df3ebb",
  null
  
)

export default component.exports