<template>
  <div class="templates">
    <div class="templates__header">
      Choose a template
      <span class="templates__header-hint"
        >the data inside will change after you choose and click Select</span
      >
    </div>
    <div class="templates__body">
      <div class="templates__list" v-if="templatesList">
        <div
          class="templates__list-item"
          v-for="template in templatesList"
          :key="template.id"
          @click="selectTemplate(template)"
          @mouseover="hoveredVideo = template.id"
          @mouseleave="hoveredVideo = null"
          :class="{ active: selectedTemplate.id === template.id }"
        >
          <template v-if="!H.isVideo(template.preview_url)">
            <img :src="template.preview_url" alt="" />
          </template>
          <div class="video-container" v-else>
            <video
              v-if="hoveredVideo === template.id"
              :src="template.preview_url"
              autoplay
            ></video>
            <img v-else :src="template.snapshot_url" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="templates__footer">
      <v-btn class="cancel" @click="$emit('close')">Cancel</v-btn>
      <v-btn
        class="select"
        @click="$emit('newTemplate', selectedTemplate)"
        :disabled="
          !selectedTemplate.id || selectedTemplate.id === currentTemplate.id
        "
        >Select</v-btn
      >
    </div>
  </div>
</template>

<script>
import Template from "@/models/Template";
import H from "@/utils/helper";
export default {
  name: "SmbTemplatesList",
  props: {
    currentTemplate: {
      type: Object,
      default: () => {},
    },
    templatesList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedTemplate: new Template(),
    hoveredVideo: "fsdfdsfds",
  }),
  computed: {
    H() {
      return H;
    },
  },
  created() {
    this.selectedTemplate = this.currentTemplate;
  },
  methods: {
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
  },
};
</script>

<style scoped lang="scss">
.templates {
  display: flex;
  flex-direction: column;
  &__header {
    text-align: left;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    color: #000;
    margin-bottom: 15px;
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      font-size: 20px;
    }
    &-hint {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      margin-left: 5px;
      @media screen and (max-width: 600px) {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 15px 20px;
    max-height: 450px;
    overflow-y: auto;
    &-item {
      display: inline-flex;
      width: 100%;
      aspect-ratio: 1 / 1;
      cursor: pointer;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: transparent;
        transition: all 0.3s ease;
      }
      &.active {
        &:before {
          border: 4px solid #fb0707;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .video-container {
        width: 100%;
        aspect-ratio: 1 / 1;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    .cancel {
      font-size: 22px !important;
      font-style: normal;
      font-weight: 500;
      color: #1d1d1b;
      height: 49px;
      min-width: 123px;
      margin-right: 15px;
      border-radius: 16px;
      box-shadow: none;
      letter-spacing: inherit;
      text-transform: none;
      border: 1px solid #1d1d1b;
      @media screen and (max-width: 600px) {
        height: 42px;
        min-width: 130px;
        margin-right: 7px;
      }
    }
    .select {
      font-size: 22px !important;
      font-style: normal;
      font-weight: 500;
      color: #1d1d1b;
      height: 49px;
      min-width: 157px;
      border-radius: 16px;
      box-shadow: none;
      letter-spacing: inherit;
      text-transform: none;
      border: 1px solid #1d1d1b;
      background: #92c4ff;
      @media screen and (max-width: 600px) {
        height: 42px;
        min-width: 130px;
        margin-left: 7px;
      }
    }
  }
}
</style>
