<template>
  <div class="preview-area__list">
    <div
      class="preview-area__list-item"
      v-for="(postPreview, index) in previewList"
      :key="index"
    >
      <div
        v-if="postPreview.mediaUrl"
        class="img-container"
        :class="{
          'without_scheduling ':
            postPreview.isFromApprovedTab || postPreview.isMarkInRed,
        }"
      >
        <div class="img-container__icon" v-if="postPreview.isFromInstagram">
          <i class="icon-icons8-instagram"></i>
        </div>
        <div class="img-container__icon" v-if="postPreview.isSchedulingPost">
          <i class="icon-calendar"></i>
        </div>
        <div
          class="img-container__icon video"
          v-if="!isImage(postPreview.mediaUrl)"
        >
          <i class="icon-play"></i>
        </div>
        <img v-if="isImage(postPreview.mediaUrl)" :src="postPreview.mediaUrl" />
        <video v-else :src="postPreview.mediaUrl"></video>
      </div>
      <div v-else class="img-container">
        <div class="img-container__icon" v-if="postPreview.isFromInstagram">
          <i class="icon-icons8-instagram"></i>
        </div>
        <div class="img-container__icon" v-if="postPreview.isSchedulingPost">
          <i class="icon-calendar"></i>
        </div>
        <img src="@/assets/img/default-inst-img.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
export default {
  name: "InstagramViewGrid",
  mixins: [helper],
  props: {
    previewList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.preview-area {
  &__list {
    width: 100%;
    max-width: 400px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-content: start;
    &-item {
      width: 100%;
      height: 120px;
      .img-container {
        height: 120px;
        width: 100%;
        position: relative;
        video,
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &__icon {
          display: inline-flex;
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.3);
          i {
            color: #fff;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            font-size: 13px;
          }
        }
        .video {
          top: 10px;
          bottom: 0px;
        }
      }
    }
  }
}
.without_scheduling {
  border: 1px solid red;
}
</style>
