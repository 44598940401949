<template>
  <div class="post-wrapper">
    <div
      class="post-header"
      v-if="postsSorted.length && dividerIsShow"
      :class="{ 'client-divider': isHeaderClient }"
    >
      Posts
      <div v-if="isCanSelectAllPosts" class="custom-checkbox ml-3">
        <input
          :disabled="!postsSorted.length"
          id="selectedPost"
          :checked="isSelectedAllPosts"
          class="styled-checkbox"
          :value="!isSelectedAllPosts"
          type="checkbox"
          @change="$emit('selectOrUnselectAllPosts', !isSelectedAllPosts)"
        />
        <label for="selectedPost"></label>
      </div>
    </div>
    <div class="main-container">
      <div
        :class="[
          'post-list',
          postListType,
          { 'is-previous-feedback': isPreviousFeedback },
        ]"
      >
        <div
          class="post-list__item"
          :class="postListType"
          v-for="(post, index) in postsSorted"
          :key="post.id"
        >
          <PostForm
            v-if="post.type === 'post'"
            :index="index"
            :lastIndex="postsSorted.length"
            :post="post"
            :postType="postType"
            :currentCustomer="currentCustomer"
            :isSmall="isSmall"
            :isShowSwitch="isShowSwitch"
            :isShowEditIcon="isShowEditIcon"
            :isShowComment="isShowComment"
            :isShowMakeChangesButton="isShowMakeChangesButton"
            :isShowCloseIcon="isShowCloseIcon"
            :isShowRating="isShowRating"
            :isCanAddComment="isCanAddComment"
            :isShowSubmitBtn="isShowSubmitBtn"
            :isShowTopActions="isShowTopActions"
            :isShowDeletedIcon="isShowDeletedIcon"
            :isShowDateCreation="isShowDateCreation"
            :headerTitle="headerTitle"
            :focusToElementId="focusToElementId"
            :isCanEditPublishDate="isCanEditPublishDate"
            :publishDateFormat="publishDateFormat"
            :isShowPublishTime="isShowPublishTime"
            :isShowAllowMovePostIcon="isShowAllowMovePostIcon"
            :checkedList="checkedList"
            :isCanSwap="isCanSwap"
            :isHeaderClient="isHeaderClient"
            :isFacebookPlatform="isFacebookPlatform"
            :isShowEditIconForClient="isShowEditIconForClient"
            :isCanShowEditedLabel="isCanShowEditedLabel"
            :isCanShowBackupData="isCanShowBackupData"
            :isCanAddACoupon="isCanAddACoupon"
            :isCanShowFailedLabel="isCanShowFailedLabel"
            :isPreviousFeedback="
              isPreviousFeedback ||
              (postType === 'smb-dashboard' && !!post.customerRating)
            "
            :isShowDeleteSchedulingIcon="isShowDeleteSchedulingIcon"
            :isShowActionDate="isShowActionDate"
            :isShowActionDateInFooter="isShowActionDateInFooter"
            :actionDateTitle="actionDateTitle"
            :isShowIndication="isShowIndication"
            :isShowSmbAction="isShowSmbAction"
            :ratingSize="ratingSize"
            :isPosted="isPosted"
            :isScheduledContent="isScheduledContent"
            :isSmbPendingPage="isSmbPendingPage"
            :isContentCalendarPage="isContentCalendarPage"
            :isCanDeleteContent="isCanDeleteContent"
            :isCanSaveForLater="isCanSaveForLater"
            @swapWithPrevious="swapPublishDates($event)"
            @approveAction="$emit('approveAction', $event)"
            @submitAction="$emit('submitAction', $event)"
            @updateRush="$emit('updateRush', $event)"
            @postDeletedSuccessfully="$emit('postDeletedSuccessfully')"
            @updateAmountPost="$emit('updateAmountPost')"
            @updateRejectedList="$emit('updateRejectedList', $event)"
            @deleteFromRejectedContent="
              $emit('deleteFromRejectedContent', $event)
            "
            @focusOnInput="$emit('focusOnInput', post)"
            @blurFromInput="$emit('blurFromInput')"
            @updatePostsList="$emit('updatePostsList')"
            @updateSchedule="$emit('updateSchedule', $event)"
            @updatedCoupon="$emit('updatedCoupon', $event)"
            @changeDataInPost="$emit('changeDataInPost', $event)"
            @deleteSchedulingHandler="$emit('deleteSchedulingHandler', $event)"
            @updateCaptionValues="$emit('updateCaptionValues', $event)"
            @rejectSmbPost="$emit('rejectSmbPost', $event)"
            @saveSmbPost="$emit('saveSmbPost', $event)"
            @publishSmbPostNow="$emit('publishSmbPostNow', $event)"
            @scheduleSmbPost="$emit('scheduleSmbPost', $event)"
            @cancelScheduling="$emit('cancelScheduling', $event)"
            @howToEditLiveFbPost="$emit('howToEditLiveFbPost')"
            @howToEditLiveIgPost="$emit('howToEditLiveIgPost')"
            @editTemplateImage="$emit('editTemplateImage', $event)"
            @saveForLater="$emit('saveForLater', $event)"
            @showedEditTemplateDialog="$emit('showedEditTemplateDialog')"
            @editCaption="$emit('editCaption')"
            @approvedToAnimatePost="$emit('approvedToAnimatePost', $event)"
            @revertAnimatedPost="$emit('revertAnimatedPost', $event)"
            @changeSocialAccounts="$emit('changeSocialAccounts', $event)"
          />
          <div
            class="pagination-button"
            :class="{ disabled: post.isDisabled }"
            v-if="post.type === 'paginationButton'"
            @click="
              !post.isLoading && !post.isDisabled
                ? $emit('showMore', post)
                : null
            "
          >
            <v-progress-circular
              v-if="post.isLoading"
              :size="50"
              color="#fffff"
              indeterminate
            ></v-progress-circular>
            <template v-else>
              {{ post.text }}
            </template>
          </div>
        </div>
      </div>
      <InstagramViewGrid
        v-if="isShowPreview && previewList.length"
        :preview-list="previewList"
      />
    </div>
    <div class="main-container-stories">
      <div
        class="post-header"
        v-if="storiesSorted.length && isShowStoryTitle"
        :class="{ 'client-divider': isHeaderClient }"
      >
        Stories
        <div v-if="isCanSelectAllStories" class="custom-checkbox ml-3">
          <input
            :disabled="!storiesSorted.length"
            id="selectedStories"
            :checked="isSelectedAllStories"
            class="styled-checkbox"
            :value="!isSelectedAllStories"
            type="checkbox"
            @change="$emit('selectOrUnselectAllStories', !isSelectedAllStories)"
          />
          <label for="selectedStories"></label>
        </div>
      </div>
      <div
        :class="[
          'post-list',
          postListType,
          { 'is-previous-feedback': isPreviousFeedback },
        ]"
        v-if="storiesSorted.length"
      >
        <div
          class="post-list__item"
          :class="postListType"
          v-for="post in storiesSorted"
          :key="post.id"
        >
          <PostForm
            v-if="post.type === 'story'"
            :post="post"
            :postType="postType"
            :isShowSwitch="isShowSwitch"
            :isSmall="isSmall"
            :currentCustomer="currentCustomer"
            :isShowEditIcon="isShowEditIcon"
            :isShowComment="isShowComment"
            :isShowMakeChangesButton="isShowMakeChangesButton"
            :isShowCloseIcon="isShowCloseIcon"
            :isShowRating="isShowRating"
            :isCanAddComment="isCanAddComment"
            :isShowSubmitBtn="isShowSubmitBtn"
            :isShowTopActions="isShowTopActions"
            :isShowDeletedIcon="isShowDeletedIcon"
            :isShowDateCreation="isShowDateCreation"
            :headerTitle="headerTitle"
            :focusToElementId="focusToElementId"
            :isCanEditPublishDate="isCanEditPublishDate"
            :publishDateFormat="publishDateFormat"
            :isShowPublishTime="isShowPublishTime"
            :isShowAllowMovePostIcon="isShowAllowMovePostIcon"
            :checkedList="checkedList"
            :isCanSwap="isCanSwap"
            :isShowEditIconForClient="isShowEditIconForClient"
            :isCanShowEditedLabel="isCanShowEditedLabel"
            :isFacebookPlatform="isFacebookPlatform"
            :isHeaderClient="isHeaderClient"
            :isCanShowBackupData="isCanShowBackupData"
            :isCanAddACoupon="isCanAddACoupon"
            :isCanShowFailedLabel="isCanShowFailedLabel"
            :isPreviousFeedback="
              isPreviousFeedback ||
              (postType === 'smb-dashboard' && !!post.customerRating)
            "
            :isShowDeleteSchedulingIcon="isShowDeleteSchedulingIcon"
            :isShowActionDate="isShowActionDate"
            :isShowActionDateInFooter="isShowActionDateInFooter"
            :actionDateTitle="actionDateTitle"
            :isShowIndication="isShowIndication"
            :isShowSmbAction="isShowSmbAction"
            :ratingSize="ratingSize"
            :isPosted="isPosted"
            :isScheduledContent="isScheduledContent"
            :isSmbPendingPage="isSmbPendingPage"
            :isContentCalendarPage="isContentCalendarPage"
            :isCanDeleteContent="isCanDeleteContent"
            :isCanSaveForLater="isCanSaveForLater"
            @approveAction="$emit('approveAction', $event)"
            @submitAction="$emit('submitAction', $event)"
            @postDeletedSuccessfully="$emit('postDeletedSuccessfully')"
            @updateAmountPost="$emit('updateAmountPost')"
            @updateRejectedList="$emit('updateRejectedList', $event)"
            @approvedToAnimatePost="$emit('approvedToAnimatePost', $event)"
            @deleteFromRejectedContent="
              $emit('deleteFromRejectedContent', $event)
            "
            @focusOnInput="$emit('focusOnInput', post)"
            @blurFromInput="$emit('blurFromInput')"
            @updatePostsList="$emit('updatePostsList')"
            @updateSchedule="$emit('updateSchedule', $event)"
            @updatedCoupon="$emit('updatedCoupon', $event)"
            @changeDataInStory="$emit('changeDataInStory', $event)"
            @deleteSchedulingHandler="$emit('deleteSchedulingHandler', $event)"
            @updateCaptionValues="$emit('updateCaptionValues', $event)"
            @rejectSmbPost="$emit('rejectSmbPost', $event)"
            @saveSmbPost="$emit('saveSmbPost', $event)"
            @publishSmbPostNow="$emit('publishSmbPostNow', $event)"
            @scheduleSmbPost="$emit('scheduleSmbPost', $event)"
            @cancelScheduling="$emit('cancelScheduling', $event)"
            @howToEditLiveFbPost="$emit('howToEditLiveFbPost')"
            @howToEditLiveIgPost="$emit('howToEditLiveIgPost')"
            @editTemplateImage="$emit('editTemplateImage', $event)"
            @saveForLater="$emit('saveForLater', $event)"
            @showedEditTemplateDialog="$emit('showedEditTemplateDialog')"
            @editCaption="$emit('editCaption')"
            @revertAnimatedPost="$emit('revertAnimatedPost', $event)"
          />
          <div
            class="pagination-button"
            v-if="post.type === 'paginationButton'"
            :class="{ disabled: post.isDisabled }"
            @click="
              !post.isLoading && !post.isDisabled
                ? $emit('showMore', post)
                : null
            "
          >
            <v-progress-circular
              v-if="post.isLoading"
              :size="50"
              color="#fffff"
              indeterminate
            ></v-progress-circular>
            <template v-else>
              {{ post.text }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostForm from "@/components/createPost/PostForm";
import helper from "@/mixins/helper";
import InstagramViewGrid from "@/components/global/InstagramViewGrid.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PostList",
  data: () => ({
    instagramFiles: [],
    scheduledPosts: [],
  }),
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    stories: {
      type: Array,
      default: () => [],
    },
    switchLabel: {
      type: String,
      default: "Approve Post",
    },
    postType: {
      type: String,
      default: "",
    },
    postListType: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isFacebookPlatform: {
      type: Boolean,
      default: false,
    },
    isShowSwitch: {
      type: Boolean,
      default: true,
    },
    isShowComment: {
      type: Boolean,
      default: false,
    },
    isShowCloseIcon: {
      type: Boolean,
      default: false,
    },
    isShowRating: {
      type: Boolean,
      default: false,
    },
    isCanAddComment: {
      type: Boolean,
      default: false,
    },
    isShowSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isPreviousFeedback: {
      type: Boolean,
      default: false,
    },
    isShowEditIcon: {
      type: Boolean,
      default: false,
    },
    isShowMakeChangesButton: {
      type: Boolean,
      default: false,
    },
    isShowTopActions: {
      type: Boolean,
      default: false,
    },
    isShowDateCreation: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: "",
    },
    isShowDeletedIcon: {
      type: Boolean,
      default: false,
    },
    isCanEditPublishDate: {
      type: Boolean,
      default: false,
    },
    focusToElementId: {
      type: String,
    },
    publishDateFormat: {
      type: String,
      default: "MM/DD/YYYY hh:mm A",
    },
    isShowPublishTime: {
      type: Boolean,
      default: false,
    },
    isShowAllowMovePostIcon: {
      type: Boolean,
      default: false,
    },
    isShowPreview: {
      type: Boolean,
      default: false,
    },
    dividerIsShow: {
      type: Boolean,
      default: true,
    },
    checkedList: {
      type: Array,
      default: () => [],
    },
    isCanSwap: {
      type: Boolean,
      default: false,
    },
    isSortedByType: {
      type: Boolean,
      default: false,
    },
    isSortedByScheduledForStr: {
      type: Boolean,
      default: false,
    },
    isSortedByNewestScheduledForStr: {
      type: Boolean,
      default: false,
    },
    isCanSelectAllPosts: {
      type: Boolean,
      default: false,
    },
    isCanSelectAllStories: {
      type: Boolean,
      default: false,
    },
    isHeaderClient: {
      type: Boolean,
      default: false,
    },
    isShowEditIconForClient: {
      type: Boolean,
      default: false,
    },
    isCanShowEditedLabel: {
      type: Boolean,
      default: false,
    },
    isCanShowBackupData: {
      type: Boolean,
      default: false,
    },
    isCanAddACoupon: {
      type: Boolean,
      default: false,
    },
    isCanShowFailedLabel: {
      type: Boolean,
      default: false,
    },
    isShowDeleteSchedulingIcon: {
      type: Boolean,
      default: false,
    },
    isShowActionDate: {
      type: Boolean,
      default: false,
    },
    actionDateTitle: {
      type: String,
      default: "",
    },
    isSortedByUpdatedDate: {
      type: Boolean,
      default: false,
    },
    isShowActionDateInFooter: {
      type: Boolean,
      default: false,
    },
    isShowIndication: {
      type: Boolean,
      default: false,
    },
    currentCustomer: {
      type: Object,
      default: () => {},
    },
    isShowSmbAction: {
      type: Boolean,
      default: false,
    },
    ratingSize: {
      type: [String, Number],
      default: 35,
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    isScheduledContent: {
      type: Boolean,
      default: false,
    },
    isSmbPendingPage: {
      type: Boolean,
      default: false,
    },
    isContentCalendarPage: {
      type: Boolean,
      default: false,
    },
    isShowStoryTitle: {
      type: Boolean,
      default: false,
    },
    isCanDeleteContent: {
      type: Boolean,
      default: false,
    },
    isCanSaveForLater: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PostForm,
    InstagramViewGrid,
  },
  async created() {
    if (this.isShowPreview) {
      await this.getMedia();
      this.scheduledPosts = await this.fetchScheduledPosts({
        customerId: this.currentCustomer.id,
      });
    }
  },
  mixins: [helper],
  computed: {
    ...mapGetters("post", ["getSelectedPostsList"]),
    postsSorted() {
      let tmpPosts = this.posts;
      if (this.isSortedByType) {
        return [
          ...tmpPosts.sort((a, b) => {
            return a.type < b.type ? -1 : 1;
          }),
        ];
      } else if (this.isSortedByScheduledForStr) {
        return [
          ...tmpPosts
            .filter((i) => (i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.scheduledForStr < b.scheduledForStr ? -1 : 1;
            }),
          ...tmpPosts
            .filter((i) => (!i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt > b.updatedAt ? 1 : -1;
            }),
        ];
      } else if (this.isSortedByNewestScheduledForStr) {
        return [
          ...tmpPosts
            .filter((i) => (i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.scheduledForStr < b.scheduledForStr ? 1 : -1;
            }),
          ...tmpPosts
            .filter((i) => (!i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt > b.updatedAt ? -1 : 1;
            }),
        ];
      } else {
        return tmpPosts;
      }
    },
    storiesSorted() {
      let tmpStories = this.stories;
      if (this.isSortedByType) {
        return [
          ...tmpStories.sort((a, b) => {
            return a.type < b.type ? -1 : 1;
          }),
        ];
      } else if (this.isSortedByScheduledForStr) {
        return [
          ...tmpStories
            .filter((i) => (i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.scheduledForStr < b.scheduledForStr ? -1 : 1;
            }),
          ...tmpStories
            .filter((i) => (!i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt > b.updatedAt ? 1 : -1;
            }),
        ];
      } else if (this.isSortedByNewestScheduledForStr) {
        return [
          ...tmpStories
            .filter((i) => (i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.scheduledForStr < b.scheduledForStr ? 1 : -1;
            }),
          ...tmpStories
            .filter((i) => (!i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt > b.updatedAt ? -1 : 1;
            }),
        ];
      } else if (this.isSortedByUpdatedDate) {
        return [
          ...tmpStories
            .filter((i) => (i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt < b.updatedAt ? 1 : -1;
            }),
          ...tmpStories
            .filter((i) => (!i.scheduledForStr ? i : null))
            .sort((a, b) => {
              return a.updatedAt > b.updatedAt ? -1 : 1;
            }),
        ];
      } else {
        return tmpStories;
      }
    },
    scheduledSortedPosts() {
      let tmpPosts = this.scheduledPosts.filter((i) => i.type === "post");
      return [
        ...tmpPosts
          .filter((i) => (i.scheduledForStr ? i : null))
          .sort((a, b) => {
            return a.scheduledForStr < b.scheduledForStr ? 1 : -1;
          }),
        ...tmpPosts
          .filter((i) => (!i.scheduledForStr ? i : null))
          .sort((a, b) => {
            return a.updatedAt > b.updatedAt ? 1 : -1;
          }),
      ];
    },
    previewList() {
      let minDateInFeedForApprovedPost = this.$dayjs().unix();
      let sortedApprovedAndScheduledPosts = [
        ...this.postsSorted
          .filter(
            (i) =>
              i.scheduledForStr &&
              this.$dayjs(
                this.parseStrDate(i.scheduledForStr).dateStr +
                  " " +
                  this.parseStrDate(i.scheduledForStr).timeStr
              ).unix() >= minDateInFeedForApprovedPost
          )
          .map((i) => {
            if (!i.media)
              return {
                mediaUrl: null,
                isFromApprovedTab: true,
                isFromInstagram: false,
                scheduledForStr: i.scheduledForStr,
              };
            const url = this.getMediaUrl(i.media[0]);
            return {
              mediaUrl: url,
              isFromApprovedTab: true,
              isFromInstagram: false,
              scheduledForStr: i.scheduledForStr,
            };
          })
          .reverse(),
        ...this.scheduledSortedPosts.map((i) => {
          if (!i.media)
            return {
              mediaUrl: null,
              isSchedulingPost: true,
              scheduledForStr: i.scheduledForStr,
            };
          const url = this.getMediaUrl(i.media[0]);
          return {
            mediaUrl: url,
            isSchedulingPost: true,
            scheduledForStr: i.scheduledForStr,
          };
        }),
      ].sort((a, b) => {
        return a.scheduledForStr < b.scheduledForStr ? 1 : -1;
      });

      return [
        ...sortedApprovedAndScheduledPosts,
        ...this.instagramFiles.slice(0, 9).map((i) => {
          const url = this.getMediaUrl(i);
          return { mediaUrl: url, isFromInstagram: true };
        }),
      ];
    },
    isSelectedAllPosts() {
      return this.postsSorted.every((post) => {
        return this.checkedList.includes(post.id);
      });
    },
    isSelectedAllStories() {
      return this.storiesSorted.every((story) => {
        return this.checkedList.includes(story.id);
      });
    },
  },
  methods: {
    ...mapActions("mediaLibraryWidget", [
      "fetchMediaData",
      "fetchScheduledPosts",
    ]),
    ...mapActions("createPost", ["updatePublishDate"]),
    getMediaUrl(post) {
      if (!post) return "";
      return post.editedMediaUrlReduced || post.editedMediaUrl || post.mediaUrl;
    },
    async getMedia() {
      const params = {
        customerId: this.currentCustomer.id,
        type: 3,
        socialPlatform: "instagram",
        sortBy: 1,
        limit: "30",
      };
      const { items } = await this.fetchMediaData(params);
      this.instagramFiles = items.filter(
        (m) => !m.externalCarousel || m.externalCarousel.includes("_0")
      );
    },
    async swapPublishDates(index) {
      const currntPostScheduledForStr = this.postsSorted[index].scheduledForStr;
      const currntPostId = this.postsSorted[index].id;

      const prevPostScheduledForStr =
        this.postsSorted[index - 1].scheduledForStr;
      const prevPostId = this.postsSorted[index - 1].id;

      try {
        await this.updatePublishDate({
          id: currntPostId,
          scheduledForStr: prevPostScheduledForStr,
        });
        await this.updatePublishDate({
          id: prevPostId,
          scheduledForStr: currntPostScheduledForStr,
        });

        this.$emit("postModified");
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.post-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 45px 5px;
  padding: 20px 10px;
  @media screen and (max-height: 850px) {
    padding: 10px 10px;
  }
  @media screen and (max-width: 500px) {
    padding: 15px 5px;
  }
  &.narrow {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 12px 5px;
  }
  &.short {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 12px 2px;
  }
  &.is-previous-feedback {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
.post-header {
  margin: 10px 25px 0 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #515456;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(78, 87, 120, 0.1);
  display: flex;
  align-items: center;
}

.client-divider {
  display: flex;
  justify-content: center;
  font-size: 50px;
  color: $color-smb-black;
}

::v-deep {
  .post-list {
    &.short {
      .top-row {
        min-height: inherit;
      }
      .widget-item {
        padding: 5px 0 !important;
      }
    }
  }
  .post-list {
    &.narrow {
      width: 100%;
      .post__img {
        height: 250px;
      }
      .post__slider {
        height: 250px;
      }
      .widget-item {
        height: auto;
        flex: 1;
        flex-direction: column;
        display: inline-flex;
        padding: 10px 0 4px 0 !important;
        .post-info {
          margin-bottom: 10px;
          padding: 0 10px;
        }
      }
      .post-list__item {
        display: flex;
        flex-direction: column;
        & > div {
          flex: 1;
        }
      }
      .widget-content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .post {
        max-height: 410px;
        &__header {
          padding: 9px 10px;
          .user {
            &__avatar {
              width: 24px;
              height: 24px;
            }
            &__name {
              font-size: 10px;
            }
            &__location {
              font-size: 10px;
            }
          }
          .more-info {
            width: 16px;
            i {
              font-size: 12px;
            }
          }
        }
        &__slider {
          .splide__pagination {
            bottom: -17px;
          }
        }
        &__content {
          display: flex;
          flex-direction: column;
        }
        &__controls {
          padding: 25px 10px 13px 10px;
          .cell .item {
            margin-right: 8px;
            i {
              font-size: 15px;
            }
          }
        }
        &__text-container {
          padding: 0 10px;
          display: inline-flex;
          flex-direction: column;
          .text pre {
            font-size: 12px;
          }
          .text {
            display: block;
          }
          .tags-list {
            &__item {
              font-size: 12px;
            }
          }
        }
      }
      .stories {
        max-height: 360px;
        flex: 1;
        height: 100%;
        .post__img.crop .splide__slide {
          display: inline-flex;
          align-items: center;
        }
        .post__slider {
          height: 420px;
          .splide__pagination {
            bottom: -17px;
            height: 5px;
          }
        }
        .post__body {
          flex: 1;
        }
        .post__content {
          flex: 1;
          height: 100%;
          background: #ccc;
        }
        .post__img {
          height: 360px;
          &.crop {
            height: auto !important;
            margin: auto;
            position: static;
            .splide {
              &--slide {
                position: static;
                flex: 1;
                display: flex;
              }
              &__list {
                width: 100%;
              }
              &__track {
                flex: 1;
                display: flex;
              }
              &__slide {
                width: 100% !important;
                background: none !important;
                height: auto !important;
                position: relative;
                img {
                  width: 100%;
                  display: block !important;
                  &.cover {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
                .video {
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  .video-wrapper .video-container {
                    object-fit: contain;
                  }
                  &.isStatic {
                    position: static;
                  }
                }
              }

              &__arrow {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &:disabled {
                  opacity: 0;
                }

                &--prev {
                  &:before {
                    font-family: "icomoon" !important;
                    content: "\e902";
                    position: absolute;
                    font-size: 24px;
                    color: $color-white;
                  }
                }

                &--next {
                  &:before {
                    font-family: "icomoon" !important;
                    content: "\e90c";
                    position: absolute;
                    font-size: 24px;
                    color: $color-white;
                  }
                }

                svg {
                  display: none;
                }
              }
            }
            .splide__pagination {
              bottom: inherit;
              top: -6px;
              z-index: 10;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
              grid-gap: 2px;
              padding-left: 0;
            }
            .splide__pagination__page {
              width: 100%;
              margin: 0;
              border-radius: 0 !important;
              transform: scale(1);
              height: 2px;
              padding: 0 !important;
              opacity: 0.6;
              background: $color-white;
              &.is-active {
                background: $color-white;
                opacity: 1;
              }
            }
          }
        }
      }
      .publish-rush__name {
        font-size: 10px;
      }
    }
  }
  .post-list__item.short {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .widget-item {
      height: auto;
      flex: 1;
    }
    .widget-content {
      display: flex;
      flex-direction: column;
      .post-info {
        padding: 0 5px;
        margin-bottom: 5px;
        &__for {
          .item {
            width: 8px;
            height: 8px;
            display: inline-flex;
          }
        }
        &__type {
          font-size: 8px;
        }
        .publish-date {
          position: relative;
          width: 100px;
          &__name {
            display: none;
          }
          &__val {
            position: absolute;
            left: 50px;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 8px;
            font-weight: bold;
            white-space: nowrap;
            min-width: 100px;
          }
        }
      }
    }
    .post {
      max-height: 286px;
      &__ontent {
        display: flex;
        flex-direction: column;
      }
      &__header {
        padding: 5px 5px;
        .user {
          &__avatar {
            width: 15px;
            height: 15px;
            display: inline-flex;
          }
          &__name {
            font-size: 8px;
          }
          &__location {
            font-size: 8px;
          }
        }
        .more-info {
          width: 8px;
          height: 8px;
          i {
            font-size: 8px;
          }
        }
      }
      &__img {
        height: 150px;
      }
      &__slider {
        height: 150px;
        .splide__arrow {
          width: 15px;
          height: 15px;
        }
        .splide__arrow--prev:before {
          font-size: 15px;
        }
        .splide__arrow--next:before {
          font-size: 15px;
        }
        .splide__pagination {
          bottom: -15px;
          li {
            line-height: inherit;
          }
        }
        .splide__pagination__page {
          margin: 2px;
          padding: 1px !important;
          width: 3px;
          height: 3px;
        }
      }
      &__controls {
        padding: 15px 5px 5px 5px;
        .cell {
          .item {
            margin-right: 7px;
            i {
              font-size: 8px;
            }
          }
        }
      }
      &__text-container {
        padding: 0 5px;
        display: inline-flex;
        flex-direction: column;
        .text {
          font-size: 8px;
          pre {
            font-size: 9px;
          }
        }
        .tags-list {
          &__item {
            font-size: 9px;
          }
        }
      }
      &__footer-info {
        padding: 5px;
        .top-row .title {
          font-size: 8px !important ;
        }
        .top-row .grade span {
          font-size: 8px;
          margin-right: 4px;
        }
        .rating {
          display: inline-flex;
          .v-rating {
            display: inline-flex;
            .mdi-star,
            .mdi-star-outline {
              &:before {
                font-family: "icomoon" !important;
                content: "\e907";
                font-size: 8px;
              }
            }
          }
        }
        .comment {
          font-size: 8px;
        }
      }
    }
    .stories {
      .post__slider,
      .post__img {
        height: 240px;
      }
      .post__content {
        min-height: 240px;
        height: auto;
      }
    }
  }
}
.main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  @media screen and (min-width: 740px) {
    padding-right: 20px;
  }
}
.styled-checkbox {
  margin-left: 10px;
}
.pagination-button {
  &.disabled {
    background-color: #bdbdbd;
  }
  margin: auto;
  flex: initial !important;
  min-height: 74px;
  background-color: #1d1d1b;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  max-width: 130px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
</style>
