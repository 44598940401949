<template>
  <div class="modify-template" :class="{ 'overflow-hidden': isLoading }">
    <div class="modify-template__title">
      {{ isMobile() && mobileStep === 2 ? "Preview" : "Edit media" }}
      <template v-if="templatesList">
        <span
          v-if="!isLoadingTemplateList"
          class="choose-template"
          @click="chooseNewTemplate.isShow = true"
          >Choose a different design</span
        >
        <v-progress-circular v-else indeterminate></v-progress-circular>
      </template>
    </div>
    <div class="modify-template__body" :class="{ blur: isLoading }">
      <div
        class="edit-area"
        :class="{ active: isMobile() && mobileStep === 1 }"
      >
        <div class="edit-area__wrapper">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="edit-area__controls-wrapper">
              <template v-for="(item, index) in inputFields">
                <div
                  class="edit-area__control"
                  :key="index"
                  v-if="item.hidden !== true"
                >
                  <div
                    v-if="item.textColor"
                    class="edit-area__control-color"
                    v-bind:style="{ backgroundColor: item.textColor }"
                  >
                    <div class="colors">
                      <div class="colors__pallet">
                        <div class="colors__pallet-items">
                          <div
                            class="item"
                            v-for="(color, index) in [
                              ...customerColors,
                              ...additionalColorsList,
                            ]"
                            :key="index"
                            v-bind:style="{ backgroundColor: color }"
                            @click="changeTextColor(color, item)"
                          ></div>
                        </div>
                        <div
                          class="colors__pallet-items plus"
                          @click="addNewColorsHandler(item)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="edit-area__input-wrapper">
                    <div class="edit-area__input-val">
                      <v-textarea
                        outlined
                        rows="1"
                        auto-grow
                        :value="item.value === ' ' ? '' : item.value"
                        :placeholder="item.layer"
                        hide-details="auto"
                        @input="updateTextValue($event, item)"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </v-form>
          <div
            class="edit-area__controls-wrapper"
            v-if="backgroundField.length"
          >
            <template v-for="(item, index) in backgroundField">
              <div
                class="edit-area__control color"
                :key="index + 'a'"
                v-if="item.hidden !== true"
              >
                <div
                  class="edit-area__control-color"
                  v-bind:style="{ backgroundColor: item.value }"
                >
                  <div class="colors">
                    <div class="colors__pallet">
                      <div class="colors__pallet-items">
                        <div
                          class="item"
                          v-for="(color, index) in [
                            ...customerColors,
                            ...additionalColorsList,
                          ]"
                          :key="index"
                          v-bind:style="{ backgroundColor: color }"
                          @click="changeBgColor(color, item)"
                        ></div>
                      </div>
                      <div
                        class="colors__pallet-items plus"
                        @click="addNewColorsHandler(item)"
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div class="edit-area__input-wrapper">
                  <div class="edit-area__input-val">
                    <span class="name">{{ item.layer }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="edit-area__b-row">
            <div class="edit-area__images">
              <template v-for="(item, index) in editImages">
                <div class="item" v-if="!item.hidden" :key="index">
                  <div
                    class="item__info"
                    v-if="!isDropActive && !item.isLoadingImage"
                  >
                    <div
                      class="item__img"
                      @dragover="dragHandler(true)"
                      :class="{
                        'transparent-img': isTransparentImage(item.value),
                      }"
                      :id="item.name"
                    >
                      <div
                        v-if="isTransparentImage(item.value) && !item.isHidden"
                        class="line-1"
                      ></div>
                      <div
                        v-if="isTransparentImage(item.value) && !item.isHidden"
                        class="line-2"
                      ></div>
                      <div class="item__icon" v-if="item.isHidden">
                        <Uploader
                          v-if="isShowUploadContainer(item)"
                          :componentRef="'template_uploader_logo_' + index"
                          className="uploader-container-logo"
                          fileFormat="png|jpeg|gif|svg|jpg"
                          :isShowDragAndDropText="false"
                          :isMultiple="false"
                          @uploadFile="startUploadLogoHandler($event, item)"
                          @notValidFormat="notValidFormatHandler"
                        />
                        <svg
                          @click="setLogoHandler(item)"
                          xmlns="http://www.w3.org/2000/svg"
                          width="70"
                          height="70"
                          viewBox="0 0 70 70"
                          fill="none"
                        >
                          <rect
                            opacity="0.8"
                            x="0.75"
                            y="0.75"
                            width="68.5"
                            height="68.5"
                            rx="3.25"
                            stroke="#1D1D1B"
                            stroke-width="1.5"
                            stroke-dasharray="5 5"
                          />
                          <path
                            d="M34.9999 18.5312V51.4724M18.5293 35.0018H51.4705"
                            stroke="#1D1D1B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <img v-if="item.value" :src="item.value" alt="" />
                      <div
                        class="assets-images"
                        v-if="
                          (item.name === 'logo_image_url' ||
                            item.name === 'headshot_image_url' ||
                            item.name === 'brokerage_image_url') &&
                          !item.isHidden
                        "
                      >
                        <div class="assets-images__list">
                          <div
                            class="assets-images__list-item"
                            v-for="(businessAsset, index) in assetsList"
                            :key="index"
                          >
                            <img
                              :src="businessAsset"
                              alt=""
                              @click="
                                (item.value = businessAsset),
                                  (isCanGenerateNewMedia = true),
                                  setNewLogoImage(businessAsset, item.name)
                              "
                            />
                          </div>
                          <div
                            v-if="!isLoadingUploader"
                            class="assets-images__list-item uploader-container"
                            @click="
                              emitClickOnUploaderByElementId(
                                'newBusinessUploader'
                              )
                            "
                          >
                            <v-icon class="uploader-container__icon"
                              >icon-upload-image</v-icon
                            >
                            <span class="uploader-container__text">Upload</span>
                          </div>
                          <v-progress-circular
                            v-else
                            indeterminate
                            color="black"
                          ></v-progress-circular>

                          <SmbFileUploader
                            class="asset-uploader"
                            elementId="newBusinessUploader"
                            isHideInput
                            custom-class=""
                            prependIcon="icon-upload-image"
                            acceptProp="image/png, image/jpeg, image/bmp"
                            @filesWasUpdated="
                              uploadNewBusinessFileHandler($event)
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="item__name">
                      {{ item.layer }}
                      <span class="item__name-hint">{{
                        item.name === "logo_image_url"
                          ? "Best for square logo"
                          : item.name === "brokerage_image_url"
                          ? "Best for landscape logo"
                          : ""
                      }}</span>
                    </div>
                    <div class="item__controls" v-if="!item.isHidden">
                      <button
                        class="file-uploader"
                        v-if="
                          item.name !== 'logo_image_url' &&
                          item.name !== 'headshot_image_url' &&
                          item.name !== 'brokerage_image_url'
                        "
                      >
                        <Uploader
                          :componentRef="'uploader_icon_' + index"
                          isUseCustomElementInSlot
                          :isDrop="false"
                          :isMultiple="false"
                          fileFormat="png|jpeg|gif|svg|jpg"
                          className="uploader-custom-icon"
                          @uploadFile="startUploadFileHandler($event, item)"
                          @notValidFormat="notValidFormatHandler"
                        >
                          <template v-slot:uploader-block>
                            <i class="icon-export"></i>
                          </template>
                        </Uploader>
                      </button>
                      <button
                        class="edit"
                        @click="changeImage(item.name, item)"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </button>
                      <template
                        v-if="
                          item.name === 'logo_image_url' ||
                          item.name === 'brokerage_image_url'
                        "
                      >
                        <button class="edit" @click="deleteImage(item)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </button>
                        <!--                        <button v-else class="edit" @click="undoImage(item)">-->
                        <!--                          <v-icon>mdi-undo-variant</v-icon>-->
                        <!--                        </button>-->
                      </template>
                    </div>
                  </div>
                  <div
                    v-else-if="isDropActive && !item.isLoadingImage"
                    class="item__img"
                    @dragleave="dragHandler(false)"
                    :id="item.name"
                  >
                    <Uploader
                      :componentRef="'template_uploader_' + index"
                      className="uploader-container"
                      fileFormat="png|jpeg|gif|svg|jpg"
                      :isShowDragAndDropText="false"
                      :isMultiple="false"
                      @uploadFile="startUploadFileHandler($event, item)"
                      @notValidFormat="notValidFormatHandler"
                    />
                  </div>
                  <div
                    v-else
                    class="item__img loader-template-img"
                    :id="item.name"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#000000"
                    ></v-progress-circular>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="edit-area__mobile-nav">
            <div class="edit-area__mobile-nav-item">
              <button
                class="edit-area__mobile-nav-item"
                :disabled="!isCanGenerateNewMedia"
                @click="generateTemplateMobile()"
              >
                Generate new media
              </button>
            </div>
            <div class="edit-area__mobile-nav-item">
              <button
                class="edit-area__mobile-nav-item"
                @click="$emit('close')"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="result-area"
        :class="{ active: isMobile() && mobileStep === 2 }"
      >
        <div class="result-area__image">
          <img
            v-if="
              currentTemplate.preview_url &&
              !addLogoDialog.isShow &&
              !H.isVideo(currentTemplate.preview_url)
            "
            :src="currentTemplate.preview_url"
          />
          <SmbSetLogoPosition
            v-else-if="addLogoDialog.isShow"
            :currentTemplatePreview="currentTemplatePreview"
            :logoList="logoList"
            :post="post"
            @newCoordinates="setNewCoordinates($event)"
          />
          <div class="video-wrapper" v-else>
            <v-progress-circular
              v-if="isLoadedVideo"
              indeterminate
            ></v-progress-circular>
            <video v-else controls :src="currentTemplate.preview_url"></video>
          </div>
        </div>
        <div class="result-area__btn">
          <div class="top-row">
            <v-btn
              class="generate-btn"
              :disabled="!isCanGenerateNewMedia"
              @click="generateTemplate()"
              >Generate new media</v-btn
            >
          </div>
          <div class="b-row">
            <v-btn
              class="reset"
              :disabled="!isCanResetToOriginal"
              @click="resetToOriginal()"
              >Reset to original</v-btn
            >
            <v-btn
              class="done"
              :class="{ disabled: !isApprove }"
              :disabled="!isApprove"
              @click="approveTemplate()"
            >
              Done
            </v-btn>
          </div>
        </div>
        <div class="result-area__mobile-nav">
          <div class="result-area__mobile-nav-item">
            <button
              class="edit-area__mobile-nav-item"
              :class="{ disabled: !isApprove }"
              :disabled="!isApprove"
              @click="approveTemplate()"
            >
              Save media
            </button>
          </div>
          <div class="result-area__mobile-nav-item">
            <button class="edit-area__mobile-nav-item" @click="mobileStep = 1">
              Return to editing
            </button>
          </div>
          <div class="result-area__mobile-nav-item">
            <button
              class="edit-area__mobile-nav-item"
              :disabled="!isCanResetToOriginal"
              @click="resetTemplateDialog.isShow = true"
            >
              Reset to original template
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog
      :isShow="templateMediaDialog.isShow"
      :size="500"
      customClass="template-list"
      @close="templateMediaDialog.isShow = false"
    >
      <template v-slot:description>
        <SmbMedia
          :calendarMediaItems="relatedMedia"
          :templateId="currentTemplate.id"
          mediaRatio="square"
          :searchTermVal="searchTermTemplate"
          @changeImage="setNewImage($event)"
          @changeRelativeImage="setNewImage($event)"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      customClass="reset-template-dialog"
      :isShow="resetTemplateDialog.isShow"
      :footerButtons="resetTemplateDialog.footerButtons"
      @success="resetTemplate()"
      @cancel="resetTemplateDialog.isShow = false"
      @close="resetTemplateDialog.isShow = false"
    >
      <template v-slot:description>
        Are you sure you want to reset <strong>all changes?</strong>
      </template>
    </ModalDialog>
    <ModalDialog
      customClass="template-list"
      :isShow="chooseNewTemplate.isShow"
      :isCanClose="false"
      :size="857"
      @close="chooseNewTemplate.isShow = false"
    >
      <template v-slot:description>
        <SmbTemplatesList
          @newTemplate="setNewTemplate($event)"
          v-if="chooseNewTemplate.isShow"
          :templatesList="templatesList"
          :currentTemplate="currentTemplate"
          @close="chooseNewTemplate.isShow = false"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="addNewColorDialog.isShow"
      :footerButtons="addNewColorDialog.footerButtons"
      @close="addNewColorDialog.isShow = false"
    >
      <template v-slot:description>
        <SmbColorPicker
          :colors-list="[]"
          :limit="Infinity"
          :currentColor="currentColor"
          :isShowColorsCustomInput="false"
          :isShowColorsLimit="false"
          @currentIndexChanged="currentIndex = $event"
          @currentColorChanged="currentColor = $event"
          @colorListChanged="addedColorsHandler($event)"
        />
      </template>
    </ModalDialog>
    <Loader v-if="isLoading" />
    <div v-if="isLoadingVideo" class="template-loader">
      <div class="template-loader__title">Hold tight!</div>
      <div class="template-loader__circular">
        <v-progress-circular
          :size="61"
          :width="9"
          indeterminate
        ></v-progress-circular>
      </div>
      <div class="template-loader__description">
        Our AI is generating your personalized video. <br />
        This may take up to 60 seconds.
      </div>
    </div>
  </div>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";
import SmbMedia from "@/components/smb-client/SmbMedia";
import SmbSetLogoPosition from "@/components/smb-client/SmbSetLogoPosition";
import SmbTemplatesList from "@/components/smb-client/SmbTemplatesList";
import validationRules from "@/shared/validationRules";
import Uploader from "@/components/global/Uploader";
import uploader from "@/mixins/uploader";
import Post from "@/models/Post";
import H from "@/utils/helper";
import Template from "@/models/Template";
import { mapActions, mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";
import SmbColorPicker from "@/components/smb-client/SmbColorPicker.vue";
import Loader from "@/components/global/Loader.vue";
import SmbFileUploader from "@/components/smb-client/SmbFileUploader.vue";
export default {
  name: "SmbEditTemplate",
  components: {
    SmbFileUploader,
    Loader,
    SmbColorPicker,
    ModalDialog,
    Uploader,
    SmbMedia,
    SmbTemplatesList,
    SmbSetLogoPosition,
  },
  props: {
    post: {
      type: Object,
      default: new Post(),
    },
    editedTemplate: {
      type: Object,
      default: new Template(),
    },
    template: {
      type: Object,
      default: () => new Template(),
    },
    isEditTemplate: {
      type: Boolean,
      default: false,
    },
    similarTemplates: {
      type: Array,
      default: () => [],
    },
    isMediaTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    templateMediaDialog: {
      isShow: false,
    },
    currentTemplate: new Template(),
    defaultTemplate: new Template(),
    originalSelectedTemplate: null,
    defaultImagesList: [],
    templatesList: [],
    editTemplateIndex: null,
    uploadedLogoUrl: null,
    currentTemplatePreview: null,
    isLoading: false,
    isLoadingVideo: false,
    isShowContainLogo: false,
    isShowContainBrokerageLogo: false,
    isApprove: false,
    resetTemplateDialog: {
      isShow: false,
      footerButtons: [
        { name: "Reset to original", emitName: "success", btnClass: "black" },
        { name: "Cancel", emitName: "close", btnClass: "black" },
      ],
    },
    addLogoDialog: {
      isShow: false,
      title: "",
      logoType: null,
    },
    chooseNewTemplate: {
      isShow: false,
    },
    editImageName: "",
    editImageRatio: "",
    valid: true,
    logoList: [],
    mediaIsValid: true,
    isDropActive: false,
    dataIsChanges: false,
    isCanGenerateNewMedia: false,
    isCanResetToOriginal: false,
    isLoadingTemplateList: false,
    isLoadedVideo: false,
    mobileStep: 1,
    additionalColorsList: [],
    addNewColorDialog: {
      isShow: false,
      footerButtons: [{ name: "Close", emitName: "close" }],
    },
    currentColor: "#000000",
    currentIndex: 0,
    currentTemplateParameter: null,
    listOfDeletedImagesIndexes: [],
    isLoadingUploader: false,
  }),
  mixins: [validationRules, uploader],
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("post", ["getCurrentPost", "getMediaIsLoading"]),
    ...mapGetters("stories", ["getCurrentStories", "getMediaIsLoading"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),

    H() {
      return H;
    },
    customerColors() {
      return this.getCurrentSmbCustomer.brand_color_palettes.length
        ? this.getCurrentSmbCustomer.brand_color_palettes[0]
        : ["#000000", "#FFFFFF"];
    },

    editImages() {
      this.currentTemplate.parameters.forEach((parameter, index) => {
        parameter.indexInList = index;
      });

      let mediaList = this.currentTemplate.parameters.filter(
        (i) => i.type === "image_url"
      );

      const logoIndex = mediaList.findIndex(
        (obj) => obj.name === "logo_image_url"
      );

      const headshotIndex = mediaList.findIndex(
        (obj) => obj.name === "headshot_image_url"
      );

      if (logoIndex !== -1) {
        const logoObj = mediaList.splice(logoIndex, 1)[0];
        mediaList.unshift(logoObj);
      }
      if (headshotIndex !== -1) {
        const headshotObj = mediaList.splice(headshotIndex, 1)[0];
        mediaList.unshift(headshotObj);
      }

      mediaList = mediaList.map((item) => {
        item.isHidden = item.hidden;

        if (
          item.name === "logo_image_url" &&
          this.isTransparentImage(item.value) &&
          !H.isVideo(this.currentTemplate.preview_url) &&
          !this.isMobile()
        ) {
          item.isHidden = true;
        }

        if (
          item.name === "brokerage_image_url" &&
          this.isTransparentImage(item.value) &&
          !H.isVideo(this.currentTemplate.preview_url) &&
          !this.isMobile()
        ) {
          item.isHidden = true;
        }

        return item;
      });

      return mediaList.length ? mediaList : [];
    },

    backgroundField() {
      const uniqueList = [];
      this.currentTemplate.parameters.forEach((obj) => {
        if (
          this.currentTemplate.parameters.filter((o) => o.layer === obj.layer)
            .length === 1 &&
          obj.type === "color"
        ) {
          uniqueList.push(obj);
        }
      });
      return uniqueList;
    },

    inputFields() {
      let textFields;
      if (this.currentTemplate.parameters.length) {
        textFields = this.currentTemplate.parameters.filter(
          (i) => i.type === "text"
        );
        let colors = this.currentTemplate.parameters.filter(
          (i) => i.type === "color"
        );
        colors.forEach((i) => {
          let indexElement = textFields.findIndex(
            (item) => item.layer === i.layer
          );
          if (indexElement !== -1) {
            textFields[indexElement].textColor = i.value;
            textFields[indexElement].nameColor = i.name;
          }
        });
      } else {
        textFields = [];
      }
      return textFields;
    },

    searchTermTemplate() {
      if (
        this.editedTemplate.searchTerm &&
        this.editedTemplate.searchTerm.length
      ) {
        if (Array.isArray(this.editedTemplate.searchTerm)) {
          return this.editedTemplate.searchTerm[0];
        } else {
          return this.editedTemplate.searchTerm;
        }
      } else {
        return "";
      }
    },
    relatedMedia() {
      return this.post.mediaUrl.filter((i) => !H.isVideo(i));
    },
    assetsList() {
      const logo = this.getCurrentSmbCustomer.brand_logo;
      const brokerAge = this.getCurrentSmbCustomer.brokerage_logo;
      const headshot = this.getCurrentSmbCustomer.brand_headshots.length
        ? this.getCurrentSmbCustomer.brand_headshots[0]
        : null;
      const brandingList = [...[logo, brokerAge, headshot]].filter(
        (val) => !!val
      );

      return [...brandingList, ...this.getCurrentSmbCustomer.business_assets];
    },
  },
  async created() {
    this.currentTemplate = H.deepCopy(this.editedTemplate);
    this.defaultTemplate = H.deepCopy(this.editedTemplate);

    this.getEditTemplateIndex();
    await this.getSimilarTemplates();
  },
  methods: {
    isMobile() {
      return isMobile;
    },
    ...mapActions("templates", [
      "getTemplatesInfo",
      "createImageFromTemplate",
      "checkVideoIsUpload",
    ]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    ...mapActions("clientDashboard", ["updatePosts"]),
    ...mapActions("templates", ["getTemplatesInfo", "createImageFromTemplate"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),

    async videoIsUpload(url) {
      if (H.isVideo(this.currentTemplate.preview_url)) {
        this.isLoadingVideo = true;
      } else {
        this.isLoading = true;
      }
      const idPattern = /\/([a-f\d-]+)\.mp4$/i;
      const videoId = url.match(idPattern)[1];
      if (videoId) {
        await this.checkAndProcessVideo(videoId);
      } else {
        this.isLoading = false;
        this.isLoadedVideo = false;
        return url;
      }
    },

    async checkAndProcessVideo(videoId) {
      let isUploadVideo = await this.checkVideoIsUpload(videoId);

      if (!isUploadVideo.mediaState) {
        this.isLoadedVideo = true;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return await this.checkAndProcessVideo(videoId);
      } else {
        this.isLoading = false;
        this.isLoadedVideo = false;
      }
    },

    resetTemplate() {
      this.isShowContainLogo = false;
      this.isShowContainBrokerageLogo = false;
      this.currentTemplate = H.deepCopy(this.defaultTemplate);
      this.resetTemplateDialog.isShow = false;
      this.isCanGenerateNewMedia = false;
      this.isCanResetToOriginal = false;
      this.isApprove = false;
      this.originalSelectedTemplate = null;
    },

    async setNewTemplate(template) {
      this.chooseNewTemplate.isShow = false;
      if (H.isVideo(this.currentTemplate.preview_url)) {
        this.isLoadingVideo = true;
      } else {
        this.isLoading = true;
      }
      const prevVersionOfTemplate = H.deepCopy(this.currentTemplate);
      this.originalSelectedTemplate = H.deepCopy(template);
      this.currentTemplate = template;

      this.isCanGenerateNewMedia = false;
      this.isCanResetToOriginal = true;
      await this.fetchDataForTemplate(prevVersionOfTemplate);
      if (this.isMobile()) {
        this.mobileStep = 2;
      }
      this.isApprove = true;
    },

    setNewCoordinates(data) {
      this.logoList.forEach((item, index) => {
        if (item.logoName === data.logoName) {
          this.logoList[index].x = data.x;
          this.logoList[index].y = data.y;
        }
      });
    },

    setNewLogoHandler(item) {
      this.addLogoDialog.isShow = false;
      let params = [];
      if (item.logoName === "logo_image_url") {
        params = [
          {
            layer: "LOGO",
            name: "logo_image_url",
            type: "image_url",
            value: item.logoUrl,
          },
          {
            layer: "LOGO",
            name: "logo_shift_x",
            type: "shift_x",
            value: item.x,
          },
          {
            layer: "LOGO",
            name: "logo_shift_y",
            type: "shift_y",
            value: item.y,
          },
        ];
      } else {
        params = [
          {
            layer: "BROKERAGE",
            name: "brokerage_image_url",
            type: "image_url",
            value: item.logoUrl,
          },
          {
            layer: "BROKERAGE",
            name: "brokerage_shift_x",
            type: "shift_x",
            value: item.x,
          },
          {
            layer: "BROKERAGE",
            name: "brokerage_shift_y",
            type: "shift_y",
            value: item.y,
          },
        ];
      }

      this.currentTemplate.parameters = this.currentTemplate.parameters.filter(
        (i) =>
          i.layer !== (item.logoName === "logo_image_url")
            ? "LOGO"
            : "BROKERAGE"
      );
      this.currentTemplate.parameters = [
        ...this.currentTemplate.parameters,
        ...params,
      ];
    },

    async fetchDataForTemplate(currentTemplate) {
      try {
        this.prepareParams();
        const params = {
          task: "change_template",
          inputParameters: currentTemplate.parameters,
          templateId: this.currentTemplate.id,
          customerId: this.getCurrentSmbCustomer.id,
          contentTemplateId: this.post.contentTemplateId,
          calendarItemId: this.post.calendarItemId,
        };

        const { template } = await this.createImageFromTemplate(params);
        this.currentTemplate = template;
        this.currentTemplate.isAutoGenerated =
          this.editedTemplate && this.editedTemplate.isAutoGenerated
            ? this.editedTemplate.isAutoGenerated
            : false;
        if (H.isVideo(this.currentTemplate.preview_url)) {
          await this.videoIsUpload(this.currentTemplate.preview_url);
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.isLoadingVideo = false;
      } finally {
        this.isLoading = false;
        this.isLoadingVideo = false;
      }
    },

    async getSimilarTemplates() {
      try {
        this.isLoadingTemplateList = true;
        let params = {
          calendarItemId: this.post.calendarItemId,
          customerId: this.getCurrentSmbCustomer.id,
          currentTemplateId: this.currentTemplate.id,
          contentTemplateId: this.post.contentTemplateId,
        };
        let { similarTemplates, template } = await this.getTemplatesInfo(
          params
        );

        const currentTemplateIndex = similarTemplates.findIndex(
          (template) => template.id === this.currentTemplate.id
        );

        this.templatesList = similarTemplates.filter(
          (template) =>
            template.ok === "ok" && template.id !== this.currentTemplate.id
        );

        if (currentTemplateIndex !== -1) {
          this.templatesList.unshift(similarTemplates[currentTemplateIndex]);
        } else {
          this.templatesList.unshift(template);
        }

        this.isLoadingTemplateList = false;
      } catch (e) {
        console.log(e);
      }
    },

    getEditTemplateIndex() {
      this.editTemplateIndex = this.post.media.findIndex((item) => {
        if (item.template) {
          return item.template.id === this.currentTemplate.id;
        }
      });
    },

    resetToOriginal() {
      this.resetTemplateDialog.isShow = true;
      this.addLogoDialog.isShow = false;
      this.logoList = [];
    },

    changeImage(name, item) {
      this.templateMediaDialog.isShow = true;
      this.editImageName = name;
      this.editImageRatio = item.image_layer_type;
    },

    async deleteImage(currentParams) {
      let editedItem = this.currentTemplate.parameters.findIndex(
        (item) => item.name.trim() === currentParams.name
      );
      this.listOfDeletedImagesIndexes.push(currentParams.indexInList);
      this.currentTemplate.parameters[editedItem].value =
        "https://smbdata-production.s3.amazonaws.com/stuff/transparent_image.png";

      this.isCanResetToOriginal = true;
      this.isCanGenerateNewMedia = false;
      this.isApprove = true;

      // this.currentTemplate.parameters[editedItem].value = image.mediaUrl;
      if (!this.isMobile() && !H.isVideo(this.currentTemplate.preview_url)) {
        this.logoList = this.logoList.filter(
          (el) => el.logoName !== currentParams.name
        );
        let isContainInLogoList = this.logoList.findIndex(
          (i) => i.logoName === currentParams.name
        );
        if (this.logoList.length && !isContainInLogoList) {
          this.logoList = this.logoList.filter(
            (el) => el.logoName !== currentParams.name
          );
          if (!this.logoList.length) this.addLogoDialog.isShow = false;
        } else if (this.logoList.length && isContainInLogoList) {
          await this.generateTransparentPreview(true);
        } else {
          await this.generateTransparentPreview();
        }
      }
    },

    setDefaultImages(parameters) {
      if (parameters.length) {
        const images = parameters.filter((i) => i.type === "image_url");
        this.defaultImagesList = images.map((i) => {
          return {
            name: i.name,
            value: i.value,
          };
        });
      }
    },

    prepareParams() {
      this.currentTemplate.parameters = this.currentTemplate.parameters.map(
        (item, index) => {
          if (item.textColor) {
            return {
              layer: this.currentTemplate.parameters[index].layer,
              name: this.currentTemplate.parameters[index].name,
              type: this.currentTemplate.parameters[index].type,
              value: this.currentTemplate.parameters[index].value,
            };
          } else {
            return item;
          }
        }
      );
    },

    prepareTextParams() {
      this.currentTemplate.parameters = this.currentTemplate.parameters.map(
        (item, index) => {
          if (item.type === "text") {
            return {
              layer: this.currentTemplate.parameters[index].layer,
              name: this.currentTemplate.parameters[index].name,
              type: this.currentTemplate.parameters[index].type,
              value:
                this.currentTemplate.parameters[index].value === ""
                  ? " "
                  : this.currentTemplate.parameters[index].value,
            };
          } else {
            return item;
          }
        }
      );
    },

    async generateTemplate() {
      try {
        this.prepareParams();
        this.prepareTextParams();
        if (this.logoList.length) {
          this.logoList.forEach((i) => this.setNewLogoHandler(i));
        }
        this.logoList = [];
        if (H.isVideo(this.currentTemplate.preview_url)) {
          this.isLoadingVideo = true;
        } else {
          this.isLoading = true;
        }
        const params = {
          task: "create_image",
          inputParameters: this.currentTemplate.parameters,
          templateId: this.currentTemplate.id,
          customerId: this.getCurrentSmbCustomer.id,
          contentTemplateId: this.post.contentTemplateId,
          calendarItemId: this.post.calendarItemId,
        };
        const data = await this.createImageFromTemplate(params);
        this.currentTemplate = data.template;
        this.currentTemplate.isAutoGenerated =
          this.editedTemplate && this.editedTemplate.isAutoGenerated
            ? this.editedTemplate.isAutoGenerated
            : false;
        if (H.isVideo(this.currentTemplate.preview_url)) {
          await this.videoIsUpload(this.currentTemplate.preview_url);
        }
        this.isApprove = true;
        this.isCanGenerateNewMedia = false;
        this.isCanResetToOriginal = true;
        this.isLoading = false;
        this.isLoadingVideo = false;
      } catch (e) {
        this.isLoading = false;
        this.isLoadingVideo = false;
        console.log(e);
      }
    },

    setNewImage(image) {
      let editedItem = this.currentTemplate.parameters.findIndex(
        (item) => item.name.trim() === this.editImageName.trim()
      );
      this.currentTemplate.parameters[editedItem].value = image.mediaUrl;
      this.currentTemplate.parameters[editedItem].externalId = image.externalId;
      this.currentTemplate.parameters[editedItem].externalService =
        image.externalService;

      if (!this.isEditTemplate && this.defaultImagesList.length) {
        document
          .getElementById(this.currentTemplate.parameters[editedItem].name)
          .classList.remove("invalid");
      }
      this.mediaIsValid = true;
      this.templateMediaDialog.isShow = false;
      this.editImageName = "";
      this.editImageRatio = "";

      this.isCanGenerateNewMedia = true;
      this.isCanResetToOriginal = true;
      this.isApprove = false;
    },

    changeTextColor(color, item) {
      const index = this.currentTemplate.parameters.findIndex(
        (i) => i.layer === item.layer && i.type === "color"
      );
      this.currentTemplate.parameters[index].value = color;
      this.isCanGenerateNewMedia = true;
      this.isCanResetToOriginal = true;
      this.isCanGenerateNewMedia = true;
      this.isApprove = false;
    },

    changeBgColor(color, item) {
      const index = this.currentTemplate.parameters.findIndex(
        (i) => i.layer === item.layer
      );
      this.currentTemplate.parameters[index].value = color;
      this.isCanGenerateNewMedia = true;
      this.isCanResetToOriginal = true;
      this.isApprove = false;
    },

    async uploadObjectMedia(mediaObject) {
      const data = await this.uploadMediaFromUrl({
        customerId: this.getCurrentSmbCustomer.id,
        inputMediaUrl: mediaObject.mediaUrl,
        isLicensed: mediaObject.isLicensed,
        processMedia: false,
        createMediaItem: true,
        createdBy: "6",
        fileName: "post_raw_file", //TODO, shoule be unique per post
        folderName: "None",
      });
      const mediaUrl = data.data["media_url"];
      const mediaId = data.data["media_id"];
      return { mediaUrl, mediaId };
    },

    async approveTemplate() {
      this.isLoading = true;
      const updatedPost = JSON.parse(JSON.stringify(this.post));
      const newlyUploadedMedia = await this.uploadObjectMedia({
        mediaUrl: this.currentTemplate.preview_url,
      });

      let newMedia = {
        cropDetails: updatedPost.media[this.editTemplateIndex].cropDetails,
        mediaUrl: newlyUploadedMedia.mediaUrl,
        mediaId: newlyUploadedMedia.mediaId,
        ratio: updatedPost.media[this.editTemplateIndex].ratio,
        template: this.currentTemplate,
        type: H.isVideo(newlyUploadedMedia.mediaUrl) ? "video" : "image",
      };
      updatedPost.media.splice(this.editTemplateIndex, 1, newMedia);

      const mediaParams = updatedPost.media.map((obj) => ({
        cropDetails: obj.cropDetails,
        mediaId: obj.mediaId,
        mediaUrl: obj.mediaUrl,
        type: H.isVideo(obj.mediaUrl) ? "video" : "image",
        ratio: this.post.type === "story" ? "vertical" : obj.ratio,
        template: obj.template,
      }));

      await this.updatePosts({
        id: this.post.id,
        byCustomer: true,
        mediaObject: mediaParams,
      });
      this.$emit("updateSmbPostMedia", updatedPost);
      this.isLoading = false;
    },

    updateTextValue(newValue, elem) {
      let editedItem = this.currentTemplate.parameters.findIndex(
        (item) => item.type === "text" && elem.name === item.name
      );
      this.currentTemplate.parameters[editedItem].value = newValue;
      this.isCanGenerateNewMedia = true;
      this.isCanResetToOriginal = true;
      this.isApprove = false;
    },

    dragHandler(flag = false) {
      this.isDropActive = flag;
    },
    async startUploadLogoHandler(file, item) {
      try {
        this.isLoading = true;
        const {
          url,
          fields: { key },
        } = await this.uploadFileHandler(file, this.getCurrentSmbCustomer.id);

        this.uploadedLogoUrl = url + key;
        this.currentTemplate.parameters.map((i) =>
          i.name === item.name ? (i.value = url + key) : i
        );
        if (!this.logoList.length) {
          this.currentTemplatePreview = this.currentTemplate.preview_url;
        }
        this.addLogoDialog.isShow = true;
        this.logoList.push({
          logoName: item.name,
          logoUrl: this.uploadedLogoUrl,
          x: 0,
          y: 0,
        });
        this.isCanGenerateNewMedia = true;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },

    async generateTransparentPreview(isDeleteCurrentParams) {
      try {
        this.prepareParams();
        this.prepareTextParams();
        if (H.isVideo(this.currentTemplate.preview_url)) {
          this.isLoadingVideo = true;
        } else {
          this.isLoading = true;
        }
        let tempParams = [];
        if (isDeleteCurrentParams) {
          tempParams = H.deepCopy(this.currentTemplate.parameters);
          let editedItem = tempParams.findIndex(
            (item) => item.name.trim() === this.logoList[0].logoName
          );
          tempParams[editedItem].value =
            "https://smbdata-production.s3.amazonaws.com/stuff/transparent_image.png";
        }
        const params = {
          task: "create_image",
          inputParameters: isDeleteCurrentParams
            ? tempParams
            : this.currentTemplate.parameters,
          templateId: this.currentTemplate.id,
          customerId: this.getCurrentSmbCustomer.id,
          contentTemplateId: this.post.contentTemplateId,
          calendarItemId: this.post.calendarItemId,
        };
        const data = await this.createImageFromTemplate(params);
        if (!this.logoList.length) this.addLogoDialog.isShow = false;
        this.currentTemplatePreview = data.template.preview_url;
        this.currentTemplate.preview_url = data.template.preview_url;
        this.isLoading = false;
        this.isLoadingVideo = false;
      } catch (e) {
        this.isLoading = false;
        this.isLoadingVideo = false;
        console.log(e);
      }
    },

    isShowUploadContainer(item) {
      return item.name === "logo_image_url"
        ? this.getCurrentSmbCustomer.brand_logo
          ? false
          : true
        : this.getCurrentSmbCustomer.brokerage_logo
        ? false
        : true;
    },

    async setLogoHandler(item) {
      this.isLoading = true;
      this.currentTemplatePreview = this.currentTemplate.preview_url;
      this.addLogoDialog.isShow = true;
      this.currentTemplate.parameters.map((i) => {
        return i.name === item.name
          ? (i.value =
              item.name === "logo_image_url"
                ? this.getCurrentSmbCustomer.brand_logo
                : this.getCurrentSmbCustomer.brokerage_logo)
          : i;
      });
      this.logoList.push({
        logoName: item.name,
        logoUrl: this.getCurrentSmbCustomer.brand_logo,
        x: 0,
        y: 0,
      });
      this.isCanResetToOriginal = true;
      this.isCanGenerateNewMedia = true;
      this.isLoading = false;
    },

    setNewLogoImage(url, logoName) {
      if (this.addLogoDialog.isShow) {
        this.logoList.forEach((i, index) => {
          if (i.logoName === logoName) {
            this.logoList[index].logoUrl = url;
          }
        });
      }
    },

    async startUploadFileHandler(file, item) {
      try {
        this.isDropActive = false;

        this.currentTemplate.parameters.splice(item.indexInList, 1, {
          ...item,
          ...{ isLoadingImage: true },
        });

        const {
          url,
          mediaId,
          fields: { key },
        } = await this.uploadFileHandler(file, this.getCurrentSmbCustomer.id);

        this.currentTemplate.parameters.splice(item.indexInList, 1, {
          ...item,
          ...{ isLoadingImage: false, value: url + key },
        });
        this.isCanGenerateNewMedia = true;
        this.isCanResetToOriginal = true;
        this.isApprove = false;
      } catch (e) {
        console.error(e);
      }
    },
    notValidFormatHandler() {
      this.isDropActive = false;
    },
    async generateTemplateMobile() {
      this.mobileStep = 2;
      await this.generateTemplate();
    },
    addNewColorsHandler(currentTemplate) {
      this.addNewColorDialog.isShow = true;
      this.currentTemplateParameter = currentTemplate;
    },
    addedColorsHandler(newColorsList) {
      this.additionalColorsList = [
        ...this.additionalColorsList,
        ...newColorsList,
      ];
      this.changeTextColor(
        newColorsList[newColorsList.length - 1],
        this.currentTemplateParameter
      );
      this.addNewColorDialog.isShow = false;
      this.currentColor = "#000000";
      this.currentIndex = 0;
    },
    isTransparentImage(url) {
      return url.includes("transparent_image.png");
    },
    undoImage(currentItem) {
      this.currentTemplate.parameters[currentItem.indexInList].value = this
        .originalSelectedTemplate
        ? this.originalSelectedTemplate.parameters[currentItem.indexInList]
            .value
        : this.defaultTemplate.parameters[currentItem.indexInList].value;
      this.listOfDeletedImagesIndexes = this.listOfDeletedImagesIndexes.filter(
        (deletedIndex) => deletedIndex !== currentItem.indexInList
      );
    },
    async uploadNewBusinessFileHandler(file) {
      this.isLoadingUploader = true;

      const { url, fields } = await this.uploadFileHandler(
        [...[file]],
        this.getCurrentSmbCustomer.id
      );

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: [
            ...this.getCurrentSmbCustomer.business_assets,
            ...[url + fields.key],
          ],
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoadingUploader = false;
    },
    emitClickOnUploaderByElementId(id) {
      document.getElementById(id).click();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.modify-template {
  &__header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &.blur {
      filter: blur(4px);
    }
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    text-align: left;
    color: #000;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 16px;
    }
    .choose-template {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      color: #3771c8;
      text-decoration: underline;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  &__name {
    font-size: 16px;
    font-weight: 500;
  }
  &__edit {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $color-violet;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 365px;
    grid-gap: 40px;
    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr 320px;
      grid-gap: 40px;
    }
    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: column;
    }
    &.blur {
      filter: blur(4px);
    }
  }
  .result-area {
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 600px) {
      display: none;
      &.active {
        display: flex;
      }
    }
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      left: -20px;
      top: 0;
      bottom: 0;
      background: #ccc;
    }
    &__image {
      width: 100%;
      max-height: 400px;
      margin-bottom: 30px;
      flex: 1;
      @media screen and (max-width: 700px) {
        max-height: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
      video {
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-fit: contain;
        object-position: top;
      }
    }
    &__btn {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        display: none;
      }
      .top-row {
        margin-bottom: 30px;
      }
      .generate-btn {
        width: 100%;
        height: 49px;
        background: #92c4ff;
        border: 1px solid #000000;
        border-radius: 16px;
        color: #1d1d1b;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        text-transform: none;
        letter-spacing: inherit;
        box-shadow: none;
      }
      .b-row {
        display: grid;
        grid-template-columns: 1fr 150px;
        grid-gap: 17px;
        button {
          width: 100%;
          flex: auto;
          height: 49px;
          padding: 0 30px;
          border-radius: 16px;
          box-shadow: none;
          text-transform: none;
          letter-spacing: inherit;
          &.done {
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            color: #fff;
            background: #1d1d1b;
          }
          &.reset {
            background: #ffffff;
            border: 1px solid #1d1d1b;
            border-radius: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #1d1d1b;
            @media screen and (max-width: 400px) {
              font-size: 13px;
            }
          }
        }
      }
    }
    &__mobile-nav {
      display: none;
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-top: 38px;
      }
      &-item {
        width: 100%;
        border-top: 1px solid rgba(29, 29, 27, 0.4);
        button {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          color: #1d1d1b;
          padding: 13px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .edit-area {
    @media screen and (max-width: 600px) {
      display: none;
      &.active {
        display: flex;
        width: 100%;
      }
    }
    &__mobile-nav {
      display: none;
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        margin-top: 38px;
      }
      &-item {
        width: 100%;
        border-top: 1px solid rgba(29, 29, 27, 0.3);
        button {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          color: #1d1d1b;
          padding: 13px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    &__wrapper {
      width: 100%;
    }
    &__controls-wrapper {
      display: flex;
      flex-direction: column;
    }
    &__control {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      @media screen and (max-width: 750px) {
        flex-direction: column;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 25px;
      }
      &.color {
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-width: 750px) {
          flex-direction: row;
        }
      }
      &-color {
        width: 70px;
        height: 70px;
        flex-shrink: 0;
        border: 1px solid #ccc;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        @media screen and (max-width: 750px) {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }
        @media screen and (max-width: 600px) {
          width: 30px;
          height: 30px;
          margin-bottom: 3px;
        }
        .colors {
          position: absolute;
          background: #fff;
          top: 35px;
          z-index: 10;
          transform: scale(0);
          transition: all 0.3s ease;
          box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
          @media screen and (min-width: 760px) {
            left: 20px;
            top: 0;
          }
          &:before {
            content: "";
            width: 20px;
            height: 100%;
            position: absolute;
            left: -20px;
            top: 0;
            bottom: 0;
          }
          &__pallet {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 760px) {
              flex-direction: row;
            }
            .plus {
              width: 50px;
              height: 50px;
              border: 1px dashed #000;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              font-size: 25px;
              align-self: center;
              color: black;
              margin: 10px 0 20px 0;
              @media screen and (min-width: 760px) {
                margin: 10px 10px 10px 10px;
              }
            }
            &-items {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-auto-rows: 40px;
              grid-gap: 14px;
              padding: 10px;

              @media screen and (min-width: 760px) {
                display: flex;
                align-items: center;
                padding: 10px;
              }

              .item {
                width: 50px;
                height: 50px;
                border: 1px solid #ccc;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
        &:hover {
          .colors {
            transform: scale(1);
          }
        }
      }
    }
    &__input {
      &-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        ::v-deep {
          .v-input__slot {
            background: #fafafa;
            border-radius: 4px;
          }
          textarea {
            font-weight: 700;
            line-height: 140%;
            min-height: 60px;
          }
        }
      }
      &-val {
        width: 100%;
        display: inline-flex;
        .name {
          display: block;
          font-weight: 700;
        }
      }
    }
    &__images {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 25px;
      .item {
        display: flex;
        align-items: center;
        position: relative;
        &__info {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
        &__name {
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          margin-left: 0;
          margin-right: 10px;
          text-align: left;
          position: relative;
          &-hint {
            position: absolute;
            left: 0;
            font-weight: 400;
            display: block;
            white-space: nowrap;
            font-size: 12px;
          }
        }
        &__controls {
          display: inline-flex;
          align-items: center;
          .file-uploader {
            display: inline-flex;
            cursor: pointer;
            margin-right: 10px;
            ::v-deep {
              .uploader-custom-icon {
                display: inline-flex;
                cursor: pointer;
              }
              .file-upload {
                display: inline-flex;
                font-size: 15px;
                label {
                  cursor: pointer;
                }
              }
            }
          }
          .edit {
            display: inline-flex;
            font-size: 15px;
          }
        }
        &__img {
          position: relative;
          display: inline-flex;
          height: 70px;
          width: 70px;
          margin-right: 10px;
          background: #ccc;
          border: 2px solid transparent;
          flex-shrink: 0;
          @media screen and (max-width: 600px) {
            width: 30px;
            height: 30px;
          }
          &.invalid {
            border: 2px solid red;
          }
          .uploader-container-logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
          }
          .edit {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            font-size: 11px;
            color: #7e5689;
            background: #ffffff;
            transition: all 0.3s ease;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .assets-images {
            position: absolute;
            transform: scale(0);
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
            background: #fff;
            left: 30px;
            z-index: 10;
            &__list {
              display: flex;
              align-items: center;
              padding: 10px;
              @media screen and (max-width: 600px) {
                width: 260px;
                flex-wrap: wrap;
              }
              &-item {
                width: 50px;
                height: 50px;
                border: 1px solid #ccc;
                margin-right: 10px;
                cursor: pointer;
                background: #8e9194;
                @media screen and (max-width: 600px) {
                  margin-bottom: 10px;
                }
                &:last-child {
                  margin-right: 0;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
                &.plus {
                  width: 50px;
                  height: 50px;
                  border: 1px dashed #000;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  font-size: 25px;
                  align-self: center;
                  color: #000;
                }
              }
            }
          }
          &:hover {
            .assets-images {
              transform: scale(1);
            }
          }
        }
        .transparent-img {
          position: relative;
          background: #fff;
          .line-1,
          .line-2 {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: black;
          }

          .line-1 {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }

          .line-2 {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
        &.upload-logo {
          position: relative;
          .change-position {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
          }
          ::v-deep {
            .uploader-container {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              opacity: 0;
            }
          }
          .item {
            cursor: pointer;
            &__icon {
              width: 70px;
              height: 70px;
              margin-right: 10px;
              cursor: pointer;
            }
            &__name {
              cursor: pointer;
            }
          }
        }
      }
    }
    &__b-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.template-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  &__title {
    font-size: 40px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &__description {
    font-size: 18px;
    margin-top: 30px;
    font-weight: 600;
  }
}

.uploader-container {
  height: 100%;
}

.loader-template-img {
  align-items: center;
  justify-content: center;
}

.btn {
  &.disabled {
    background: #ccc;
  }
  i {
    margin-left: 10px;
    display: inline-flex;
  }
}
.asset-uploader {
  display: none;
}
.uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  &__icon {
    font-size: 18px;
    margin-top: 10px;
  }
  &__text {
    font-size: 12px;
    text-decoration: underline;
  }
  &.border {
    border: 1px solid #cccc;
  }
}
</style>
