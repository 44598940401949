<template>
  <div
    class="stories"
    :class="[
      postType === 'client' ? 'client' : '',
      postType === 'smb-dashboard' ? 'smb-dashboard' : '',
    ]"
  >
    <!-- <div v-if="isLicenseIndication">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          why do you see watermarks?
          <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
        </template>
        <span>
          once you approve the Story the media will be purchased and the
          watermark will be cleared
          <br />
        </span>
      </v-tooltip>
    </div> -->
    <div class="post__body">
      <!--      <div class="rejected" v-if="post.customerApprove === 'false'">
        Rejected
      </div>-->
      <div class="post__content">
        <div class="post__header" @click="$emit('clickingOnPost', post)">
          <div class="user">
            <div class="user__avatar">
              <img
                v-if="currentCustomer.picUrl"
                :src="currentCustomer.picUrl"
                :alt="currentCustomer.name"
              />
              <img
                v-else
                src="@/assets/default-profile-picture.png"
                :alt="currentCustomer.name"
              />
            </div>
            <div class="user__info">
              <div class="user__name">{{ currentCustomer.name }}</div>
              <div
                class="user__location"
                v-if="getCustomerAddress && isShowCustomerAddress"
              >
                {{ getCustomerAddress }}
              </div>
            </div>
          </div>
          <div class="more-info" @click="toggleSmbNav()">
            <i class="icon-more"></i>
          </div>
        </div>

        <div class="post__img" :class="{ crop: post.media.length }">
          <v-btn
            class="edit-media"
            :class="{
              'centralize-with-animate-item':
                post.videoSupported || post.isCanRevertVideoTemplate,
            }"
            v-if="isContainTemplate"
            @click="editTemplateHandler()"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/edit_image_icon.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/edit_image_icon_hover.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Edit</div>
          </v-btn>
          <v-btn
            class="edit-media animate-item"
            v-if="post.videoSupported"
            @click="$emit('approvedToAnimatePost', post)"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/animate.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/animate_hover.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Change to video</div>
          </v-btn>
          <v-btn
            class="edit-media animate-item"
            v-if="post && post.isCanRevertVideoTemplate"
            @click="$emit('revertAnimatedPost', post)"
          >
            <div class="edit-media__icon">
              <img
                class="main-icon"
                src="@/assets/img/icons/revert-arrow.png"
                alt=""
              />
              <img
                class="hover-icon"
                src="@/assets/img/icons/revert-arrow.png"
                alt=""
              />
            </div>
            <div class="edit-media__text">Revert</div>
          </v-btn>

          <splide
            v-if="post.media.length"
            :slides="post.media"
            :options="options"
            class="crop__slider"
            :class="{ 'is-previous-feedback': isPreviousFeedback }"
          >
            <splide-slide v-for="(file, index) in post.media" :key="index">
              <img
                @click="$emit('clickingOnPost', post)"
                v-if="file.type === 'image'"
                class="post-image"
                :class="{ cover: !file.editedMediaUrlReduced }"
                :src="getMediaUrl(file)"
              />
              <div
                class="video"
                :class="{ isStatic: onlyVideo() }"
                v-else-if="file.type === 'video'"
              >
                <VideoPlayer
                  :isShowButton="false"
                  :refName="'clientDashboardStoriesVideo' + index"
                  :videoSrc="file.mediaUrl"
                />
              </div>
            </splide-slide>
          </splide>
          <div class="default-img" v-else>
            <img src="@/assets/img/default-inst-img.svg" alt="" />
          </div>
          <div
            class="failed-label"
            v-if="isCanShowFailedLabel && post.publishState === 'error'"
          >
            Failed to post
          </div>
        </div>
      </div>
    </div>
    <SmbPostClientNav
      v-if="isShowSmbNav"
      :post="post"
      :customClass="smbNavCustomClass"
      :isDisableEditTemplate="editTemplateIsDisabled"
      :isPosted="isPosted"
      :isScheduledContent="isScheduledContent"
      v-click-outside="hideSmbNav"
      @changeScheduledTime="changeSmbScheduleDate"
      @cancelScheduling="cancelSchedulingHandler"
      @closeMenu="hideSmbNav()"
      @howToEditLiveFbPost="hideSmbNav, $emit('howToEditLiveFbPost')"
      @howToEditLiveIgPost="hideSmbNav, $emit('howToEditLiveIgPost')"
      @editMedia="editMediaHandler()"
      @editTemplate="editTemplateHandler"
    />
    <SmbEditMedia
      @close="closeModalEditTemplate"
      v-if="isShowEditMediaModal"
      :post="post"
      @updateSmbPostMedia="$emit('editTemplateImage', $event)"
    />
    <div
      class="post__footer post__footer-client"
      v-if="isShowRating"
      :class="{ 'smb-rating': postType === 'smb-dashboard' }"
    >
      <div v-if="postType !== 'smb-dashboard'" class="rating-status">
        <div
          class="item"
          :class="
            post.customerRating > 0 && post.customerRating <= 2 ? 'active' : ''
          "
        >
          Reject
        </div>
        <div class="item" :class="post.customerRating >= 3 ? 'active' : ''">
          Approve
        </div>
      </div>
      <div v-if="postType === 'smb-dashboard'" class="rating-title">
        Help us improve
      </div>
      <div class="star-list">
        <v-rating
          :readonly="isPreviousFeedback"
          v-model="post.customerRating"
          :length="5"
          :color="post.customerRating <= 2 ? 'red' : 'green'"
          :size="ratingSize"
          @input="changeMarkHandler(post)"
        ></v-rating>
      </div>
      <div class="comments" v-if="isCanAddComment">
        <span class="comments__title">Comment</span>
        <div class="comments__control">
          <textarea
            :readonly="isPreviousFeedback"
            :placeholder="isPreviousFeedback ? '' : 'Write your comment...'"
            v-model="post.customerComment"
            :ref="'postComment_' + post.id"
            @focus="$emit('focusOnInput', post)"
            @blur="blurFromInputHandler(post)"
            @input="$emit('changeDataInStory')"
          ></textarea>
        </div>
      </div>
      <div v-if="postType === 'smb-dashboard'" class="smb-schedule">
        <span class="smb-schedule__text">{{
          post.publishState === "published"
            ? "Posted on"
            : +post.scheduledFor > $dayjs(new Date()).unix()
            ? "Scheduled for"
            : "About to be published"
        }}</span>
        <span
          class="smb-schedule__date"
          v-if="
            post.publishState === 'published' ||
            (post.publishState !== 'published' &&
              +post.scheduledFor > $dayjs(new Date()).unix())
          "
          >{{ getPublishDate }}</span
        >
      </div>
    </div>
    <div
      class="post__footer post__footer-info"
      v-if="isShowComment && (post.customerComment || post.customerRating)"
    >
      <div class="action-date" v-if="isShowActionDateInFooter">
        <div class="date-title">{{ actionDateTitle }}</div>
        <div class="date-text">
          {{ $dayjs.unix(post.updatedAt).format("MM.DD.YYYY") }}
        </div>
      </div>
      <div class="top-row">
        <div class="title" :class="post.customerRating >= 3 ? 'active' : ''">
          {{ post.customerComment ? "Comment" : "" }}
        </div>
        <div class="grade">
          <span>Grade</span>
          <div class="rating">
            <v-rating
              v-model="post.customerRating"
              :length="5"
              :readonly="true"
              :size="35"
            ></v-rating>
          </div>
        </div>
      </div>
      <div class="comment">
        {{ post.customerComment }}
      </div>
    </div>
    <div
      class="post__footer"
      v-if="postType === 'smb-dashboard' && isShowSmbAction"
    >
      <div class="footer-smb">
        <div
          class="footer-smb__row"
          :class="{ single: getCurrentSmbCustomer.manual_posting === true }"
        >
          <div class="footer-smb__cell">
            <v-btn
              variant="outlined"
              class="blue-btn schedule"
              @click="scheduleStory()"
            >
              <v-progress-circular
                indeterminate
                v-if="post.isLoadingSchedule"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <div v-else class="schedule__container">
                <template v-if="getPublishDate">
                  Schedule for
                  <span class="schedule-date">{{ getPublishDate }}</span>
                </template>
                <template v-else>Schedule</template>
              </div>
            </v-btn>
          </div>
          <div
            class="footer-smb__cell"
            v-if="getCurrentSmbCustomer.manual_posting !== true"
          >
            <v-btn
              variant="outlined"
              class="blue-btn publish-now"
              @click="publishStoryNow()"
            >
              <v-progress-circular
                indeterminate
                v-if="post.isLoadingPublishNow"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <template v-else>Publish now</template>
            </v-btn>
          </div>
        </div>
        <div
          class="footer-smb__row"
          :class="{ single: !isCanDeleteContent && !isCanSaveForLater }"
        >
          <div class="footer-smb__cell">
            <v-btn
              @click="changeSmbScheduleDate"
              variant="outlined"
              class="white-btn edit-content"
              >Change scheduled time</v-btn
            >
          </div>
          <div
            class="footer-smb__cell"
            v-if="isCanDeleteContent || isCanSaveForLater"
          >
            <v-btn
              v-if="isCanDeleteContent"
              variant="outlined"
              class="white-btn reject"
              @click="rejectStory()"
              >Delete content</v-btn
            >
            <v-btn
              v-if="isCanSaveForLater"
              variant="outlined"
              class="white-btn reject"
              @click="$emit('saveForLater', post)"
            >
              <v-progress-circular
                indeterminate
                v-if="post.isLoadingSaveForLater"
                model-value="20"
                size="20"
              ></v-progress-circular>
              <template v-else>Save for later</template></v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <ModalDialog
      :isShow="isCancelScheduleDialog.isShow"
      :title="isCancelScheduleDialog.title"
      :subTitle="isCancelScheduleDialog.subTitle"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="isCancelScheduleDialog.footerButtons"
      @cancelScheduling="
        (isCancelScheduleDialog.isShow = false), $emit('cancelScheduling', post)
      "
      @back="isCancelScheduleDialog.isShow = false"
      @close="isCancelScheduleDialog.isShow = false"
    />
    <ModalDialog
      :isShow="isNotAvailableActionDialog.isShow"
      :title="isNotAvailableActionDialog.title"
      :footerButtons="isNotAvailableActionDialog.footerButtons"
      @close="isNotAvailableActionDialog.isShow = false"
    >
      <template v-slot:description>
        Please note that story scheduling is a manual process by our team.
        <div class="smb-text-underline smb-d-inline">
          We can’t accommodate for any changes that are for less than 48 hours.
        </div>
        If you’re unhappy with the story, simply delete it once it’s live.
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="editTemplateDialogIsShow"
      :size="1000"
      :isCanClose="false"
      customClass="edit-template-modal"
      @close="editTemplateDialogIsShow = false"
    >
      <template v-slot:description>
        <SmbEditTemplate
          v-if="editTemplateDialogIsShow"
          :post="post"
          :isEditTemplate="true"
          :editedTemplate="selectTemplateForEdit()"
          @close="editTemplateDialogIsShow = false"
          @updateSmbPostMedia="
            $emit('editTemplateImage', $event);
            editTemplateDialogIsShow = false;
          "
        />
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import helper from "@/mixins/helper";
import VideoPlayer from "@/components/global/VideoPlayer";
import SmbPostClientNav from "@/components/smb-client/SmbPostClientNav";
import SmbEditTemplate from "@/components/smb-client/SmbEditTemplate";
import ModalDialog from "@/components/global/ModalDialog.vue";
import SmbEditMedia from "@/components/smb-client/SmbEditMedia";
import H from "@/utils/helper";

export default {
  name: "Story",
  data: () => ({
    isApproveValue: false,
    isShowSmbNav: false,
    isShowEditMediaModal: false,
    editTemplateIsDisabled: false,
    editTemplateDialogIsShow: false,
    isCancelScheduleDialog: {
      isShow: false,
      title: "Are you sure?",
      subTitle: "The content will move back to Pending for editing",
      footerButtons: [
        {
          name: "Cancel scheduling",
          emitName: "cancelScheduling",
          btnClass: "black",
        },
        { name: "Back", emitName: "back", btnClass: "black-border" },
      ],
    },
    smbNavCustomClass: "",
    isShowEditTemplateModal: false,
    isNotAvailableActionDialog: {
      isShow: false,
      title: "This action isn’t available",
      subTitle:
        "Please note that story scheduling is a manual process by our team. We can’t accommodate for any changes that are for less than 48 hours. \n" +
        "If you’re unhappy with the story, simply delete it once it’s live.",
      footerButtons: [
        {
          name: "OK",
          btnClass: "black-border",
          emitName: "close",
        },
      ],
    },
  }),
  mixins: [helper],
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    postType: {
      type: String,
      default: "",
    },
    isShowSwitch: {
      type: Boolean,
      default: true,
    },
    isShowComment: {
      type: Boolean,
      default: false,
    },
    isShowRating: {
      type: Boolean,
      default: false,
    },
    isCanAddComment: {
      type: Boolean,
      default: false,
    },
    isShowSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isShowMakeChangesButton: {
      type: Boolean,
      default: false,
    },
    focusToElementId: {
      type: String,
    },
    isCanShowFailedLabel: {
      type: Boolean,
      default: false,
    },
    isPreviousFeedback: {
      type: Boolean,
      default: false,
    },
    isShowCustomerAddress: {
      type: Boolean,
      default: false,
    },
    isShowActionDateInFooter: {
      type: Boolean,
      default: false,
    },
    actionDateTitle: {
      type: String,
      default: "",
    },
    currentCustomer: {
      type: Object,
      default: () => {},
    },
    isShowSmbAction: {
      type: Boolean,
      default: false,
    },
    ratingSize: {
      type: [String, Number],
      default: 35,
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    isScheduledContent: {
      type: Boolean,
      default: false,
    },
    isContentCalendarPage: {
      type: Boolean,
      default: false,
    },
    isCanDeleteContent: {
      type: Boolean,
      default: true,
    },
    isCanSaveForLater: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VideoPlayer,
    SmbPostClientNav,
    ModalDialog,
    SmbEditMedia,
    SmbEditTemplate,
  },
  computed: {
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerAddress",
    ]),
    ...mapGetters("createPost", ["getFeedbackApprovedList"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isApprove: {
      get: function () {
        if (this.$route.name === "create-post-pre-feedback") {
          return this.getFeedbackApprovedList.includes(this.post.id)
            ? this.getFeedbackApprovedList.includes(this.post.id)
            : false;
        } else {
          return false;
        }
      },
      set: function (newValue) {
        this.isApproveValue = newValue;
      },
    },
    options() {
      return {
        type: "slide",
        perPage: 1,
        fixedHeight: "40vh",
        arrows: true,
        autoWidth: false,
        width: "500px",
        cover: true,
        pagination: this.post.media.length > 1,
      };
    },
    isLicenseIndication() {
      return this.post.media.some((m) => m.isLicensed == false);
    },
    getPublishDate() {
      return this.parseStringDateForSmb(this.post.scheduledForStr);
    },
    isContainTemplate() {
      return this.post.media.find((i) => i.template !== null) ? true : false;
    },
  },
  methods: {
    editMediaHandler() {
      this.hideSmbNav();
      this.isShowEditMediaModal = true;
    },

    editTemplateHandler() {
      this.$emit("showedEditTemplateDialog");
      this.editTemplateDialogIsShow = true;
    },

    closeModalEditTemplate() {
      if (this.isShowEditMediaModal) {
        this.isShowEditMediaModal = false;
      }
    },
    selectTemplateForEdit() {
      let mediaWithTemplate = this.post.media.find((i) => i.template !== null);
      if (mediaWithTemplate) {
        return mediaWithTemplate.template;
      } else {
        this.editTemplateIsDisabled = true;
      }
    },
    rejectStory() {
      this.$emit("rejectSmbPost", this.post);
    },
    saveStory() {
      this.$emit("saveSmbPost", this.post);
    },
    publishStoryNow() {
      this.$emit("publishSmbPostNow", this.post);
    },
    scheduleStory() {
      this.$emit("scheduleSmbPost", this.post);
    },

    hideSmbNav() {
      if (this.isShowSmbNav) {
        this.isShowSmbNav = false;
      }
    },
    toggleSmbNav(className = "") {
      if (this.postType === "smb-dashboard") {
        this.isShowSmbNav = !this.isShowSmbNav;

        if (this.isShowSmbNav) {
          this.selectTemplateForEdit();
        }

        className
          ? (this.smbNavCustomClass = className)
          : (this.smbNavCustomClass = "");
      }
    },
    getMediaUrl(file) {
      return file.editedMediaUrlReduced || file.editedMediaUrl || file.mediaUrl;
    },
    changeMarkHandler(post) {
      if (post.customerRating <= 2) {
        this.$emit("updateRejectedList", post);
      } else {
        this.$emit("deleteFromRejectedContent", post);
      }
      this.$emit("changeDataInStory", post);
    },
    blurFromInputHandler(post) {
      this.$emit("blurFromInput", post);
    },
    onlyVideo() {
      if (this.post.media.length) {
        return !this.post.media
          .map((file) => file.type === "image")
          .includes(true);
      }
    },
    isProcessedImages(media) {
      if (media.length) {
        const data = media.filter((item) => !H.isVideo(item.mediaUrl));
        return data.some((item) => !item.editedMediaUrlReduced);
      } else {
        false;
      }
    },
    changeSmbScheduleDate() {
      if (
        this.isContentCalendarPage &&
        this.post.type === "story" &&
        this.getCurrentSmbCustomer.manual_posting
      ) {
        this.isNotAvailableActionDialog.isShow = true;
      } else {
        this.$emit("changeScheduledTime");
        this.hideSmbNav();
      }
    },
    cancelSchedulingHandler() {
      if (
        this.isContentCalendarPage &&
        this.post.type === "story" &&
        this.getCurrentSmbCustomer.manual_posting
      ) {
        this.isNotAvailableActionDialog.isShow = true;
      } else {
        this.hideSmbNav();
        if (this.isScheduledContent) {
          this.isCancelScheduleDialog.isShow = true;
        } else {
          this.$emit("cancelScheduling", this.post);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.post,
.stories {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 650px;

  .rejected {
    display: none;
  }
  &.smb-dashboard,
  &.client {
    max-height: 1020px;
    .rejected {
      text-transform: uppercase;
      color: #ff4853;
      opacity: 0.6;
      transform: rotate(-60deg);
      font-weight: bold;
      font-size: 64px;
      line-height: 140%;
      position: absolute;
      z-index: 10;
      top: 200px;
      display: block;
    }
    .post {
      position: relative;
      &__content {
        width: 100%;
        max-width: 364px;
        margin: 0 auto;
        @media screen and (max-width: 500px) {
          max-width: 100%;
        }
      }

      &__slider {
        height: 550px;
      }

      &__img {
        height: 550px;
      }
    }
    .smb-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      .rating-title {
        font-weight: 500;
        font-size: 10px;
        color: #1d1d1b;
        padding-bottom: 10px;
      }
      ::v-deep {
        .star-list {
          button {
            margin: 0 5px;
          }
          .v-rating {
            .accent--text {
              color: #c7c9ca !important;
              caret-color: #c7c9ca !important;
            }
          }
        }
      }
    }
  }

  &__body {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__content {
    background: $color-white;
    border: 1px solid #eeeff0;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06) !important;
    border-radius: 0 !important;
    position: relative;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 12px;
    cursor: pointer;

    .user {
      display: inline-flex;
      align-items: center;

      &__avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 8px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__info {
        display: inline-flex;
        flex-direction: column;
      }

      &__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: $color-black;
      }

      &__location {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: #515456;
        span {
          margin-right: 4px;
        }
      }
    }

    .more-info {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: $color-white;
      }
    }
  }

  &__img {
    background: rgba(78, 87, 120, 0.2);
    height: 415px;
    position: relative;

    .default-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.blur {
      overflow: hidden;
      .splide {
        filter: blur(8px);
      }
    }

    &-loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1000;
      span {
        text-align: center;
        color: #fff;
        font-size: 12px;
        padding: 5px;
      }
    }

    .edit-media {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      height: auto;
      width: auto;
      min-width: auto;
      padding: 0;
      letter-spacing: initial;
      text-transform: none;
      border: 1px solid #1d1d1b;
      border-radius: 5px;
      transition: all 0.3s ease;
      display: none;

      ::v-deep {
        .v-btn__content {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px;
          min-width: 80px;
        }
      }

      &__icon {
        display: inline-flex;

        img {
          width: 18px;
        }

        .main-icon {
          display: block;
        }

        .hover-icon {
          display: none;
        }
      }

      &__text {
        color: #1d1d1b;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        margin-top: 2px;
      }

      &:hover {
        background: #92c4ff;

        .edit-media {
          &__icon {
            .main-icon {
              position: relative;
              left: 1px;
              display: none;
            }

            .hover-icon {
              display: block;
            }
          }
        }
      }
      &.centralize-with-animate-item {
        left: 25%;
        width: 100%;
        max-width: 95px;
        .edit-media__icon {
          img {
            width: 24px !important;
          }
        }
        .edit-media__text {
          padding-right: 6px;
          font-size: 10px;
        }
      }
      &.animate-item {
        left: 75%;
        width: 100%;
        max-width: 95px;
        .edit-media__icon {
          img {
            width: 24px !important;
          }
        }
        .edit-media__text {
          font-size: 10px;
        }
      }
    }

    &:hover {
      .edit-media {
        display: inline-flex;
      }
    }
  }

  &__slider {
    width: 100% !important;
    height: 415px;
    margin-bottom: 13px;

    ::v-deep {
      .splide {
        &__track {
          height: 100%;
        }

        &__list {
          height: 100%;
        }

        &__slide {
          height: 100% !important;
          width: 100%;
          background-size: contain !important;

          .post-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &__arrow {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &:disabled {
            opacity: 0;
          }

          &--prev {
            &:before {
              font-family: "icomoon" !important;
              content: "\e902";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          &--next {
            &:before {
              font-family: "icomoon" !important;
              content: "\e90c";
              position: absolute;
              font-size: 24px;
              color: $color-white;
            }
          }

          svg {
            display: none;
          }
        }

        &__pagination {
          padding-left: 0;
          bottom: -27px;

          &__page {
            width: 4px;
            height: 4px;
            background: $color-grey3;
            transform: scale(1);
            padding: 2px !important;
            transition: all 0.3s ease;

            &.is-active {
              background: $color-black;
            }
          }
        }
      }
    }

    .content-container {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .image-file {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
      }
    }

    .inst-video {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__controls {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px;

    .cell {
      display: inline-flex;
      align-items: center;

      .item {
        display: inline-flex;
        margin-right: 12px;

        i {
          font-size: 17px;
          color: $color-black;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__text-container {
    width: 100%;
    padding: 0 16px;

    .likes {
      font-weight: 500;
      font-size: 9px;
      line-height: 140%;
      color: $color-black;
      margin-bottom: 8px;
    }

    .text {
      font-weight: normal;
      font-size: 10px;
      line-height: 150%;
      color: #515456;
      margin-bottom: 8px;
    }

    .tags-list {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &__item {
        font-weight: normal;
        font-size: 10px;
        line-height: 140%;
        color: $color-grey2;
        margin-right: 5px;
      }
    }
  }

  &__footer {
    width: 100%;
    border-top: 1px solid rgba(47, 72, 88, 0.2);
    padding-top: 11px;
    &-client {
      .rating-status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: 8px;
        .item {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: $color-grey3;
          transition: all 0.3s ease;
          &:first-child {
            &.active {
              transition: all 0.3s ease;
              color: $color-red;
            }
          }
          &:last-child {
            &.active {
              transition: all 0.3s ease;
              color: $color-green;
            }
          }
        }
      }
      .star-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        ::v-deep {
          .v-rating {
            &--readonly {
              opacity: 0.5;
            }
            .mdi-star,
            .mdi-star-outline {
              &:before {
                font-family: "icomoon" !important;
                content: "\e907";
              }
            }
            .v-icon {
              padding: 0;
              margin: 0 9px;
              color: $color-grey3;
              &.red--text {
                color: $color-red;
              }
              &.green--text {
                color: $color-green;
              }
            }
          }
        }
      }
      .comments {
        width: 100%;
        padding: 0 16px;
        &__title {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: $color-black;
          margin-bottom: 4px;
        }
        &__control {
          width: 100%;
          textarea {
            width: 100%;
            resize: none;
            height: 68px;
            background: #fafafa;
            border: 1px solid $color-grey3;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 8px 12px;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            outline: none;
            &:read-only {
              border: none;
              padding-left: 0;
              padding-right: 0;
              background: none;
              cursor: default;
            }
          }
        }
      }
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    .btn {
      height: 36px;
      width: 100%;
      max-width: 200px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: $color-white;
      border-radius: 16px;
      background: $color-violet;
      transition: all 0.3s ease;
      &:hover {
        background: darken($color-violet, 10);
      }
    }
  }

  &__footer-info {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0 15px;
    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 600;
        font-size: 11px !important;
        line-height: 130%;
        color: $color-red;
        &.active {
          color: $color-green;
        }
      }
      .grade {
        display: inline-flex;
        align-items: center;
        span {
          font-weight: normal;
          font-size: 10px;
          line-height: 140%;
          color: $color-grey2;
          margin-right: 10px;
        }
        .rating {
          ::v-deep {
            .v-rating {
              .mdi-star,
              .mdi-star-outline {
                &:before {
                  font-family: "icomoon" !important;
                  content: "\e907";
                  font-size: 10px;
                }
              }
              .v-icon {
                padding: 0;
                margin: 0 1px;
                color: $color-grey3;
                &.accent--text {
                  color: $color-grey3 !important;
                }
                &.primary--text {
                  color: #ffc273 !important;
                }
              }
            }
          }
        }
      }
    }
    .comment {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $color-black;
      max-height: 80px;
      overflow-y: auto;
    }
  }
}

.stories {
  position: relative;
  .post {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      .user {
        &__name {
          color: $color-white;
          .time {
            opacity: 0.7;
          }
        }
        &__location {
          color: $color-white;
          opacity: 0.7;
        }
      }
    }
    &__img {
      width: 100%;
      height: 550px;
    }
    &__slider {
      height: 550px;
      margin-bottom: 0;
      .content-container {
        img {
          object-fit: cover;
          object-position: center;
        }
      }
      .inst-video {
        .v-icon {
          display: none;
        }
        video {
          object-fit: cover;
          object-position: center;
        }
      }
      ::v-deep {
        .splide__pagination {
          bottom: inherit;
          top: -6px;
          z-index: 10;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
          grid-gap: 2px;
        }
        .splide__pagination__page {
          width: 100%;
          margin: 0;
          border-radius: 1px;
          height: 2px;
          padding: 0 !important;
          opacity: 0.6;
          background: $color-white;
          &.is-active {
            background: $color-white;
            opacity: 1;
          }
        }
      }
    }
  }
  .splide__slide {
    background-size: cover !important;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
  &.smb-dashboard,
  &.client {
    flex: 1;
    .post {
      &__content {
        width: 100%;
        max-width: calc(100% - 24px);
      }
    }
    .post__body {
      height: 100%;
      overflow-y: hidden;
    }
    .post__img {
      &.crop {
        margin: auto;
        position: static;
        height: auto;
        ::v-deep {
          .splide {
            &--slide {
              position: static;
              flex: 1;
              height: 100%;
              display: flex;
            }
            &__list {
              width: 100%;
              aspect-ratio: 9 / 16;
            }
            &__slide {
              width: 100% !important;
              background: none !important;
              height: auto !important;
              position: relative;
              align-items: center;
              display: flex;
              img {
                width: 100%;
                display: block !important;
                &.cover {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .video {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                .video-wrapper .video-container {
                  object-fit: contain;
                }
                &.isStatic {
                  position: static;
                }
              }
            }

            &__track {
              margin: auto;
            }

            &__arrow {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              &:disabled {
                opacity: 0;
              }

              &--prev {
                &:before {
                  font-family: "icomoon" !important;
                  content: "\e902";
                  position: absolute;
                  font-size: 24px;
                  color: $color-white;
                }
              }

              &--next {
                &:before {
                  font-family: "icomoon" !important;
                  content: "\e90c";
                  position: absolute;
                  font-size: 24px;
                  color: $color-white;
                }
              }

              svg {
                display: none;
              }
            }
          }
          .splide__pagination {
            bottom: inherit;
            top: -6px;
            z-index: 10;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
            grid-gap: 2px;
            padding-left: 0;
          }
          .splide__pagination__page {
            width: 100%;
            margin: 0;
            border-radius: 0 !important;
            transform: scale(1);
            height: 2px;
            padding: 0 !important;
            opacity: 0.6;
            background: $color-white;
            &.is-active {
              background: $color-white;
              opacity: 1;
            }
          }
        }
        .splide {
          &.is-previous-feedback {
            ::v-deep {
              .splide__list {
                min-height: 385px;
              }
            }
          }
        }
      }
    }
  }
  .failed-label {
    position: absolute;
    padding: 0px 4px;
    background: red;
    color: #ffffff;
    font-size: 15px;
    top: 50%;
    max-width: 105px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.action-date {
  font-size: 12px;
  text-align: center;
  display: flex;
  .date-title {
    padding-right: 10px;
    color: red;
  }
}
.smb-dashboard {
  ::v-deep {
    .tags-list {
      max-width: initial;
      &__item {
        max-width: initial;
      }
    }
  }
  &.stories {
    height: 100%;
    .footer-smb {
      position: relative;
      margin-bottom: 0;
      padding: 0 3px;
      display: flex;
      flex-direction: column;
      &__row {
        margin-bottom: 8px;
        display: grid;
        grid-template-columns: 1fr 85px;
        grid-gap: 4px;
        &.single {
          grid-template-columns: 1fr;
        }
        @media screen and (max-height: 850px) {
          margin-bottom: 8px;
        }
        @media screen and (min-width: 500px) {
          grid-template-columns: 1fr 75px;
        }
      }
      .v-btn {
        font-weight: 500;
        font-size: 15px;
        line-height: 150%;
        background: #ffffff;
        color: #1d1d1b;
        border: 1px solid #1d1d1b;
        border-radius: 5px !important;
        text-transform: none;
        padding: 1px 3px;
        box-shadow: none;
        letter-spacing: initial;
        height: 37px !important;
        width: 100%;
        text-indent: initial;
        flex: 1;
        @media screen and (max-height: 850px) {
          height: 32px !important;
        }
        @media screen and (min-width: 500px) {
          letter-spacing: -0.5px;
        }
        &.reject {
          font-size: 10px;
        }
        &.blue-btn {
          background: #92c4ff;
          font-weight: 700;
          font-size: 11px;
          line-height: 150%;
          ::v-deep {
            .schedule-date {
              font-size: 11px;
              margin-left: 3px;
            }
          }
          &.schedule {
            font-size: 9px;
            @media screen and (max-height: 550px) {
              font-size: 12px;
            }
            ::v-deep {
              .schedule-date {
                font-size: 10px;
                margin-left: 3px;
                @media screen and (max-height: 550px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    ::v-deep {
      .post {
        &__header {
          .user {
            &__name {
              font-size: 14px;
            }
          }
        }
        &__img {
          height: auto;
          min-height: 400px;
          flex: 1;
        }
        &__content {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.smb-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(47, 72, 88, 0.2);
  padding: 7px;
  width: 100%;
  height: 71px;
  &__text {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1d1d1b;
  }
  &__date {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1d1d1b;
  }
}
.smb-text-underline {
  text-decoration: underline;
}
.smb-d-inline {
  display: inline;
}
</style>
