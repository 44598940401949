<template>
  <div
    class="indication-container"
    :class="customClass"
    :style="`background-color: ${tooltipBgColor}`"
  >
    <div class="indication-container__wrapper">
      <div
        v-if="text"
        :style="`color: ${tooltipTextColor}`"
        class="indication-container__title"
      >
        {{ text }}
      </div>
      <div v-if="imgSrc" class="indication-container__img">
        <img :src="imgSrc" alt="" />
      </div>
      <div v-if="tooltip" class="indication-container__tooltip">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="tooltipTextColor" dark v-bind="attrs" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </div>

      <div
        v-if="
          dataObject.type === 'post' && dataObject.postTo.length && !tooltip
        "
        class="indication-container__icons"
      >
        <div
          v-for="platform in dataObject.postTo"
          :key="platform"
          class="indication-container__icons-item"
        >
          <img
            :src="require(`@/assets/img/icons/${platform}_logo_rounded.svg`)"
          />
        </div>
      </div>
      <button
        class="delete-post"
        :class="{
          marginLeft: dataObject.type !== 'post' || !dataObject.postTo.length,
        }"
        @click="$emit('rejectSmbPost')"
        v-if="!isContentCalendarPage"
      >
        <i class="icon-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Post from "@/models/Post";

export default {
  name: "Indication",
  methods: {},
  props: {
    text: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    tooltipTextColor: {
      type: String,
      default: "",
    },
    tooltipBgColor: {
      type: String,
      default: "",
    },
    itemType: {
      type: String,
      default: "",
    },
    isContentCalendarPage: {
      type: Boolean,
      default: false,
    },
    dataObject: {
      type: Object,
      default: () => new Post(),
    },
  },
};
</script>

<style scoped lang="scss">
.indication-container {
  width: 100%;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  margin-bottom: 4px;
  &__img {
    height: 32px;
    margin-right: 4px;
    margin-left: 4px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 11px;
    margin-bottom: 11px;
    margin-left: 3px;
    margin-right: 3px;
    align-items: center;
  }
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  }
  &__tooltip {
    margin-left: auto;
    i {
      font-size: 18px;
    }
  }
  &__type {
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  &__icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 3px;
    &-item {
      display: inline-flex;
      width: 16px;
      height: 16px;
      margin: 0 1px;
      img {
        width: 100%;
      }
    }
  }
  &.custom-color-bg,
  &.gradient-indication {
    background: transparent;
    border: none;
    margin-bottom: 0;
    .indication-container {
      &__title {
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        margin-right: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__img {
        margin-left: auto;
        margin-right: 0;
        width: 30px;
        height: 35px;
      }
    }
  }
  &.indication4 {
    background-color: transparent !important;
    border: none;
    margin-bottom: 0;
    .indication-container {
      &__wrapper {
        justify-content: flex-start;
      }
      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #ffffff;
        margin-right: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__img {
        display: inline-flex;
        margin-left: 1px;
        margin-right: 2px;
        max-width: 80px;
        height: auto;
        img {
          width: 100%;
        }
      }
    }
  }
}
.delete-post {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  //margin-left: auto;
  margin-right: 2px;
  color: #fff;
  &:hover {
    color: #fe3a43;
  }
  &.marginLeft {
    margin-left: auto;
  }
}
</style>
