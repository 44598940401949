<template>
  <div class="external-library">
    <div class="external-library__search">
      <v-text-field
        v-model="searchVal"
        label='Example "Marble kitchen"'
        @keydown.enter="search(1)"
      ></v-text-field>
      <button @click="search(1)" class="btn black">Search</button>
    </div>
    <div v-if="errorText.length" class="error">{{ errorText }}</div>
    <div class="external-library__list">
      <div
        class="item"
        v-for="(item, index) in media"
        :key="index"
        @click="
          $emit('changeImage', {
            mediaUrl: item.media_url,
            externalId: item.media_external_id,
            media_thumbnail_url: item.media_thumbnail_url,
            externalService: item.externalService,
          })
        "
      >
        <span class="item__pro-mark" v-if="item.pro">
          <v-icon v-if="getCurrentSmbCustomer.plan === 2">mdi-plus</v-icon>
          <i v-else class="icon-crown-svgrepo-com"></i>
          <span class="text">{{
            getCurrentSmbCustomer.plan === 2 ? "PLUS" : "PRO"
          }}</span>
        </span>
        <img
          :src="
            item.media_thumbnail_url ? item.media_thumbnail_url : item.media_url
          "
          alt=""
        />
      </div>
      <div class="load-more" v-if="media.length && limitPages > 1">
        <button
          class="btn black"
          :disabled="page - 1 >= limitPages"
          @click="loadMore"
        >
          Load-more
        </button>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";
import helper from "@/mixins/helper";
import LogRocket from "logrocket";

export default {
  name: "SmbStockMedia",
  props: {
    mediaRatio: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    templateId: {
      type: String,
      default: "",
    },
    searchTermVal: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    searchVal: null,
    isLoading: false,
    busy: false,
    media: [],
    imagesAlternatives: [],
    nextPage: null,
    service: undefined,
    page: 0,
    limitPages: 9,
    isSelectColor: false,
    selectedColor: null,
    errorText: "",
  }),
  components: { Loader },
  mixins: [helper],
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  created() {
    if (this.searchTermVal.length) {
      this.searchVal = this.searchTermVal;
      this.search(1);
    }
  },
  methods: {
    ...mapActions("templates", ["getSmbStockMedia"]),
    async search(pageNum) {
      LogRocket.track("pending_content_stock_search");
      this.errorText = "";
      if (pageNum) {
        this.media = [];
        this.page = 1;
      }
      try {
        if (this.searchVal) {
          this.isLoading = true;
          let params = {
            countPerSearchTerm: this.limitPages,
            nextPage: this.nextPage,
            page: this.page ? this.page : undefined,
            ratio: this.mediaRatio,
            searchTerm: this.searchVal,
            service: this.service,
            customerId: this.getCurrentSmbCustomer.id,
          };
          const { service, page, photos } = await this.getSmbStockMedia(params);
          this.service = service;
          this.page = page + 1;
          if (photos.length) {
            const images = photos;
            this.busy = false;
            this.media = [...this.media, ...images];
          }
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        this.errorText = "Something went wrong please try again";
        console.log(e);
      }
    },
    async loadMore() {
      this.busy = true;
      if (this.page <= this.limitPages) {
        await this.search();
      }
    },
    saveColor(color) {
      this.isSelectColor = false;
      this.selectedColor = color.hex;
    },
  },
};
</script>

<style scoped lang="scss">
.external-library {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  &__search {
    display: flex;
    align-items: baseline;
    margin-top: -20px;
    .btn {
      margin-left: 10px;
    }
  }
  &__filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    &-item {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-height: 320px;
    overflow-y: auto;
    padding-right: 2px;
    .item {
      width: 100%;
      height: 120px;
      position: relative;
      &__pro-mark {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px 7px;
        display: inline-flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 15px;
        line-height: 16px;
        i {
          font-size: 13px;
          color: #ececec;
        }
        .text {
          font-size: 10px;
          line-height: 10px;
          margin-left: 3px;
          color: #ececec;
          font-weight: 700;
          text-transform: uppercase;
        }
        &:hover {
          .text {
            transform: scale(1);
            transition: all 0.3s ease-in;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .color-chip {
    .color {
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 3px;
      border: 1px solid #cccccc;
    }
  }
}
.load-more {
  grid-column: 1 / -1;
  button[disabled] {
    opacity: 0.3;
  }
}
</style>
