<template>
  <div class="instagram-story" :id="elementId">
    <!-- Story Content -->
    <div class="story-content skeleton-loader"></div>

    <!-- Overlay Info -->
    <div class="overlay-info">
      <!-- User Info -->
      <div class="user-info">
        <div class="avatar skeleton-loader distinct-color"></div>
        <div class="username skeleton-loader distinct-color"></div>
      </div>

      <!-- Story Segments -->
      <div class="story-segments">
        <div class="segment skeleton-loader"></div>
        <div class="segment skeleton-loader"></div>
        <div class="segment skeleton-loader"></div>
        <div class="segment skeleton-loader"></div>
        <div class="segment skeleton-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StorySkeleton",
  props: {
    elementId: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.instagram-story {
  width: 100%;
  height: 100%;
  min-height: 500px;
  max-width: 550px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.story-content {
  width: 100%;
  height: 100%;
  background: linear-gradient(100deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.overlay-info {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar,
.username,
.segment {
  background: linear-gradient(100deg, #f0f0f0, #e0e0f0, #f0f0f0);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.distinct-color {
  background: linear-gradient(100deg, #a0a0a0, #909090, #a0a0a0);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.username {
  width: 100px;
  height: 10px;
}

.story-segments {
  display: flex;
  gap: 2px;
}

.segment {
  flex-grow: 1;
  height: 2px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
